import { createSelector } from '@reduxjs/toolkit';
import { RequestConfig } from 'services/http';
import { RootState, store } from 'store';

const getToken = createSelector((state: RootState) => state.auth, auth => auth.accessToken);

const requestInterceptor = (value: RequestConfig): RequestConfig => {
	const accessToken: string | null = getToken(store.getState());

	if (accessToken) {
		value.headers = { ...value.headers, Authorization: `Bearer ${accessToken}` };
	}

	return value;
};

export default requestInterceptor;
