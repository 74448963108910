import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import Button from 'components/forms/button';
import { Dictionary } from 'utils/constants/dictionary';
import './ConfirmModal.scss';

type Props = {
	onClose: () => void;
	onOk: () => void;
};

const ConfirmModal = ({ onClose, onOk }: Props): JSX.Element => (
	<Modal
		centered
		className="report-modal"
		closable
		footer={null}
		onCancel={onClose}
		visible
		width={500}
	>
		<div className="confirm-modal__body">
			<h2>
				<FormattedMessage defaultMessage="Are you sure?" id={Dictionary.confirmModal.text} />
			</h2>
		</div>
		<div className="flex justify-content-between">
			<Button
				className="btn-green"
				defaultText="Yes"
				onClick={onOk}
				textId={Dictionary.common.yes}
			/>

			<Button
				defaultText="No"
				onClick={onClose}
				textId={Dictionary.common.no}
			/>
		</div>
	</Modal>
);

export default ConfirmModal;
