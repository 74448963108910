/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-mixed-spaces-and-tabs */
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import {
	configureStore, ThunkAction, Action,
	AsyncThunkPayloadCreator, AsyncThunk, combineReducers, AnyAction,
} from '@reduxjs/toolkit';
import storage from 'redux-persist/es/storage';
import { isDevelopment } from 'utils/constants/base';
import authReducer, { signOut } from './auth';
import counterReducer from './counter-example/counterSlice';
import glossaryReducer from './glossary';
import groupFreeUsersReducer from './group-free-users';
import groupImagesReducer from './group-images';
import groupInvitesReducer from './group-invites';
import groupMembersRankingReducer from './group-members-ranking';
import groupMembersReducer from './group-members';
import groupRankingReducer from './group-ranking';
import groupReducer from './group';
import groupStatisticsReducer from './group-statistics';
import leadsReducer from './leads';
import mediaReducer from './media';
import mediasReducer from './medias';
import mediasFeedReducer from './medias-feed';
import salesHistoryReducer from './sales-history';
import salesListReducer from './sales-list';
import salesUserLevelReducer from './sales-user-level';
import trainingCategoriesReducer from './training-categories';
import trainingReducer from './training';
import trainingsFeedReducer from './trainings-feed';
import trainingsListReducer from './trainings-list';
import userAddressReducer from './user-address';
import userBalanceReducer from './user-balance';
import userBankAccountReducer from './user-bank-account';
import userImagesReducer from './user-images';
import userInfoReducer from './user-info';
import userPasswordReducer from './user-password';
import userPersonalReducer from './user-personal';
import userPixReducer from './user-pix';
import userProfileReducer from './user-profile';
import userRankingReducer from './user-ranking';
import userReferralLinkReducer from './user-referral-link';
import userRegistrationLinkReducer from './user-registration-link';
import userStatisticsReducer from './user-statistics';

const reducers = combineReducers({
	auth: authReducer,
	counter: counterReducer,
	glossary: glossaryReducer,
	group: groupReducer,
	groupFreeUsers: groupFreeUsersReducer,
	groupImages: groupImagesReducer,
	groupInvites: groupInvitesReducer,
	groupMembers: groupMembersReducer,
	groupMembersRanking: groupMembersRankingReducer,
	groupRanking: groupRankingReducer,
	groupStatistics: groupStatisticsReducer,
	leads: leadsReducer,
	media: mediaReducer,
	medias: mediasReducer,
	mediasFeed: mediasFeedReducer,
	salesHistory: salesHistoryReducer,
	salesList: salesListReducer,
	salesUserLevel: salesUserLevelReducer,
	training: trainingReducer,
	trainingCategories: trainingCategoriesReducer,
	trainingsFeed: trainingsFeedReducer,
	trainingsList: trainingsListReducer,
	userAddress: userAddressReducer,
	userBalance: userBalanceReducer,
	userBankAccount: userBankAccountReducer,
	userImages: userImagesReducer,
	userInfo: userInfoReducer,
	userPassword: userPasswordReducer,
	userPersonal: userPersonalReducer,
	userPix: userPixReducer,
	userProfile: userProfileReducer,
	userRanking: userRankingReducer,
	userReferralLink: userReferralLinkReducer,
	userRegistrationLink: userRegistrationLinkReducer,
	userStatistics: userStatisticsReducer,
});

const appReducer = (state: any, action: AnyAction) => {
	if (action.type === signOut().type) {
		storage.removeItem('persist:fluency-strikers-dashboard-auth');
		return reducers(undefined, action);
	}

	return reducers(state, action);
};

const persistConfig = {
	key: 'fluency-strikers-dashboard-auth',
	storage,
	whitelist: ['auth'],
	debug: isDevelopment,
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

declare module '@reduxjs/toolkit' {
  type AsyncThunkConfig = {
  	state?: unknown;
  	dispatch?: AppDispatch;
  	extra?: unknown;
  	rejectValue?: unknown;
  	serializedErrorType?: unknown;
  };

  function createAsyncThunk<
  	Returned,
  	ThunkArg = void,
  	ThunkApiConfig extends AsyncThunkConfig = { state: RootState }
  >(
  	typePrefix: string,
  	payloadCreator: AsyncThunkPayloadCreator<
  	Returned,
  	ThunkArg,
  	ThunkApiConfig
  	>,
  	// eslint-disable-next-line @typescript-eslint/no-explicit-any
  	options?: any
  ): AsyncThunk<Returned, ThunkArg, ThunkApiConfig>;
}
