import { BaseEntity, IdType } from './common';

export enum PixKey {
	BANK_ACCOUNT = 'bank account',
	CNPJ = 'cnpj',
	CPF = 'cpf',
	EMAIL = 'email',
	PHONE = 'phone',
}

export type BankAccountData = {
	cpf: string | null;
	bankId: IdType;
	agency: string;
	accountNumber: string;
};

export type CreateBankAccountDto = BankAccountData;

export type BankAccountResponse = BaseEntity & BankAccountData;

export type PixData = {
	key: PixKey;
	email: string | null;
	phone: string | null;
	cnpj: string | null;
	cpf: string | null;
	bankId: IdType | null;
	agency: string | null;
	accountNumber: string | null;
	countryId: string | null;
	stateId: IdType | null;
	city: string | null;
};

export type CreatePixDataDto = PixData;

export type PixDataResponse = BaseEntity & PixData;

