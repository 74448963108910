import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserImagesService from 'services/user-images-service';
import { FetchStatus, IdType } from 'types/common';
import { ImageListItem } from 'types/images';
import { RootState } from './index';

type UserImagesState = {
	data: ImageListItem[] | null;
	status: FetchStatus;
};

const initialState: UserImagesState = {
	data: null,
	status: FetchStatus.IDLE,
};

export const fetchUserImagesAsync = createAsyncThunk(
	'(GET) /user-images/all',
	async (userId: IdType) => {
		const response = await UserImagesService.getUserImages(userId);

		return response;
	},
);

export const addUserImageAsync = createAsyncThunk(
	'(POST) /user-images',
	async (imageId: IdType) => {
		const response = await UserImagesService.addUserImage(imageId);

		return response;
	},
);

export const deleteUserImagesAsync = createAsyncThunk(
	'(DELETE) /user-images',
	async (imageIds: IdType[]) => {
		const response = await UserImagesService.deleteUserImages(imageIds);

		if (response) return imageIds;
	},
);

export const userImagesSlice = createSlice({
	name: 'user-images',
	initialState,
	reducers: {
		hideUserImages: (state, { payload }: PayloadAction<IdType[]>) => {
			if (state.data) {
				state.data = state.data.map(item => payload.includes(item.id) ? { ...item, isHidden: true } : item);
			}
		},

		showUserImage: (state, { payload }: PayloadAction<IdType>) => {
			if (state.data) {
				state.data = state.data.map(item => item.id === payload ? { ...item, isHidden: false } : item);
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserImagesAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchUserImagesAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE,
				state.data = action.payload;
			})

			.addCase(addUserImageAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(addUserImageAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE,
				state.data = state.data ? [...state.data, action.payload] : [action.payload];
			})

			.addCase(deleteUserImagesAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(deleteUserImagesAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE,
				state.data = state.data?.filter(item => !action.payload?.includes(item.id)) || [];
			});
	},
});

const getUserImages = (state: RootState) => state.userImages;

export const getUserImagesData = createSelector(getUserImages, userImages => userImages.data);

export const { hideUserImages, showUserImage } = userImagesSlice.actions;

export default userImagesSlice.reducer;
