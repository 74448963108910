import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataGrid from 'components/data-grid';
import VideoListItem from 'components/video/video-list-item';
import { fetchTrainingsFeedAsync, getTrainingsFeedData } from 'store/trainings-feed';
import { Training } from 'types/trainings';
import { Dictionary } from 'utils/constants/dictionary';
import ROUTES from 'utils/constants/routes';
import { useAppDispatch, useAppSelector } from 'utils/hooks';

const TrainingsFeed = (): JSX.Element | null => {
	const dispatch = useAppDispatch();
	const trainingsFeed: Training[] | null = useAppSelector(getTrainingsFeedData);

	useEffect(() => {
		dispatch(fetchTrainingsFeedAsync());
	}, [dispatch]);

	if (!trainingsFeed?.length) return null;

	return (
		<div className="home__feed">
			<DataGrid<Training>
				defaultTitle="Trainings"
				items={trainingsFeed}
				renderItem={training => (
					<Link to={`/${ROUTES.TRAINING.BASE}/${String(training.id)}`}>
						<VideoListItem
							previewImageId={training.previewImageId}
							title={training.title}
						/>
					</Link>
				)}
				titleId={Dictionary.homePage.trainings}
			/>
		</div>
	);
};

export default TrainingsFeed;

