import { Row, Col } from 'antd';
import FormItemView from 'components/form-item-view';
import ButtonEdit from 'components/forms/button-edit';
import { getGlossaryData } from 'store/glossary';
import { getUserAddressData } from 'store/user-address';
import { Country, GlossaryEnum } from 'types/glossary';
import { UserProfileAddress } from 'types/user-profile';
import { Dictionary } from 'utils/constants/dictionary';
import getGlossaryValue from 'utils/helpers/get-glossary-value';
import { useAppSelector } from 'utils/hooks';

type Props = {
	onClickEdit: () => void;
};

const PersonalAddressInfo = ({ onClickEdit }: Props): JSX.Element => {
	const countries: Country[] | null = useAppSelector(getGlossaryData<Country[] | null>(GlossaryEnum.COUNTRIES));

	const userAddress: UserProfileAddress | null = useAppSelector(getUserAddressData);

	const selectedCountryStates = countries?.find(c => c.id === userAddress?.countryId)?.states;

	return (
		<div className="profile-form">
			<div className="profile-form__body">
				<Row gutter={24}>
					<Col lg={{ span: 24 }} xs={{ span: 24 }}>
						<FormItemView defaultLabel="Street" labelId={Dictionary.personalDataPage.address.street}>
							{userAddress?.street}
						</FormItemView>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col lg={{ span: 12 }} xs={{ span: 24 }}>
						<FormItemView defaultLabel="Number" labelId={Dictionary.personalDataPage.address.number}>
							{userAddress?.house}
						</FormItemView>
					</Col>

					<Col lg={{ span: 12 }} xs={{ span: 24 }}>
						<FormItemView
							defaultLabel="Complement"
							labelId={Dictionary.personalDataPage.address.complement}
						>
							{userAddress?.apartment}
						</FormItemView>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col lg={{ span: 24 }} xs={{ span: 24 }}>
						<FormItemView
							defaultLabel="Neighborhood"
							labelId={Dictionary.personalDataPage.address.neighborhood}
						>
							{userAddress?.district}
						</FormItemView>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col lg={{ span: 12 }} xs={{ span: 24 }}>
						<FormItemView defaultLabel="Country" labelId={Dictionary.personalDataPage.address.country}>
							{userAddress && countries && getGlossaryValue(userAddress.countryId, countries, 'name')}
						</FormItemView>
					</Col>

					<Col lg={{ span: 12 }} xs={{ span: 24 }}>
						<FormItemView defaultLabel="State" labelId={Dictionary.personalDataPage.address.state}>
							{userAddress?.stateId && selectedCountryStates &&
							getGlossaryValue(userAddress.stateId, selectedCountryStates, 'name')}
						</FormItemView>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col lg={{ span: 12 }} xs={{ span: 24 }}>
						<FormItemView defaultLabel="City" labelId={Dictionary.personalDataPage.address.city}>
							{userAddress?.city}
						</FormItemView>
					</Col>

					<Col lg={{ span: 12 }} xs={{ span: 24 }}>
						<FormItemView defaultLabel="ZIP code" labelId={Dictionary.personalDataPage.address.zip}>
							{userAddress?.zip}
						</FormItemView>
					</Col>
				</Row>
			</div>

			<ButtonEdit onClick={onClickEdit} />
		</div>
	);
};

export default PersonalAddressInfo;
