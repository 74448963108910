import { Routes, Route } from 'react-router-dom';
import ROUTES from 'utils/constants/routes';
import ForgotPassword from 'views/forgot-password';
import Login from 'views/login';
import NewPassword from 'views/new-password';
import NotFound from 'views/not-found';
import ResetPassword from 'views/reset-password';

const { FORGOT_PASSWORD, NEW_PASSWORD, RESET_PASSWORD } = ROUTES.AUTH;

const AuthRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Login />} index />
		<Route element={<ForgotPassword />} path={FORGOT_PASSWORD} />
		<Route element={<NewPassword />} path={NEW_PASSWORD} />
		<Route element={<ResetPassword />} path={RESET_PASSWORD} />
		<Route element={<NotFound />} path="*" />
	</Routes>
);

export default AuthRoutes;
