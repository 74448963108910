export class ListResult<T> {
	constructor(public readonly items: Array<T>, public readonly total: number) {}
}

export interface ListResultState<T> {
	data: ListResult<T> | null;
	status: FetchStatus;
	error: ResultFailure;
}

export interface ResultState<T> {
	data: T;
	status: FetchStatus;
	error: ResultFailure;
}

export interface WithFilter<T> {
	filter: T;
}

export enum FetchStatus {
	FAILED = 'failed',
	IDLE = 'idle',
	LOADING = 'loading',
}

export enum Sorting {
	ASC = 'ASC',
	DESC = 'DESC',
}

export enum AppTheme {
	DARK,
	LIGHT,
}

export enum ImageContainer {
	GROUP_AVATAR = 'group-avatar',
	GROUP_IMAGES = 'group-images',
	USER_AVATAR = 'user-avatar',
	USER_IMAGES = 'user-images',
	PREVIEW_IMAGES = 'preview-images',
}

export type ResultFailure = Error | null;

export type IdType = number;

export type BaseMessageDto = {
	message: string;
};

export interface BaseEntity {
	id: IdType;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
}

export interface UpdateDataPayload<T> {
	id: IdType;
	data: T;
}

export interface DateRangeFilter {
	startDate: string;
	endDate: string;
}

export interface SearchFilter {
	search: string;
}

export interface SortingFilter {
	sorting: Sorting;
}

export type LinkResponse = {
	link: string;
};

export interface TokenDto {
	token: string;
}

export interface Location {
	city: string;
	countryId: IdType;
	stateId: IdType | null;
}
