import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import { Checkbox, Form } from 'formik-antd';
import { Formik, FormikProps } from 'formik';
import Button from 'components/forms/button';
import { TextArea } from 'components/inputs-formik';
import Message from 'components/message';
import { reportComplaintAsync } from 'store/complaints';
import { IdType } from 'types/common';
import { Complaint, ComplaintRequest, ComplaintResponse } from 'types/complaints';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch } from 'utils/hooks';
import { hideUserImages } from 'store/user-images';
import { crossWhite } from 'assets/images';
import './ReportModal.scss';

const initialValues: Complaint = {
	description: '',
	isIndecent: false,
	isRude: false,
	isSensitive: false,
};

type CheckboxProps = {
	defaultMessage: string;
	defaultSubtitle: string;
	labelId: string;
	name: string;
	subtitleId: string;
};

type Props = {
	userId?: IdType | null;
	groupId?: IdType | null;
	userImageIds?: IdType[] | null;
	groupImageIds?: IdType[] | null;
	onClose: () => void;
	onFulfilled?: () => void;
};

const CheckboxItem = ({
	defaultMessage, defaultSubtitle, labelId, name, subtitleId,
}: CheckboxProps): JSX.Element => (
	<div className="report-modal__checkbox-container">
		<Checkbox className="report-modal__checkbox" name={name}>
			<div className="report-modal__checkbox-text">
				<div className="report-modal__checkbox-text_title">
					<FormattedMessage defaultMessage={defaultMessage} id={labelId} />
				</div>
				<div className="report-modal__checkbox-text_subtitle">
					<FormattedMessage defaultMessage={defaultSubtitle} id={subtitleId} />
				</div>
			</div>
		</Checkbox>
	</div>
);

const InnerForm = ({ dirty }: FormikProps<ComplaintRequest>): JSX.Element => (
	<Form>
		<div className="report-modal__body">
			<div className="report-modal__title">
				<FormattedMessage defaultMessage={"What's happening?"} id={Dictionary.reportModal.title} />
			</div>

			<div className="report-modal__text">
				<FormattedMessage
					defaultMessage={'Check all that apply'}
					id={Dictionary.reportModal.checkAllThatApply}
				/>
			</div>

			<div className="report-modal__checkboxes">
				<CheckboxItem
					defaultMessage="Is indesent"
					defaultSubtitle="Indecent text"
					labelId={Dictionary.reportModal.indencentAvatar}
					name="isIndecent"
					subtitleId={Dictionary.reportModal.indencentSubtext}
				/>

				<CheckboxItem
					defaultMessage="Is sensitive"
					defaultSubtitle="Sensitive text"
					labelId={Dictionary.reportModal.sensitive}
					name="isSensitive"
					subtitleId={Dictionary.reportModal.sensitiveSubtext}
				/>

				<CheckboxItem
					defaultMessage="Is Rude"
					defaultSubtitle="Rude text"
					labelId={Dictionary.reportModal.salaciousPhotos}
					name="isRude"
					subtitleId={Dictionary.reportModal.salaciousSubtext}
				/>
			</div>

			<div className="report-modal__textarea-label">
				<FormattedMessage defaultMessage={'Is something else?'} id={Dictionary.reportModal.comment} />
			</div>

			<TextArea
				className="report-modal__description"
				defaultPlaceholder="Default placeholder"
				name="description"
				placeholderId={Dictionary.reportModal.commentPlaceholder}
			/>

			<Button
				className="report-modal__submit"
				defaultText="Submit"
				disabled={!dirty}
				textId={Dictionary.reportModal.submitReport}
				type="submit"
			/>
		</div>
	</Form>
);

const ReportModal = ({
	userId = null, groupId = null, userImageIds = null, groupImageIds = null, onClose, onFulfilled,
}: Props): JSX.Element => {
	const dispatch = useAppDispatch();

	const handleSubmit = (values: ComplaintRequest): void => {
		dispatch(reportComplaintAsync(values)).then((res) => {
			res.payload && Message.success({ messageId: Dictionary.message.success.complaintSent });

			const complaint = res.payload as ComplaintResponse;

			if (complaint.groupImages) {
				dispatch(hideUserImages(complaint.groupImages.map(item => item.id)));
			}

			if (complaint.userImages) {
				dispatch(hideUserImages(complaint.userImages.map(item => item.id)));
			}

			onFulfilled && onFulfilled();
		});
	};

	return (
		<Modal
			centered
			className="report-modal"
			closable
			closeIcon={<img alt="" src={crossWhite} />}
			footer={null}
			onCancel={onClose}
			visible
			width={700}
		>
			<Formik<ComplaintRequest>
				component={InnerForm}
				initialValues={{
					...initialValues,
					userId,
					groupId,
					userImageIds,
					groupImageIds,
				}}
				onSubmit={handleSubmit}
			/>
		</Modal>
	);
};

export default ReportModal;
