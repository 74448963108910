import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import GroupsUsersService from 'services/groups-users-service';
import UserService from 'services/user-service';
import { FetchStatus, IdType, ResultState } from 'types/common';
import { GroupFreeUsersFilter, GroupFreeUsersListItem } from 'types/group-free-users';
import { GROUP_FREE_USERS_FILTER_DEFAULT } from 'utils/constants/default-filters';
import { RootState } from './index';

type GroupFreeUsersState = ResultState<GroupFreeUsersListItem[] | null> & { filter: GroupFreeUsersFilter };

const initialState: GroupFreeUsersState = {
	data: null,
	status: FetchStatus.IDLE,
	error: null,
	filter: GROUP_FREE_USERS_FILTER_DEFAULT,
};

export const fetchGroupFreeUsersAsync = createAsyncThunk(
	'(GET) /users/without-group',
	async (filter: GroupFreeUsersFilter) => {
		const response = await UserService.getUsersWithoutGroup(filter);

		return response;
	},
);

export const inviteToGroupAsync = createAsyncThunk(
	'(PATCH) /groups-users/invite-to-group',
	async (userId: IdType) => {
		await GroupsUsersService.inviteToGroup(userId);

		return userId;
	},
);

export const groupFreeUsersSlice = createSlice({
	name: 'group-free-users',
	initialState,
	reducers: {
		setGroupFreeUsersFilter(state, { payload }: PayloadAction<GroupFreeUsersFilter>) {
			state.filter = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGroupFreeUsersAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchGroupFreeUsersAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			})

			.addCase(inviteToGroupAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(inviteToGroupAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;

				if (state.data) {
					state.data = state.data.filter(item => item.id !== action.payload);
				}
			});
	},
});

const getGroupFreeUsers = (state: RootState) => state.groupFreeUsers;

export const { setGroupFreeUsersFilter } = groupFreeUsersSlice.actions;

export const getGroupFreeUsersData = createSelector(getGroupFreeUsers, groupFreeUsers => groupFreeUsers.data);
export const getGroupFreeUsersFilter = createSelector(getGroupFreeUsers, groupFreeUsers => groupFreeUsers.filter);

export default groupFreeUsersSlice.reducer;
