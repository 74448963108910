import { BaseMessageDto, DateRangeFilter } from 'types/common';
import { TransactionHistoryItem, UserBalance } from 'types/transactions';
import { TRANSACTIONS } from 'utils/constants/end-points';
import Http from './http';

class TransactionsService {
	getTransactionsHistory = (filter: Partial<DateRangeFilter> = {}): Promise<TransactionHistoryItem[]> => Http.get(
		`${TRANSACTIONS}/history`, { params: { ...filter } });

	getUserBalance = (): Promise<UserBalance> => Http.get(`${TRANSACTIONS}/balance`);

	paymentRequest = (amount: number): Promise<BaseMessageDto> => Http.post(
		`${TRANSACTIONS}/payment-request`, { amount });
}

export default new TransactionsService();
