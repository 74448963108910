import { SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { plusWhite } from 'assets/images';
import Avatar from 'components/avatar';
import Button from 'components/forms/button';
import { InputImageFile } from 'components/inputs';
import { InputText } from 'components/inputs-formik';
import { getUserId } from 'store/auth';
import { createGroupAsync } from 'store/group';
import { uploadImageAsync } from 'store/upload';
import { fetchUserProfileAsync } from 'store/user-profile';
import { IdType, ImageContainer } from 'types/common';
import { GroupDataRequest } from 'types/group';
import { UploadImageResponse } from 'types/upload';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { useGroupValidationSchema } from 'utils/validation/schemas';
import './NewGroupModal.scss';

type Props = {
	onClose: () => void;
};

const initialValues: GroupDataRequest = { avatarImageId: null, name: '' };

const InnerForm = ({
	dirty, isValid, setFieldValue, values, onClose,
}: FormikProps<GroupDataRequest> & Props): JSX.Element => {
	const dispatch = useAppDispatch();

	const handleChangeInputFile = (e: SyntheticEvent): void => {
		const target = e.target as HTMLInputElement;
		const file: File | null = target.files && target.files[0];

		if (file) {
			//TODO: image size validation
			dispatch(uploadImageAsync({ file, type: ImageContainer.GROUP_AVATAR }))
				.then(res => setFieldValue('avatarImageId', (res.payload as UploadImageResponse).imageId));
		}
	};

	return (
		<Form>
			<InputText
				defaultPlaceholder="Group name"
				name="name"
				placeholderId={Dictionary.newGroupModal.groupNamePlaceholder}
			/>

			<div className="new-group-modal__avatar-container">

				{values.avatarImageId && (
					<Avatar
						alt="new-group-avatar"
						containerName={ImageContainer.GROUP_AVATAR}
						imageId={values.avatarImageId}
					/>
				)}

				{!values.avatarImageId && (
					<div className="new-group-modal__add-photo">
						<div className="new-group-modal__add-photo-btn">
							<img alt="" src={plusWhite} />
						</div>
						<div className="new-group-modal__add-photo-label">
							<FormattedMessage defaultMessage="Add photo" id={Dictionary.newGroupModal.addAvatar} />
						</div>
						<InputImageFile
							id="new-group__add-photo-input_inner"
							labelId={Dictionary.common.photos.addPhoto}
							onChange={handleChangeInputFile}
						/>
					</div>
				)}

				<div className="new-group-modal__add-photo-text">
					<FormattedMessage
						defaultMessage="Only .jpg and .png"
						id={Dictionary.common.photos.photoFormatMsg}
					/>
				</div>
			</div>

			<div className="new-lead-modal__footer">
				<Button
					className="btn-green"
					defaultText="Create"
					disabled={!dirty || !isValid}
					textId={Dictionary.common.create}
					type="submit"
				/>

				<Button defaultText="Cancel" onClick={onClose} textId={Dictionary.common.cancel} />
			</div>
		</Form>
	);
};

const NewGroupModal = ({ onClose }: Props): JSX.Element => {
	const dispatch = useAppDispatch();

	const userId: IdType | null = useAppSelector(getUserId);

	const handleSubmit = (values: GroupDataRequest) => {
		dispatch(createGroupAsync(values)).then(() => {
			if (userId) dispatch(fetchUserProfileAsync(userId));
		});
	};

	return (
		<Modal
			centered
			className="new-group-modal"
			closable={false}
			footer={null}
			onCancel={onClose}
			title={
				<h2>
					<FormattedMessage defaultMessage="Create group" id={Dictionary.newGroupModal.title} />
				</h2>
			}
			visible
			width={700}
		>
			<Formik<GroupDataRequest>
				component={(formikProps) => <InnerForm onClose={onClose} {...formikProps} />}
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={useGroupValidationSchema()}
			/>
		</Modal>
	);
};

export default NewGroupModal;

