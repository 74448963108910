import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { backPurple } from 'assets/images';
import { Dictionary } from 'utils/constants/dictionary';
import './PageTitle.scss';

type Props = {
	backLink?: string | boolean;
	backLinkText?: string | React.ReactNode;
	centered?: boolean;
	defaultText?: string;
	textId?: string;
};

const backButton = <FormattedMessage defaultMessage="Back" id={Dictionary.common.back} />;

const PageTitle = (
	{ backLink, backLinkText = backButton, centered, defaultText, textId }: Props,
): JSX.Element => {
	const navigate = useNavigate();
	const goBack = () => navigate(-1);

	const renderButton = (onClick?: () => void) => (
		<Button className="page-title__back-btn" onClick={onClick}>
			<img alt="" src={backPurple} />
			{backLinkText}
		</Button>
	);

	return (
		<div className={`page-title ${centered ? 'page-title-center' : ''}`}>
			{backLink && (
				typeof backLink === 'string'
					? <Link className="page-title__back-wrap" to={backLink}>
						{renderButton()}
					</Link>
					: <div className="page-title__back-wrap">
						{renderButton(goBack)}
					</div>
			)}
			{defaultText && <h1>
				{textId ? <FormattedMessage defaultMessage={defaultText} id={textId} /> : defaultText}
			</h1>}
		</div>
	);
};

export default PageTitle;
