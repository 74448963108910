import { useParams } from 'react-router-dom';
import ProfilePage from '../profile-page';

const ProfileItem = (): JSX.Element | null => {
	const { id } = useParams<{ id: string }>();

	if (!id) return null;

	return (
		<ProfilePage profileId={+id} />
	);
};

export default ProfileItem;
