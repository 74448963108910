import { FormattedMessage } from 'react-intl';

type Props = {
	className?: string;
	defaultText?: string;
	disabled?: boolean;
	type?: 'button' | 'submit' | 'reset';
	textId?: string;
	imgSrc?: string;
	onClick?: () => void;
};

const Button = ({ className, defaultText, disabled, type = 'button', textId, imgSrc, onClick }: Props): JSX.Element => (
	<button
		className={`btn ${className ?? ''}`}
		disabled={disabled}
		onClick={onClick}
		type={type}
	>
		{imgSrc && <img alt="" src={imgSrc} />}

		<span>
			{defaultText && textId && <FormattedMessage defaultMessage={defaultText} id={textId} />}
		</span>
	</button>
);

export default Button;
