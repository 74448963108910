import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import Button from 'components/forms/button';
import { InputPassword } from 'components/inputs-formik';
import { useChangePasswordValidationSchema } from 'utils/validation/schemas';
import { Dictionary } from 'utils/constants/dictionary';
import './ChangePasswordForm.scss';

export type ChangePasswordFormValues = {
	currentPassword: string;
	newPassword: string;
	newPasswordConfirmation: string;
};

type Props = {
	submitBtnTextDefault: string;
	submitBtnTextId: string;
	onSubmit: (password: ChangePasswordFormValues) => void;
};

const ChangePasswordForm = ({
	submitBtnTextDefault,
	submitBtnTextId,
	onSubmit,
}: Props): JSX.Element => {
	const InnerForm = ({ dirty, isValid }: FormikProps<ChangePasswordFormValues>): JSX.Element => (
		<Form className="change-password__form change-password-form">
			<div className="change-password-form__top">
				***
			</div>
			<div className="change-password-form__body">
				<div className="change-password-form__wrap">
					<InputPassword
						defaultLabel="Current password"
						labelId={Dictionary.changePasswordPage.currentPassword}
						name="currentPassword"
					/>

					<InputPassword
						defaultLabel="New password"
						labelId={Dictionary.changePasswordPage.newPassword}
						name="newPassword"
					/>

					<InputPassword
						defaultLabel="Confirm your new password"
						labelId={Dictionary.changePasswordPage.confirmNewPassword}
						name="newPasswordConfirmation"
					/>
				</div>

				<div className="change-password-form__footer">
					<Button
						defaultText={submitBtnTextDefault}
						disabled={!dirty || !isValid}
						textId={submitBtnTextId}
						type="submit"
					/>
				</div>
			</div>
		</Form>
	);

	return (
		<Formik
			component={InnerForm}
			initialValues={{ currentPassword: '', newPassword: '', newPasswordConfirmation: '' }}
			onSubmit={onSubmit}
			validationSchema={useChangePasswordValidationSchema()}
		/>
	);
};

export default ChangePasswordForm;
