import { createContext, FC, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import English from './lang/en.json';
import Portuguese from './lang/pt.json';
import Spanish from './lang/es.json';

const portugueseLocales: string[] = [
	'pt',
	'pt-GW',
	'pt-LU',
	'pt-AO',
	'pt-BR',
	'pt-CH',
	'pt-MZ',
	'pt-PT',
	'pt-TL',
	'pt-GQ',
	'pt-CV',
	'pt-MO',
	'pt-ST',
];

const spanishLocales: string[] = [
	'es',
	'es-419',
	'es-AR',
	'es-BO',
	'es-BR',
	'es-BZ',
	'es-CL',
	'es-CO',
	'es-CR',
	'es-CU',
	'es-DO',
	'es-EA',
	'es-EC',
	'es-ES',
	'es-GQ',
	'es-GT',
	'es-HN',
	'es-IC',
	'es-MX',
	'es-NI',
	'es-PA',
	'es-PE',
	'es-PH',
	'es-PR',
	'es-SV',
	'es-PY',
	'es-US',
	'es-UY',
	'es-VE',
];

const local = navigator.language;
let lang: Record<string, string> = English;

if (portugueseLocales.includes(local)) {
	lang = Portuguese;
}

if (spanishLocales.includes(local)) {
	lang = Spanish;
}

const Context = createContext({});

const LocalizationProvider: FC = ({ children }): JSX.Element => {
	const [locale, setLocale] = useState(local);
	const [messages, setMessages] = useState<Record<string, string>>(lang);

	const selectLanguage = (value: string): void => {
		setLocale(value);
		if (portugueseLocales.includes(local)) {
			setMessages(Portuguese);
			return;
		}

		if (spanishLocales.includes(local)) {
			setMessages(Spanish);
			return;
		}

		setMessages(English);
	};

	const contextValue = useMemo(() => ({ locale, selectLanguage }), [locale]);

	return (
		<Context.Provider value={contextValue}>
			<IntlProvider locale={locale} messages={messages}>
				{children}
			</IntlProvider>
		</Context.Provider>
	);
};

export default LocalizationProvider;
