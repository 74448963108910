import { FocusEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useField } from 'formik';
import { FormItem, Input, TextAreaProps } from 'formik-antd';

type Props = {
	defaultLabel?: string;
	defaultPlaceholder?: string;
	labelId?: string;
	name: string;
	nullable?: boolean;
	placeholderId?: string;
	validateStatus?: '' | 'error' | 'success' | 'warning' | 'validating';
	help?: string | number | boolean;
};

const { TextArea: InputTextArea } = Input;

const TextArea = ({
	defaultLabel,
	defaultPlaceholder,
	name,
	labelId,
	placeholderId,
	maxLength,
	nullable = true,
	validateStatus,
	help,
	required,
	className,
	onBlur,
	onChange,
	...restProps
}: TextAreaProps & Props): JSX.Element => {
	const [field, meta, actions] = useField(name);
	const intl = useIntl();

	const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
		nullable && typeof e.target.value === 'string' && actions.setValue(e.target.value.trim() || null);
		onBlur && onBlur(e);
	};

	const validStatus = meta.touched ? (validateStatus || (meta.error ? 'error' : '')) : '';
	const helpMessage = meta.touched && (help || meta.error);

	return (
		<FormItem
			colon={false}
			help={helpMessage}
			htmlFor={name}
			label={defaultLabel && labelId && <FormattedMessage defaultMessage={defaultLabel} id={labelId} />}
			name={name}
			required={required}
			validateStatus={validStatus}
		>
			<InputTextArea
				{...restProps}
				{...field}
				id={name}
				onBlur={handleBlur}
				onChange={onChange}
				placeholder={
					placeholderId && defaultPlaceholder &&
					intl.formatMessage({ id: placeholderId, defaultMessage: defaultPlaceholder }, {
						maxLength: maxLength || 150,
					})
				}
				rows={3}
				value={restProps.value ?? field.value}
			/>
		</FormItem>
	);
};

export default TextArea;
