import { useEffect, useState } from 'react';
//import { Button, Select } from 'antd';
//import { FormattedMessage } from 'react-intl';
import Video from 'components/video';
import Loader from 'components/loader';
//import Message from 'components/message';
import { VideoFile } from 'types/video-file';
//import { Dictionary } from 'utils/constants/dictionary';
import './VideoItem.scss';

type VideoData = {
	title: string;
	link: string;
	description: string;
	files: VideoFile[];
	pageTitle?: string | React.ReactElement;
};

type Props = {
	isLoading?: boolean;
};

const VideoItem = (props: VideoData & Props): JSX.Element => {
	const {	link, description,	files, isLoading, pageTitle } = props;

	const [selectedFile, setSelectedFile] = useState<string>();

	useEffect(() => {
		if (files?.length) { setSelectedFile(files[files.length - 1]?.link); }
	}, [files]);

	if (isLoading) return <Loader />;

	return (
		<div className="VideoItem">
			{pageTitle}
			<div className="VideoItem__wrap">
				<div className="VideoItem__left">
					<Video link={link} />
				</div>
				<div className="VideoItem__right">
					<div className="VideoItem__desc">
						{description}
					</div>
					{/* <div className="VideoItem__footer">
						<Select
							dropdownMatchSelectWidth
							onSelect={setSelectedFile}
							style={{ width:200 }}
							value={selectedFile}
						>
							{files?.map(file=>(
								<Select.Option key={file.link} value={file.link}>
									{file.width}
									{'x'}
									{file.height}
									{' '}
									{(file.size / 1024 / 1024).toFixed(2)}
									Mb
								</Select.Option>
							))}
						</Select>
						<Button
							className="btn btn-purple"
							download
							href={selectedFile}
							onClick={() => Message.success({ messageId: Dictionary.message.success.downloadStarted })}
							type="primary"
						>
							<FormattedMessage defaultMessage="Download" id={Dictionary.common.download} />
						</Button>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default VideoItem;
