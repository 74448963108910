import { useNavigate, useSearchParams } from 'react-router-dom';
import SetPasswordForm from 'components/forms/set-password-form';
import Message from 'components/message';
import { setPasswordAsync } from 'store/user-password';
import { BaseMessageDto } from 'types/common';
import { useAppDispatch } from 'utils/hooks';
import NotFound from 'views/not-found';
import './ResetPassword.scss';

const ResetPassword = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const token: string | null = searchParams.get('token');

	const handleSubmit = (password: string): void => {
		if (token && password) {
			dispatch(setPasswordAsync({ password, token }))
				.then((res) => {
					if (res.payload) {
						Message.success({ messageText: (res.payload as BaseMessageDto).message });
						navigate('/');
					}
				});

		}
	};

	if (!token) return <NotFound />;

	return (
		<SetPasswordForm onSubmit={handleSubmit} />
	);
};

export default ResetPassword;
