import { Routes, Route } from 'react-router-dom';
import ROUTES from 'utils/constants/routes';
import NotFound from 'views/not-found';
import Trainings from 'views/trainings';
import Training from 'views/trainings/item';

const TrainingRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Trainings />} index />
		<Route element={<Training />} path={ROUTES.TRAINING.ITEM} />
		<Route element={<NotFound />} path="*" />
	</Routes>
);

export default TrainingRoutes;
