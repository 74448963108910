import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import LeadService from 'services/leads-service';
import { FetchStatus, IdType, ResultState, UpdateDataPayload } from 'types/common';
import { Lead, LeadDto, LeadsFilter } from 'types/leads';
import { LEADS_FILTER_DEFAULT } from 'utils/constants/default-filters';
import { RootState } from './index';

const initialState: ResultState<Lead[]> & { filter: LeadsFilter } = {
	data: [],
	filter: LEADS_FILTER_DEFAULT,
	status: FetchStatus.IDLE,
	error: null,
};

export const fetchLeadsAsync = createAsyncThunk(
	'leads/fetchAll',
	async (filter: LeadsFilter) => {
		const response: Lead[] = await LeadService.fetchLeads(filter);

		return response;
	},
);

export const addLeadAsync = createAsyncThunk(
	'leads/createItem',
	async (data: LeadDto, { getState, dispatch }) => {
		const response: Lead = await LeadService.addLead(data);
		const filter = getState().leads.filter;

		dispatch(fetchLeadsAsync(filter));

		return response;
	},
);

export const updateLeadAsync = createAsyncThunk(
	'leads/updateItem',
	async (req: UpdateDataPayload<LeadDto>) => {
		const response: Lead = await LeadService.updateLead(req.id, req.data);

		return response;
	},
);

export const deleteLeadAsync = createAsyncThunk(
	'leads/deleteItem',
	async (id: IdType) => {
		await LeadService.deleteLead(id);

		return id;
	},
);

export const leadsSlice = createSlice({
	name: 'leads',
	initialState,
	reducers: {
		setLeadsFilter(state, { payload }: PayloadAction<LeadsFilter>) {
			state.filter = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			// FETCH
			.addCase(fetchLeadsAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchLeadsAsync.fulfilled, (state, { payload }) => {
				state.status = FetchStatus.IDLE;
				state.data = payload;
			})

			// CREATE
			.addCase(addLeadAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(addLeadAsync.fulfilled, (state) => {
				state.status = FetchStatus.IDLE;
			})

			// UPDATE
			.addCase(updateLeadAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(updateLeadAsync.fulfilled, (state, { payload }) => {
				state.status = FetchStatus.IDLE;
				state.data = state.data.map(item => {
					if (item.id === payload.id) {	return payload; }
					return item;
				});
			})

			// DELETE
			.addCase(deleteLeadAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(deleteLeadAsync.fulfilled, (state, { payload }) => {
				state.status = FetchStatus.IDLE;
				state.data = state.data.filter(item=>item.id !== payload);
			});
	},
});

const getLeads = (state: RootState) => state.leads;

export const { setLeadsFilter } = leadsSlice.actions;

export const getLeadsData = createSelector(getLeads, leads => leads.data);
export const getLeadsFilter = createSelector(getLeads, leads => leads.filter);

export default leadsSlice.reducer;
