import MultipleSelect from 'components/multiple-select';
import { MultipleSelectItem } from 'components/multiple-select/MultipleSelect';
import { getGroupFreeUsersFilter, setGroupFreeUsersFilter } from 'store/group-free-users';
import { GroupFreeUsersFilter } from 'types/group-free-users';
import { RatingLevel } from 'types/rating';
import { Dictionary } from 'utils/constants/dictionary';
import { getTierClassname } from 'utils/helpers/tiers';
import './UserLevelSelector.scss';

const filterButtons: MultipleSelectItem<GroupFreeUsersFilter>[] = [
	{
		className: getTierClassname(RatingLevel.GOAL_GETTER_I),
		defaultMessage: RatingLevel.GOAL_GETTER_I,
		filterKey: 'goalGetterI',
		labelId: Dictionary.tier.goalGetter_I,
	},
	{
		className: getTierClassname(RatingLevel.GOAL_GETTER_I),
		defaultMessage: RatingLevel.GOAL_GETTER_II,
		filterKey: 'goalGetterII',
		labelId: Dictionary.tier.goalGetter_II,
	},
	{
		className: getTierClassname(RatingLevel.LEADER_I),
		defaultMessage: RatingLevel.LEADER_I,
		filterKey: 'leaderI',
		labelId: Dictionary.tier.leader_I,
	},
	{
		className: getTierClassname(RatingLevel.LEADER_II),
		defaultMessage: RatingLevel.LEADER_II,
		filterKey: 'leaderII',
		labelId: Dictionary.tier.leader_II,
	},
	{
		className: getTierClassname(RatingLevel.TRUE_LEADER_I),
		defaultMessage: RatingLevel.TRUE_LEADER_I,
		filterKey: 'trueLeaderI',
		labelId: Dictionary.tier.trueLeader_I,
	},
	{
		className: getTierClassname(RatingLevel.TRUE_LEADER_II),
		defaultMessage: RatingLevel.TRUE_LEADER_II,
		filterKey: 'trueLeaderII',
		labelId: Dictionary.tier.trueLeader_II,
	},
	{
		className: getTierClassname(RatingLevel.MASTER),
		defaultMessage: RatingLevel.MASTER,
		filterKey: 'master',
		labelId: Dictionary.tier.master,
	},
	{
		className: getTierClassname(RatingLevel.ULTIMATE_CHALLENGE),
		defaultMessage: RatingLevel.ULTIMATE_CHALLENGE,
		filterKey: 'ultimateChallenge',
		labelId: Dictionary.tier.ultimateChallenge,
	},
];

const UserLevelSelector = (): JSX.Element => (
	<MultipleSelect<GroupFreeUsersFilter>
		buttons={filterButtons}
		className="user-level-selector"
		defaultMessage="Tier"
		filterSelector={getGroupFreeUsersFilter}
		labelId={Dictionary.groupFreeUsersPage.tier}
		setFilterAction={setGroupFreeUsersFilter}
	/>
);

export default UserLevelSelector;
