import { Routes, Route } from 'react-router-dom';
import ROUTES from 'utils/constants/routes';
import Home from 'views/home';
import Layout from 'views/layout';
import NotFound from 'views/not-found';
import CommissionRoutes from './CommissionRoutes';
import GroupRoutes from './GroupRoutes';
//import LeadsRoutes from './LeadsRoutes';
import MediasRoutes from './MediasRoutes';
import ProfileRoutes from './ProfileRoutes';
import RankingRoutes from './RankingRoutes';
import SalesRoutes from './SalesRoutes';
import TrainingRoutes from './TrainingRoutes';

const MainRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Layout />} path="/">
			<Route element={<Home />} index />
			<Route element={<CommissionRoutes />} path={`${ROUTES.COMMISSIONS.BASE}/*`} />
			<Route element={<GroupRoutes />} path={`${ROUTES.GROUP.BASE}/*`} />
			{/* <Route element={<LeadsRoutes />} path={`${ROUTES.LEADS.BASE}/*`} /> */}
			<Route element={<MediasRoutes />} path={`${ROUTES.MEDIAS.BASE}/*`} />
			<Route element={<ProfileRoutes />} path={`${ROUTES.PROFILE.BASE}/*`} />
			<Route element={<RankingRoutes />} path={`${ROUTES.RANKING.BASE}/*`} />
			<Route element={<SalesRoutes />} path={`${ROUTES.SALES.BASE}/*`} />
			<Route element={<TrainingRoutes />} path={`${ROUTES.TRAINING.BASE}/*`} />
			<Route element={<NotFound />} path="*" />
		</Route>
	</Routes>
);

export default MainRoutes;
