import { Button } from 'antd';
import { arrowLeft, arrowRight, arrowLeftWhite, arrowRightWhite } from 'assets/images';
import './SwitchPrevNext.scss';

type Props = {
	className?: string;
	text: JSX.Element;
	nextDisabled?: boolean;
	prevDisabled?: boolean;
	darkMode?: boolean;
	onClickNext: () => void;
	onClickPrev: () => void;
};

const SwitchPrevNext = ({
	className, text, nextDisabled,
	prevDisabled, darkMode, onClickNext, onClickPrev,
}: Props): JSX.Element => (
	<div className={`switch-prev-next ${darkMode ? 'dark-mode' : ''} ${className || ''}`}>
		<Button
			className="switch-prev-next__btn switch-prev-next__prev"
			disabled={prevDisabled}
			onClick={onClickPrev}
		>
			<img alt="" src={darkMode ? arrowLeftWhite : arrowLeft} />
		</Button>
		<div className="switch-prev-next__text">
			{text}
		</div>
		<Button
			className="switch-prev-next__btn switch-prev-next__next"
			disabled={nextDisabled}
			onClick={onClickNext}
		>
			<img alt="" src={darkMode ? arrowRightWhite : arrowRight} />
		</Button>
	</div>
);

export default SwitchPrevNext;
