import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataGrid from 'components/data-grid';
import Loader from 'components/loader/Loader';
import VideoListItem from 'components/video/video-list-item';
import { fetchTrainingsByIdAsync, getTrainingsData, isTrainingsLoading } from 'store/trainings-list';
import { IdType } from 'types/common';
import { Training } from 'types/trainings';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import ROUTES from 'utils/constants/routes';

type Props = {
	categoryId: IdType;
};

const TrainingsList = ({ categoryId }: Props): JSX.Element => {
	const dispatch = useAppDispatch();
	const trainings = useAppSelector(getTrainingsData);
	const isLoading = useAppSelector(isTrainingsLoading);

	useEffect(() => {
		dispatch(fetchTrainingsByIdAsync(categoryId));
	}, [categoryId, dispatch]);

	if (isLoading) return <Loader />;

	return (
		<div className="trainings-list">
			<DataGrid<Training>
				items={trainings || []}
				renderItem={training => (
					<Link to={`/${ROUTES.TRAINING.BASE}/${String(training.id)}`}>
						<VideoListItem
							previewImageId={training.previewImageId}
							title={training.title}
						/>
					</Link>
				)}
			/>
		</div>
	);
};

export default TrainingsList;

