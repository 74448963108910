import { useEffect } from 'react';
import { getUserId } from 'store/auth';
import { fetchUserProfileAsync, getUserProfileData } from 'store/user-profile';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { IdType } from 'types/common';
import { UserProfile } from 'types/user-profile';

export const useUserProfile = (): UserProfile | null => {
	const dispatch = useAppDispatch();
	const userId: IdType | null = useAppSelector(getUserId);
	const user = useAppSelector(getUserProfileData);

	useEffect(() => {
		if (!user && userId) {
			dispatch(fetchUserProfileAsync(userId));
		}
	}, [dispatch, user, userId]);

	return user;
};
