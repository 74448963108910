import ImageGallery from 'components/image-gallery';
import Message from 'components/message';
import { useEffect } from 'react';
import {
	addGroupImageAsync, deleteGroupImagesAsync, fetchGroupImagesAsync, getGroupImagesData,
} from 'store/group-images';
import { IdType, ImageContainer } from 'types/common';
import { ComplaintType } from 'types/complaints';
import { ImageListItem } from 'types/images';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './GroupImages.scss';

type Props = {
	groupId: IdType;
	allowEdit?: boolean;
};

const GroupImages = ({ allowEdit = false, groupId }: Props): JSX.Element | null => {
	const dispatch = useAppDispatch();

	const groupImages: ImageListItem[] | null = useAppSelector(getGroupImagesData);

	const addGroupImage = (imageId: IdType) => {
		dispatch(addGroupImageAsync({ imageId, groupId }))
			.then((res) => res.payload && Message.success({ messageId: Dictionary.message.success.imageAdded }));
	};

	const deleteGroupImages = (ids: IdType[]) => {
		dispatch(deleteGroupImagesAsync({ groupId, ids }))
			.then((res) => res.payload && Message.success({ messageId: Dictionary.message.success.imageDeleted }));
	};

	useEffect(() => {dispatch(fetchGroupImagesAsync(groupId));}, [dispatch, groupId]);

	if (!groupImages) return null;

	return (
		<div className="group-images">
			<ImageGallery
				complaintType={ComplaintType.GROUP_IMAGES}
				containerName={ImageContainer.GROUP_IMAGES}
				defaultMessage="Photos"
				items={groupImages}
				labelId={Dictionary.common.photos.title}
				onAdd={addGroupImage}
				onDelete={deleteGroupImages}
				showEdit={allowEdit}
			/>
		</div>
	);
};

export default GroupImages;
