import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import ButtonSave from 'components/forms/button-save';
import DatePicker from 'components/forms/date-picker';
import { InputText, TextArea } from 'components/inputs-formik';
import Message from 'components/message';
import { getUserPersonalData, updateUserPersonalAsync } from 'store/user-personal';
import { UserProfilePersonal } from 'types/user-profile';
import { Dictionary } from 'utils/constants/dictionary';
import { PROFILE_ABOUT_MAX_LENGTH } from 'utils/constants/profile';
import { useAppDispatch, useAppSelector } from 'utils/hooks';

type Props = {
	onClickSave: () => void;
};

const ProfilePersonalInfoEdit = ({ onClickSave }: Props): JSX.Element | null => {
	const dispatch = useAppDispatch();

	const userPersonal: UserProfilePersonal | null = useAppSelector(getUserPersonalData);

	const handleSubmit = (values: UserProfilePersonal) => {
		dispatch(updateUserPersonalAsync(values)).then(res => {
			if (res.payload) {
				Message.success({ messageId: Dictionary.message.success.changesSaved });
				onClickSave();
			}
		});
	};

	const InnerForm = () => (
		<Form className="profile-form">
			<div className="profile-form__body">
				<Row gutter={24}>
					<Col lg={{ span: 12 }} xs={{ span: 24 }}>
						<InputText
							defaultLabel="Profession"
							defaultPlaceholder="Input your profession"
							labelId={Dictionary.personalDataPage.personalInformation.profession}
							name="profession"
							placeholderId={Dictionary.personalDataPage.personalInformation.professionPlaceholder}
						/>
					</Col>

					<Col lg={{ span: 12 }} xs={{ span: 24 }}>
						<DatePicker
							defaultLabel="Birth Date"
							defaultPlaceholder="00.00.0000"
							labelId={Dictionary.personalDataPage.personalInformation.birthDate}
							limitDates={{ to: dayjs() }}
							name="birthDate"
							placeholderId={Dictionary.personalDataPage.personalInformation.birthDatePlaceholder}
						/>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col lg={{ span: 24 }} xs={{ span: 24 }}>
						<TextArea
							defaultLabel="About"
							defaultPlaceholder="Input information about yourself"
							labelId={Dictionary.personalDataPage.personalInformation.about}
							maxLength={PROFILE_ABOUT_MAX_LENGTH}
							name="about"
							placeholderId={Dictionary.personalDataPage.personalInformation.aboutPlaceholder}
						/>
					</Col>
				</Row>
			</div>

			<ButtonSave />
		</Form>
	);

	if (!userPersonal) return null;

	return <Formik component={InnerForm} initialValues={userPersonal} onSubmit={handleSubmit} />;
};

export default ProfilePersonalInfoEdit;
