import { PropsWithChildren } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'antd/dist/antd.min.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import AuthRoutes from 'routing/AuthRoutes';
import MainRoutes from 'routing/MainRoutes';
import Http from 'services/http';
import { persistor, store } from 'store';
import { getLoggedIn } from 'store/auth';
import { useAppSelector } from 'utils/hooks';
import { requestFulfilled, requestRejected, responseFulfilled, responseRejected } from 'utils/interceptors';
import ROUTES from 'utils/constants/routes';
import 'utils/helpers/fixConsoleWarning';
import CoursesPage from 'views/courses-page';
import RegistrationPage from 'views/registration-page';
import LocalizationProvider from './LocalizationWrapper';
import './App.scss';

dayjs.extend(utc);

type Props = {
	redirectTo?: string;
};

Http.setInterceptorRequest(requestFulfilled, requestRejected);
Http.setInterceptorResponse(responseFulfilled, responseRejected);

const RequireAuth = ({ children, redirectTo = `/${ROUTES.AUTH.BASE}` }: PropsWithChildren<Props>): JSX.Element => {
	const { pathname, search } = useLocation();

	const loggedIn: boolean = useAppSelector(getLoggedIn);

	return loggedIn ? children as JSX.Element : <Navigate state={{ from: pathname, search }} to={redirectTo} />;
};

const ProtectedRoutes = (): JSX.Element => (
	<RequireAuth>
		<MainRoutes />
	</RequireAuth>
);

const App = (): JSX.Element => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<LocalizationProvider>
				<div className="App">
					<Routes>
						<Route element={<RegistrationPage />} path={`/${ROUTES.REGISTRATION.BASE}`} />
						<Route element={<CoursesPage />} path={`/${ROUTES.COURSES.BASE}`} />
						<Route element={<AuthRoutes />} path={`/${ROUTES.AUTH.BASE}/*`} />
						<Route element={<ProtectedRoutes />} path="/*" />
					</Routes>
				</div>
			</LocalizationProvider>
		</PersistGate>
	</Provider>
);

export default App;
