import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Radio } from 'antd';
import GroupRankingTable from 'components/tables/group-ranking-table';
import SwitchPrevNext from 'components/switch-prev-next';
import UserRankingTable from 'components/tables/user-ranking-table';
import { fetchGlossaryAsync } from 'store/glossary';
import { fetchGroupRankingTopAsync, getGroupRankingCurrentWeek, getGroupRankingLastWeek } from 'store/group-ranking';
import { fetchUserRankingTopAsync, getUserRankingCurrentWeek, getUserRankingLastWeek } from 'store/user-ranking';
import { GlossaryEnum } from 'types/glossary';
import { UserRankingItem, RankingWeekQuery, GroupRankingItem, RankingMode } from 'types/rating';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './HomeRanking.scss';

enum RankingWeek {
	CURRENT,
	LAST,
}

const HomeRanking = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const [mode, setMode] = useState<RankingMode>(RankingMode.INDIVIDUAL);
	const [week, setWeek] = useState<RankingWeek>(RankingWeek.CURRENT);

	const groupsCurrentWeek: GroupRankingItem[] | null = useAppSelector(getGroupRankingCurrentWeek);
	const groupsLastWeek: GroupRankingItem[] | null = useAppSelector(getGroupRankingLastWeek);
	const usersCurrentWeek: UserRankingItem[] | null = useAppSelector(getUserRankingCurrentWeek);
	const usersLastWeek: UserRankingItem[] | null = useAppSelector(getUserRankingLastWeek);

	const toggleMode = (): void => setMode(mode === RankingMode.INDIVIDUAL ? RankingMode.TEAM : RankingMode.INDIVIDUAL);

	const getUsersRankingDataSource = (): UserRankingItem[] => week === RankingWeek.CURRENT
		? usersCurrentWeek || []
		: usersLastWeek || [];

	const getGroupRankingDataSource = (): GroupRankingItem[] => week === RankingWeek.CURRENT
		? groupsCurrentWeek || []
		: groupsLastWeek || [];

	useEffect(() => {
		dispatch(fetchGlossaryAsync(GlossaryEnum.COUNTRIES));
	}, [dispatch]);

	useEffect(() => {
		if (mode === RankingMode.INDIVIDUAL) {
			if (week === RankingWeek.CURRENT) {
				dispatch(fetchUserRankingTopAsync(RankingWeekQuery.CURRENT));
			}

			if (week === RankingWeek.LAST) {
				dispatch(fetchUserRankingTopAsync(RankingWeekQuery.LAST));
			}
		}

		if (mode === RankingMode.TEAM) {
			if (week === RankingWeek.CURRENT) {
				dispatch(fetchGroupRankingTopAsync(RankingWeekQuery.CURRENT));
			}

			if (week === RankingWeek.LAST) {
				dispatch(fetchGroupRankingTopAsync(RankingWeekQuery.LAST));
			}
		}
	}, [dispatch, mode, week]);

	return (
		<div className="card-body ranking">
			<div className="ranking__top">
				<Radio.Group
					buttonStyle="solid"
					className="ranking__week-mode"
					defaultValue={mode}
					onChange={e => setWeek(e.target.value as RankingWeek)}
				>
					<Radio.Button value={RankingWeek.CURRENT}>
						<FormattedMessage defaultMessage="Current" id={Dictionary.homePage.weeklyRanking.actualWeek} />
					</Radio.Button>
					<Radio.Button value={RankingWeek.LAST}>
						<FormattedMessage defaultMessage="Last" id={Dictionary.homePage.weeklyRanking.lastWeek} />
					</Radio.Button>
				</Radio.Group>

				<SwitchPrevNext
					className="ranking__user-mode"
					nextDisabled={mode === RankingMode.TEAM}
					onClickNext={toggleMode}
					onClickPrev={toggleMode}
					prevDisabled={mode === RankingMode.INDIVIDUAL}
					text={mode === RankingMode.INDIVIDUAL
						? (
							<FormattedMessage
								defaultMessage="Individual"
								id={Dictionary.common.rankingTable.individual}
							/>
						) : (
							<FormattedMessage defaultMessage="Group" id={Dictionary.common.rankingTable.group} />
						)}
				/>
			</div>

			{mode === RankingMode.INDIVIDUAL && <UserRankingTable dataSource={getUsersRankingDataSource()} />}

			{mode === RankingMode.TEAM && <GroupRankingTable dataSource={getGroupRankingDataSource()} />}
		</div>
	);
};

export default HomeRanking;
