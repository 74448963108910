import { Routes, Route } from 'react-router-dom';
import NotFound from 'views/not-found';
import Ranking from 'views/ranking';

const RankingRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Ranking />} index />
		<Route element={<NotFound />} path="*" />
	</Routes>
);

export default RankingRoutes;
