import { useState, useEffect } from 'react';
import { getAccessToken } from 'store/auth';
import { IdType, ImageContainer } from 'types/common';
import { BASE_API_URL } from 'utils/constants/base';
import { DOWNLOAD } from 'utils/constants/end-points';
import { useAppSelector } from 'utils/hooks';

export const useProtectedImage = (
	imageId: IdType | null, containerName: ImageContainer, defaultImg: string,
): string => {
	const token: string | null = useAppSelector(getAccessToken);

	const [src, setSrc] = useState<string>('');

	useEffect(() => {
		let mounted = true;

		if (imageId && token) {
			const path = `${BASE_API_URL || ''}/${DOWNLOAD}/image?imageId=${imageId}&containerName=${containerName}`;

			const options = {
				method: 'GET',
				headers: { 'Authorization': `Bearer ${token}` },
			};

			fetch(path, options )
				.then(res => res.blob())
				.then(blob => mounted && setSrc(URL.createObjectURL(blob)));
		}
		return () => {
			mounted = false;
		};
	}, [containerName, imageId, token]);

	return imageId ? src : defaultImg;
};
