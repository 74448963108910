import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Carousel as AntCarousel } from 'antd';
import { arrowRightWhite, arrowLeftWhite } from 'assets/images';
import VideoListItem from 'components/video/video-list-item';
import { SLIDES_TO_SHOW_DESKTOP, SLIDES_TO_SHOW_MOBILE } from 'utils/constants/carousel';
import { MAX_WIDTH_MOBILE } from 'utils/constants/slicing';
import './Carousel.scss';

type CustomArrowProps = {
	className?: string;
	style?: React.CSSProperties;
	currentSlide?: number;
	slideCount?: number;
	onClick?: React.MouseEventHandler;
};

const SampleArrow: FC<CustomArrowProps> = (props): JSX.Element =>	{
	const { currentSlide, className, slideCount, ...arrowProps } = props;
	const isLeft = className?.includes('slick-prev');

	return (
		<div
			className={className}
			{...arrowProps}
		>
			{isLeft
				? <img alt="" src={arrowLeftWhite} />
				: <img alt="" src={arrowRightWhite} />
			}
		</div>
	);
};

type CarouselItemData = { id: number; title: string; previewImageId: number };

type Props<T> = {
	items: T[];
	color: string;
	linkTo: string;
	title?: JSX.Element | string;
};

const Carousel = function<T extends CarouselItemData>({ items, linkTo, title }: Props<T>): JSX.Element {
	return (
		<div className="carousel">
			{title && <h2>
				{title}
			</h2>}
			<AntCarousel
				arrows
				dots={false}
				infinite={false}
				nextArrow={<SampleArrow />}
				prevArrow={<SampleArrow />}
				responsive={[
					{
						breakpoint: MAX_WIDTH_MOBILE,
						settings: {
							slidesToShow: SLIDES_TO_SHOW_MOBILE,
							slidesToScroll: SLIDES_TO_SHOW_MOBILE,
						},
					},
				]}
				slidesToScroll={SLIDES_TO_SHOW_DESKTOP}
				slidesToShow={SLIDES_TO_SHOW_DESKTOP}
			>
				{items.map(item => (
					<div className="carousel__item" key={item.id}>
						<Link to={`/${linkTo}/${String(item.id)}`}>
							<VideoListItem
								previewImageId={item.previewImageId}
								title={item.title}
							/>
						</Link>
					</div>
				))}
			</AntCarousel>
		</div>
	);
};

export default Carousel;

