/* eslint-disable max-len */
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form, FormItem, Select } from 'formik-antd';
import { arrowDownPurple } from 'assets/images';
import ButtonSave from 'components/forms/button-save';
import { InputText } from 'components/inputs-formik';
import SelectGlossary from 'components/inputs-formik/SelectGlossary';
import Message from 'components/message';
import { getGlossaryData } from 'store/glossary';
import { Bank, Country, GlossaryEnum } from 'types/glossary';
import { BankAccountData, BankAccountResponse, PixData, PixDataResponse, PixKey } from 'types/payment-data';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector, useWindow } from 'utils/hooks';
import { BRAZIL_ID } from 'utils/constants/countries';
import { usePaymentDataValidationSchema } from 'utils/validation/schemas';
import GetPixKeyText from 'utils/helpers/get-pix-key-text';
import {
	createUserBankAccountAsync,
	getUserBankAccountData,
	removeUserBankAccountAsync,
	updateUserBankAccountAsync,
} from 'store/user-bank-account';
import { createUserPixAsync, getUserPixData, removeUserPixAsync, updateUserPixAsync } from 'store/user-pix';
import Button from 'components/forms/button';
import './PaymentDataEdit.scss';

type Props = {
	onClickSave: () => void;
};

type FormValues = {
	bankAccountData: BankAccountData | null;
	pixData: PixData | null;
};

const bankAccountInitial: BankAccountData = {} as BankAccountData;
const pixDataInitial: PixData = {
	accountNumber: null,
	agency: null,
	bankId: null,
	city: null,
	cnpj: null,
	cpf: null,
	email: null,
	phone: null,
	stateId: null,
} as PixData;

const PaymentDataEdit = ({ onClickSave }: Props): JSX.Element | null => {
	const dispatch = useAppDispatch();
	const validationSchema = usePaymentDataValidationSchema();

	const userBankAccount: BankAccountResponse | null = useAppSelector(getUserBankAccountData);
	const userPixData: PixDataResponse | null = useAppSelector(getUserPixData);
	const banks: Bank[] | null = useAppSelector(getGlossaryData<Bank[] | null>(GlossaryEnum.BANKS));
	const countries: Country[] | null = useAppSelector(getGlossaryData<Country[] | null>(GlossaryEnum.COUNTRIES));
	const Brazil: Country | undefined = countries?.find(item => item.id === BRAZIL_ID);

	if (!countries || !Brazil) return null;

	const handleSubmit = (values: FormValues) => {
		const { bankAccountData, pixData } = values;

		//Create bank account
		if (!userBankAccount && bankAccountData) {
			dispatch(createUserBankAccountAsync(bankAccountData)).then(res => {
				if (res.payload) {
					Message.success({ messageId: Dictionary.message.success.changesSaved });
				}
			});
		}

		//Update bank account
		if (userBankAccount && bankAccountData) {
			dispatch(updateUserBankAccountAsync(bankAccountData)).then(res => {
				if (res.payload) {
					Message.success({ messageId: Dictionary.message.success.changesSaved });
				}
			});
		}

		//Remove bank account
		if (userBankAccount && !bankAccountData) {
			dispatch(removeUserBankAccountAsync()).then(res => {
				if (res.payload) {
					Message.success({ messageId: Dictionary.message.success.changesSaved });
				}
			});
		}

		//Create PIX
		if (!userPixData && pixData) {
			dispatch(createUserPixAsync(pixData)).then(res => {
				if (res.payload) {
					Message.success({ messageId: Dictionary.message.success.changesSaved });
				}
			});
		}

		//Update PIX
		if (userPixData && pixData) {
			dispatch(updateUserPixAsync(pixData)).then(res => {
				if (res.payload) {
					Message.success({ messageId: Dictionary.message.success.changesSaved });
				}
			});
		}

		//Remove PIX
		if (userPixData && !pixData) {
			dispatch(removeUserPixAsync()).then(res => {
				if (res.payload) {
					Message.success({ messageId: Dictionary.message.success.changesSaved });
				}
			});
		}

		onClickSave();
	};

	const InnerForm = ({ dirty, isValid, setFieldValue, values }: FormikProps<FormValues>): JSX.Element => {
		const intl = useIntl();

		const [bankAccountFields, showBankAccountFields, hideBankAccountFields] = useWindow(!!values.bankAccountData);
		const [pixFields, showPixFields, hidePixFields] = useWindow(!!values.pixData);

		const handleAddBankAccount = (): void => {
			setFieldValue('bankAccountData', bankAccountInitial);
			showBankAccountFields();
		};

		const handleAddPix = (): void => {
			setFieldValue('pixData', pixDataInitial);
			showPixFields();
		};

		const handleRemoveBankAccount = (): void => {
			setFieldValue('bankAccountData', null);
			hideBankAccountFields();
		};

		const handleRemovePix = (): void => {
			setFieldValue('pixData', null);
			hidePixFields();
		};

		return (
			<Form className="profile-form">
				<div className="profile-form__body">
					{!bankAccountFields && (
						<div className="control-btn-container">
							<Button
								defaultText="Add Bank Account"
								onClick={handleAddBankAccount}
								textId={Dictionary.personalDataPage.paymentData.addBankAccount}
							/>
						</div>
					)}

					{bankAccountFields && (
						<div className="control-btn-container">
							<Button
								defaultText="Remove Bank Account"
								disabled={!values.bankAccountData}
								onClick={handleRemoveBankAccount}
								textId={Dictionary.personalDataPage.paymentData.removeBankAccount}
							/>
						</div>
					)}

					{bankAccountFields && (
						<>
							<Row gutter={24}>
								<Col lg={{ span: 12 }} xs={{ span: 24 }}>
									<InputText
										defaultLabel="CPF"
										defaultPlaceholder="000.000.000-00"
										labelId={Dictionary.personalDataPage.paymentData.cpf}
										name="bankAccountData.cpf"
										placeholderId={Dictionary.personalDataPage.paymentData.cpfPlaceholder}
									/>
								</Col>
								<Col lg={{ span: 12 }} xs={{ span: 24 }}>
									<SelectGlossary
										defaultLabel="Bank"
										defaultPlaceholder="Select bank"
										keyName="name"
										labelId={Dictionary.personalDataPage.paymentData.bank}
										name="bankAccountData.bankId"
										placeholderId={Dictionary.personalDataPage.paymentData.bankPlaceholder}
										required
										selectOptions={banks}
									/>
								</Col>
							</Row>

							<Row gutter={24}>
								<Col lg={{ span: 12 }} xs={{ span: 24 }}>
									<InputText
										defaultLabel="Agency"
										defaultPlaceholder="Agency"
										labelId={Dictionary.personalDataPage.paymentData.agency}
										max={4}
										name="bankAccountData.agency"
										placeholderId={Dictionary.personalDataPage.paymentData.agencyPlaceholder}
										required
									/>
								</Col>

								<Col lg={{ span: 12 }} xs={{ span: 24 }}>
									<InputText
										defaultLabel="Current or savings account"
										defaultPlaceholder="00000-0"
										labelId={Dictionary.personalDataPage.paymentData.account}
										name="bankAccountData.accountNumber"
										placeholderId={Dictionary.personalDataPage.paymentData.accountPlaceholder}
										required
									/>
								</Col>
							</Row>
						</>
					)}

					{!pixFields && (
						<div className="control-btn-container">
							<Button
								defaultText="Add PIX Data"
								onClick={handleAddPix}
								textId={Dictionary.personalDataPage.paymentData.addPixData}
							/>
						</div>
					)}

					{pixFields && (
						<div className="control-btn-container">
							<Button
								defaultText="Remove PIX Data"
								disabled={!values.pixData}
								onClick={handleRemovePix}
								textId={Dictionary.personalDataPage.paymentData.removePixData}
							/>
						</div>
					)}

					{pixFields && (
						<>
							<Row gutter={24}>
								<Col lg={{ span: 12 }} xs={{ span: 24 }}>
									<FormItem
										colon={false}
										label={(
											<FormattedMessage
												defaultMessage="PIX Key"
												id={Dictionary.personalDataPage.paymentData.pixKey}
											/>
										)}
										name="pixData.key"
									>
										<Select
											allowClear
											name="pixData.key"
											placeholder={intl.formatMessage({
												defaultMessage: 'Select PIX key',
												id: Dictionary.personalDataPage.paymentData.pixKeyPlaceholder,
											})}
											suffixIcon={<img alt="" src={arrowDownPurple} />}
										>
											{Object.values(PixKey).map(value => (
												<Select.Option key={value} value={value}>
													{GetPixKeyText(value)}
												</Select.Option>
											))}
										</Select>
									</FormItem>
								</Col>

								<Col lg={{ span: 12 }} xs={{ span: 24 }}>
									{values.pixData?.key === PixKey.BANK_ACCOUNT && (
										<SelectGlossary
											defaultLabel="PIX Bank"
											defaultPlaceholder="Select bank"
											keyName="name"
											labelId={Dictionary.personalDataPage.paymentData.pixBank}
											name="pixData.bankId"
											placeholderId={Dictionary.personalDataPage.paymentData.pixBankPlaceholder}
											selectOptions={banks}
										/>
									)}
									{values.pixData?.key === PixKey.CNPJ && (
										<InputText
											defaultLabel="PIX CNPJ"
											defaultPlaceholder="00.000.000/0001-00"
											labelId={Dictionary.personalDataPage.paymentData.pixCnpj}
											name="pixData.cnpj"
											placeholderId={Dictionary.personalDataPage.paymentData.pixCnpjPlaceholder}
										/>
									)}
									{values.pixData?.key === PixKey.CPF && (
										<InputText
											defaultLabel="PIX CPF"
											defaultPlaceholder="00.000.000/0001-00"
											labelId={Dictionary.personalDataPage.paymentData.pixCpf}
											name="pixData.cpf"
											placeholderId={Dictionary.personalDataPage.paymentData.pixCpfPlaceholder}
										/>
									)}
									{values.pixData?.key === PixKey.EMAIL && (
										<InputText
											defaultLabel="PIX Email"
											defaultPlaceholder="Email"
											labelId={Dictionary.personalDataPage.paymentData.pixEmail}
											name="pixData.email"
											placeholderId={Dictionary.personalDataPage.paymentData.pixEmailPlaceholder}
										/>
									)}
									{values.pixData?.key === PixKey.PHONE && (
										<InputText
											defaultLabel="PIX Phone number"
											defaultPlaceholder="Phone number"
											labelId={Dictionary.personalDataPage.paymentData.pixPhone}
											name="pixData.phone"
											placeholderId={Dictionary.personalDataPage.paymentData.pixPhonePlaceholder}
										/>
									)}
								</Col>
							</Row>

							{values.pixData?.key === PixKey.BANK_ACCOUNT && (
								<>
									<Row gutter={24}>
										<Col lg={{ span: 12 }} xs={{ span: 24 }}>
											<InputText
												defaultLabel="PIX Agency"
												defaultPlaceholder="Agency"
												labelId={Dictionary.personalDataPage.paymentData.pixAgency}
												name="pixData.agency"
												placeholderId={Dictionary.personalDataPage.paymentData.pixAgencyPlaceholder}
											/>
										</Col>

										<Col lg={{ span: 12 }} xs={{ span: 24 }}>
											<InputText
												defaultLabel="PIX Account number"
												defaultPlaceholder="00000-0"
												labelId={Dictionary.personalDataPage.paymentData.pixAccount}
												name="pixData.accountNumber"
												placeholderId={Dictionary.personalDataPage.paymentData.pixAccountPlaceholder}
											/>
										</Col>
									</Row>

									<Row gutter={24}>
										<Col lg={{ span: 12 }} xs={{ span: 24 }}>
											<SelectGlossary
												defaultLabel="PIX State"
												defaultPlaceholder="Select state"
												keyName="name"
												labelId={Dictionary.personalDataPage.paymentData.pixState}
												name="pixData.stateId"
												placeholderId={Dictionary.personalDataPage.paymentData.pixStatePlaceholder}
												renderItem={state => state.code}
												selectOptions={Brazil.states}
											/>
										</Col>

										<Col lg={{ span: 12 }} xs={{ span: 24 }}>
											<InputText
												defaultLabel="PIX City"
												defaultPlaceholder="City"
												labelId={Dictionary.personalDataPage.paymentData.pixCity}
												name="pixData.city"
												placeholderId={Dictionary.personalDataPage.paymentData.pixCityPlaceholder}
											/>
										</Col>
									</Row>
								</>
							)}
						</>
					)}
				</div>

				<ButtonSave disabled={!dirty || !isValid || bankAccountFields && !values.bankAccountData?.bankId} />
			</Form>
		);
	};

	return (
		<div className="payment-data-edit">
			<Formik<FormValues>
				component={InnerForm}
				initialValues={{
					bankAccountData: userBankAccount,
					pixData: userPixData,
				}}
				onSubmit={formValues => {
					const values = { ...formValues };

					if (values.bankAccountData && !values.bankAccountData.cpf) {
						values.bankAccountData = { ...values.bankAccountData, cpf: null };
					}

					if (values.pixData) {
						if (values.pixData.key !== PixKey.BANK_ACCOUNT) {
							values.pixData = {
								...values.pixData,
								accountNumber: null,
								agency: null,
								bankId: null,
								city: null,
								stateId: null,
							};
						}
						if (values.pixData.key !== PixKey.CNPJ && values.pixData.cnpj) {
							values.pixData.cnpj = null;
						}
						if (values.pixData.key !== PixKey.CPF && values.pixData.cpf) {
							values.pixData.cpf = null;
						}
						if (values.pixData.key !== PixKey.EMAIL && values.pixData.email) {
							values.pixData.email = null;
						}
						if (values.pixData.key !== PixKey.PHONE && values.pixData.phone) {
							values.pixData.phone = null;
						}
					}

					handleSubmit(values);
				}}
				validationSchema={validationSchema}
			/>
		</div>
	);
};

export default PaymentDataEdit;
