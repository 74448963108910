import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Dictionary } from 'utils/constants/dictionary';

const NotFound = (): JSX.Element => (
	<div>
		<h2>
			<FormattedMessage defaultMessage="Not Found" id={Dictionary.notFoundPage.title} />
		</h2>
		<p>
			<Link to="/">
				<FormattedMessage defaultMessage="Go to the home page" id={Dictionary.notFoundPage.text} />
			</Link>
		</p>
	</div>
);

export default NotFound;
