/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Col, Row } from 'antd';
import FormItemView from 'components/form-item-view';
import ButtonEdit from 'components/forms/button-edit';
import { ReactNodeArray } from 'react';
import { FormattedMessage } from 'react-intl';
import { getGlossaryData } from 'store/glossary';
import { getUserBankAccountData } from 'store/user-bank-account';
import { getUserPixData } from 'store/user-pix';
import { Bank, Country, GlossaryEnum } from 'types/glossary';
import { BankAccountResponse, PixDataResponse, PixKey } from 'types/payment-data';
import { BRAZIL_ID } from 'utils/constants/countries';
import { Dictionary } from 'utils/constants/dictionary';
import GetPixKeyText from 'utils/helpers/get-pix-key-text';
import { useAppSelector } from 'utils/hooks';
import './PaymentData.scss';

type Props = {
	onClickEdit: () => void;
};

const PaymentData = ({ onClickEdit }: Props): JSX.Element | null => {
	const userBankAccount: BankAccountResponse | null = useAppSelector(getUserBankAccountData);
	const userPixData: PixDataResponse | null = useAppSelector(getUserPixData);
	const banks: Bank[] | null = useAppSelector(getGlossaryData<Bank[] | null>(GlossaryEnum.BANKS));
	const countries: Country[] | null = useAppSelector(getGlossaryData<Country[] | null>(GlossaryEnum.COUNTRIES));
	const Brazil: Country | undefined = countries?.find(item => item.id === BRAZIL_ID);

	if (!banks || !countries || !Brazil) return null;

	return (
		<div className="profile-form payment-data">
			<div className="profile-form__body">
				{!userBankAccount && !userPixData && (
					<div className="empty-data-container">
						<FormattedMessage
							defaultMessage="No data yet"
							id={Dictionary.personalDataPage.paymentData.noDataText}
						>
							{(chunks: ReactNodeArray) => (
								<h2>
									<strong>
										{chunks}
									</strong>
								</h2>
							)}
						</FormattedMessage>
					</div>
				)}

				{userBankAccount && (
					<>
						<Row gutter={24}>
							<Col lg={{ span: 12 }} xs={{ span: 24 }}>
								<FormItemView
									defaultLabel="CPF"
									labelId={Dictionary.personalDataPage.paymentData.cpf}
								>
									{userBankAccount.cpf}
								</FormItemView>
							</Col>

							<Col lg={{ span: 12 }} xs={{ span: 24 }}>
								<FormItemView
									defaultLabel="Bank"
									labelId={Dictionary.personalDataPage.paymentData.bank}
								>
									{banks.find(item => item.id === userBankAccount.bankId)?.name}
								</FormItemView>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col lg={{ span: 12 }} xs={{ span: 24 }}>
								<FormItemView
									defaultLabel="Agency"
									labelId={Dictionary.personalDataPage.paymentData.agency}
								>
									{userBankAccount.agency}
								</FormItemView>
							</Col>

							<Col lg={{ span: 12 }} xs={{ span: 24 }}>
								<FormItemView
									defaultLabel="Current or savings account"
									labelId={Dictionary.personalDataPage.paymentData.account}
								>
									{userBankAccount.accountNumber}
								</FormItemView>
							</Col>
						</Row>
					</>
				)
				}

				{ userPixData && (
					<>
						<Row gutter={24}>
							<Col lg={{ span: 12 }} xs={{ span: 24 }}>
								<FormItemView
									defaultLabel="PIX key"
									labelId={Dictionary.personalDataPage.paymentData.pixKey}
								>
									{GetPixKeyText(userPixData.key)}
								</FormItemView>
							</Col>

							<Col lg={{ span: 12 }} xs={{ span: 24 }}>
								{userPixData?.key === PixKey.BANK_ACCOUNT && (
									<FormItemView
										defaultLabel="PIX Bank"
										labelId={Dictionary.personalDataPage.paymentData.pixBank}
									>
										{banks.find(item => item.id === userPixData.bankId)?.name}
									</FormItemView>
								)}
								{userPixData.key === PixKey.CNPJ && (
									<FormItemView
										defaultLabel="PIX CNPJ"
										labelId={Dictionary.personalDataPage.paymentData.pixCnpj}
									>
										{userPixData.cnpj}
									</FormItemView>
								)}
								{userPixData.key === PixKey.CPF && (
									<FormItemView
										defaultLabel="PIX CPF"
										labelId={Dictionary.personalDataPage.paymentData.pixCpf}
									>
										{userPixData.cpf}
									</FormItemView>
								)}
								{userPixData.key === PixKey.EMAIL && (
									<FormItemView
										defaultLabel="PIX Email"
										labelId={Dictionary.personalDataPage.paymentData.pixEmail}
									>
										{userPixData.email}
									</FormItemView>
								)}
								{userPixData.key === PixKey.PHONE && (
									<FormItemView
										defaultLabel="PIX Phone"
										labelId={Dictionary.personalDataPage.paymentData.pixPhone}
									>
										{userPixData.phone}
									</FormItemView>
								)}
							</Col>
						</Row>

						{userPixData.key === PixKey.BANK_ACCOUNT && (
							<>
								<Row gutter={24}>
									<Col lg={{ span: 12 }} xs={{ span: 24 }}>
										<FormItemView
											defaultLabel="PIX Agency"
											labelId={Dictionary.personalDataPage.paymentData.pixAgency}
										>
											{userPixData.agency}
										</FormItemView>
									</Col>

									<Col lg={{ span: 12 }} xs={{ span: 24 }}>
										<FormItemView
											defaultLabel="PIX Account number"
											labelId={Dictionary.personalDataPage.paymentData.account}
										>
											{userPixData.accountNumber}
										</FormItemView>
									</Col>
								</Row>

								<Row gutter={24}>
									<Col lg={{ span: 12 }} xs={{ span: 24 }}>
										<FormItemView
											defaultLabel="PIX State"
											labelId={Dictionary.personalDataPage.paymentData.pixState}
										>
											{Brazil.states.find(item => item.id === userPixData.stateId)?.code}
										</FormItemView>
									</Col>

									<Col lg={{ span: 12 }} xs={{ span: 24 }}>
										<FormItemView
											defaultLabel="PIX City"
											labelId={Dictionary.personalDataPage.paymentData.pixCity}
										>
											{userPixData.city}
										</FormItemView>
									</Col>
								</Row>
							</>
						)}
					</>
				)}
			</div>

			<ButtonEdit onClick={onClickEdit} />
		</div>
	);
};

export default PaymentData;
