import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { link as linkIco } from 'assets/images';
import Button from 'components/forms/button';
import Message from 'components/message';
import { Dictionary } from 'utils/constants/dictionary';
import './ShareLink.scss';

type Props = {
	imgSrc?: string;
	btnTextDefault?: string;
	btnTextId?: string;
	link: string;
	showHelper?: boolean;
};

const ShareLink = ({
	btnTextDefault = 'Copy', btnTextId, imgSrc = linkIco, link, showHelper = true,
}: Props): JSX.Element => {
	const [copied, setCopied] = useState<boolean>(false);

	const handleCopy = (): void => {
		setCopied(true);
		Message.success({ messageId: Dictionary.message.success.linkCopied });

		setTimeout(() => setCopied(false), 3000);
	};

	return (
		<div className="share-link__wrapper">
			<div className="share-link__container">
				<div className="share-link__box">
					<CopyToClipboard onCopy={handleCopy} text={link}>
						<span className="share-link__text">
							{link}
						</span>
					</CopyToClipboard>
				</div>

				{showHelper && <div className="share-link__helper">
					{copied
						? <FormattedMessage defaultMessage="Copied to Clipboard" id={Dictionary.shareLink.copied} />
						: <FormattedMessage defaultMessage="Click to copy" id={Dictionary.shareLink.actionText} />
					}
				</div>}
			</div>

			<CopyToClipboard onCopy={handleCopy} text={link}>
				<Button
					className="btn-rectangle share-link__btn"
					defaultText={btnTextDefault}
					imgSrc={imgSrc}
					textId={btnTextId}
				/>
			</CopyToClipboard>
		</div>
	);
};

export default ShareLink;
