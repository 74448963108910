/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { InputNumber } from 'antd';
import { searchIcoWhite, checkWhite } from 'assets/images';
import Button from 'components/forms/button';
import { InputText } from 'components/inputs';
import PageTitle from 'components/page-title';
import SwitchPrevNext from 'components/switch-prev-next';
import GroupRankingTable from 'components/tables/group-ranking-table';
import UserRankingTable from 'components/tables/user-ranking-table';
import { fetchGlossaryAsync } from 'store/glossary';
import { fetchGroupRankingAllAsync, getGroupRankingAll } from 'store/group-ranking';
import { fetchUserRankingAllAsync, getUserRankingAll } from 'store/user-ranking';
import { AppTheme } from 'types/common';
import { GlossaryEnum } from 'types/glossary';
import { GroupRankingItem, RankingFilter, RankingMode, UserRankingItem } from 'types/rating';
import { Dictionary } from 'utils/constants/dictionary';
import { RatingStarsMode } from 'utils/constants/rating';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './Ranking.scss';

const initialSearchParams: RankingFilter = {};

const Ranking = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const [params, setParams] = useState<RankingFilter>(initialSearchParams);

	const { salesFrom, salesTo, search } = params;

	const userRankingAll: UserRankingItem[] | null = useAppSelector(getUserRankingAll);
	const groupRankingAll: GroupRankingItem[] | null = useAppSelector(getGroupRankingAll);

	const [mode, setMode] = useState<RankingMode>(RankingMode.INDIVIDUAL);

	const fetchData = useCallback(() => {
		if (mode === RankingMode.INDIVIDUAL) {
			dispatch(fetchUserRankingAllAsync(params));
		}

		if (mode === RankingMode.TEAM) {
			dispatch(fetchGroupRankingAllAsync(params));
		}
	}, [dispatch, mode, params]);

	useEffect(() => {
		dispatch(fetchGlossaryAsync(GlossaryEnum.COUNTRIES));
	}, [dispatch]);

	useEffect(() => {
		fetchData();
	}, [mode]);

	const toggleMode = (): void => {
		setParams(initialSearchParams);
		setMode(
			(prevMode) => prevMode === RankingMode.INDIVIDUAL ? RankingMode.TEAM : RankingMode.INDIVIDUAL,
		);
	};
	const updateSearchParam = (key: keyof RankingFilter, value: string | undefined): void => {
		setParams((prevParams) => ({ ...prevParams, [key]: value }));
	};

	const handleChangeSearchInput = (e: ChangeEvent<HTMLInputElement>): void => {
		updateSearchParam('search', e.target.value || undefined);
	};

	const handleChangeSalesInput = (key: keyof RankingFilter, val: string | number | null): void => {
		updateSearchParam(key, typeof val === 'number' ? val.toString() : undefined);
	};

	return (
		<div className="ranking">
			<div className="ranking__top">
				<PageTitle defaultText="Ranking" textId={Dictionary.rankingPage.title} />

				<div className="ranking__top-right">
					<div className="ranking__top-search">
						<InputText
							defaultPlaceholder="Search"
							onChange={handleChangeSearchInput}
							onPressEnter={fetchData}
							placeholderId={Dictionary.common.search}
							suffix={<img alt="" onClick={fetchData} src={searchIcoWhite} />}
							value={search ?? ''}
						/>
					</div>

					<div className="ranking__top-filter">
						<FormattedMessage defaultMessage="Sales from" id={Dictionary.rankingPage.salesFrom} />
						<InputNumber<number>
							min={0}
							onChange={val => handleChangeSalesInput('salesFrom', val)}
							value={salesFrom ? +salesFrom : undefined}
						/>
						<FormattedMessage defaultMessage="to" id={Dictionary.rankingPage.salesTo} />
						<InputNumber<number>
							min={salesFrom ? +salesFrom : undefined}
							onChange={val => handleChangeSalesInput('salesTo', val)}
							value={salesTo ? +salesTo : undefined}
						/>
					</div>

					<Button
						className="search-btn"
						defaultText="Search"
						imgSrc={checkWhite}
						onClick={fetchData}
					/>
				</div>
			</div>

			<div className="card-body ranking-card">
				<div className="ranking-card__top">
					<SwitchPrevNext
						className="ranking__user-mode"
						darkMode={true}
						nextDisabled={mode === RankingMode.TEAM}
						onClickNext={toggleMode}
						onClickPrev={toggleMode}
						prevDisabled={mode === RankingMode.INDIVIDUAL}
						text={mode === RankingMode.INDIVIDUAL
							? (
								<FormattedMessage
									defaultMessage="Individual"
									id={Dictionary.common.rankingTable.individual}
								/>
							) : (
								<FormattedMessage defaultMessage="Group" id={Dictionary.common.rankingTable.group} />
							)}
					/>
				</div>

				{mode === RankingMode.INDIVIDUAL && (
					<UserRankingTable
						dataSource={userRankingAll || []}
						ratingStarsMode={RatingStarsMode.WHITE}
						theme={AppTheme.DARK}
					/>
				)}

				{mode === RankingMode.TEAM && <GroupRankingTable dataSource={groupRankingAll || []} />}

			</div>

			<div className="ranking__footer">
				{/* <Pagination
					current={+currentPage}
					onChange={(page)=>setParams((prevParams)=>({ ...prevParams, page: page.toString() }))}
					showSizeChanger={false}
					total={999}
				/> */}
			</div>

		</div>
	);
};

export default Ranking;

