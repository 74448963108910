/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FormattedMessage, useIntl } from 'react-intl';
import { FormItem, Select, SelectProps } from 'formik-antd';
import { useField } from 'formik';
import { nanoid } from 'nanoid';
import { GlossaryData } from 'types/glossary';

type Props<T> = {
	defaultLabel?: string;
	defaultPlaceholder?: string;
	help?: string | number | boolean;
	keyName: keyof T;
	labelId?: string;
	selectOptions?: T[] | null;
	placeholderId?: string;
	required?: boolean;
	renderItem?: (option: T) => string;
	validateStatus?: '' | 'error' | 'success' | 'warning' | 'validating';
};

const SelectGlossary = <T extends GlossaryData>({
	className,
	defaultLabel,
	defaultPlaceholder,
	filterOption,
	help,
	keyName,
	labelId,
	name,
	placeholderId,
	renderItem,
	required,
	selectOptions,
	showSearch,
	validateStatus,
	...restProps
}: SelectProps & Props<T>): JSX.Element => {
	const [, meta] = useField(name);
	const intl = useIntl();

	const validStatus = meta.touched ? (validateStatus || (meta.error ? 'error' : '')) : '';
	const helpMessage = meta.touched && (help || meta.error);

	return (
		<FormItem
			colon={false}
			hasFeedback={false}
			help={helpMessage}
			htmlFor={name}
			label={defaultLabel && labelId && <FormattedMessage defaultMessage={defaultLabel} id={labelId} />}
			name={name}
			required={required}
			validateStatus={validStatus}
		>
			<Select
				{...restProps}
				filterOption={
					filterOption || (showSearch && ((input, option) => (!(option?.props.children
						?.toString()
						.toLowerCase()
						.indexOf(input.toLowerCase()) === -1 || undefined))))
				}
				id={name}
				name={name}
				placeholder={
					placeholderId && defaultPlaceholder &&
					intl.formatMessage({ id: placeholderId, defaultMessage: defaultPlaceholder })
				}
			>
				{selectOptions?.map((option) => (
					<Select.Option key={nanoid()} value={option.id}>
						{renderItem ? renderItem(option) : option[keyName]}
					</Select.Option>
				))}
			</Select>
		</FormItem>
	);
};

export default SelectGlossary;
