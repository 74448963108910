import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/forms/button';
import Message from 'components/message';
import PageTitle from 'components/page-title';
import UserService from 'services/user-service';
import { Dictionary } from 'utils/constants/dictionary';
import SecuritySettingsForm from './security-settings-form';
import './ProfileSecuritySettings.scss';

const ProfileSecuritySettings = (): JSX.Element => {
	const navigator = useNavigate();
	const [securitySettings, setSecuritySettings] = useState<{
		email: string;
		phoneNumber: string;
	}>();

	const handleSubmitPhoneNumber = (phoneNumber: string) => {
		UserService.changePhoneNumber(phoneNumber)
			.then(res => res && Message.success({ messageId: Dictionary.message.success.phoneChanged }));
	};

	const handleClickChangeEmail = (): void => {
		UserService.confirmChangeEmail()
			.then(res => res && Message.success({ messageText: res.message }));
	};

	useEffect(() => {
		const res = UserService.getSecuritySettings();

		setSecuritySettings(res);
	}, []);

	return (
		<div className="security-settings">
			<PageTitle backLink defaultText="Security Settings" textId={Dictionary.securitySettingsPage.title} />

			<div className="security-settings__wrap">
				<SecuritySettingsForm
					changeBtnTextDefault="Change"
					changeBtnTextId={Dictionary.common.change}
					onSubmitPhoneNumber={handleSubmitPhoneNumber}
					phoneNumber={securitySettings?.phoneNumber}
					submitBtnTextDefault="Save"
					submitBtnTextId={Dictionary.common.save}
				/>

				<div className="security-settings__footer">
					<Button
						defaultText="Change Email"
						onClick={handleClickChangeEmail}
						textId={Dictionary.securitySettingsPage.changeEmail}
					/>

					<Button
						defaultText="Change Password"
						onClick={() => navigator('/profile/change-password')}
						textId={Dictionary.securitySettingsPage.changePassword}
					/>
				</div>
			</div>
		</div>
	);
};

export default ProfileSecuritySettings;
