import { Routes, Route } from 'react-router-dom';
import ROUTES from 'utils/constants/routes';
import Medias from 'views/medias';
import Media from 'views/medias/item';
import NotFound from 'views/not-found';

const MediasRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Medias />} index />
		<Route element={<Media />} path={ROUTES.MEDIAS.ITEM} />
		<Route element={<NotFound />} path="*" />
	</Routes>
);

export default MediasRoutes;
