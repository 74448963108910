import { Drawer } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
	crossWhite,
	profileMenuPersonalData,
	//profileMenuLegalDocuments,
	profileMenuSecuritySettings,
	//profileMenuInvitations,
	arrowRightPurple,
} from 'assets/images';
import { Dictionary } from 'utils/constants/dictionary';
import './ProfileMenu.scss';

type Props = {
	onClose: () => void;
};

const ProfileMenu = ({ onClose }: Props): JSX.Element => (
	<Drawer
		className="profile-menu"
		closeIcon={<img alt="" src={crossWhite} />}
		onClose={onClose}
		visible
		width={362}
	>
		<div className="profile-menu__close" onClick={onClose} />
		<nav>
			<div className="profile-menu__item">
				<Link className="profile-menu__item-link" to="/profile/personal-data">
					<div className="profile-menu__item-ico">
						<img alt="" src={profileMenuPersonalData} />
					</div>
					<FormattedMessage
						defaultMessage="Personal Data"
						id={Dictionary.profileMenu.personalData}
					/>
					<img alt="" src={arrowRightPurple} />
				</Link>
			</div>
			{/* <div className="profile-menu__item">
				<Link className="profile-menu__item-link" to="/profile/legal-documents">
					<div className="profile-menu__item-ico">
						<img alt="" src={profileMenuLegalDocuments} />
					</div>
					<FormattedMessage defaultMessage="Legal Documents" id={Dictionary.profileMenu.legalDocuments} />
					<img alt="" src={arrowRightPurple} />
				</Link>
			</div> */}
			<div className="profile-menu__item">
				<Link className="profile-menu__item-link" to="/profile/security-settings">
					<div className="profile-menu__item-ico">
						<img alt="" src={profileMenuSecuritySettings} />
					</div>
					<FormattedMessage
						defaultMessage="Security Settings"
						id={Dictionary.profileMenu.securitySettings}
					/>
					<img alt="" src={arrowRightPurple} />
				</Link>
			</div>
			{/* <div className="profile-menu__item">
				<Link className="profile-menu__item-link" to="/profile/invitation">
					<div className="profile-menu__item-ico">
						<img alt="" src={profileMenuInvitations} />
					</div>
					<FormattedMessage defaultMessage="Invitations" id={Dictionary.profileMenu.invitations} />
					<img alt="" src={arrowRightPurple} />
				</Link>
			</div> */}
		</nav>
	</Drawer>
);

export default ProfileMenu;
