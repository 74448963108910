import PageTitle from 'components/page-title';
import VideoListItem from 'components/video/video-list-item';
import { useEffect } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import { fetchMediasAllAsync, getMediasData } from 'store/medias';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './MediasList.scss';

const MediasList = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const medias = useAppSelector(getMediasData);
	const params = useParams<{ id: string }>();

	useEffect(() => {
		dispatch(fetchMediasAllAsync());
	}, [dispatch]);

	return (
		<div className="medias-list">
			{!params.id && <PageTitle defaultText="Media" textId={Dictionary.mediasPage.title} />}
			<Outlet />
			<div className="medias-list__wrap">
				{medias.map(media => (
					<div className="medias-list__item" key={media.id}>
						<Link to={String(media.id)}>
							<VideoListItem
								previewImageId={media.previewImageId}
								title={media.title}
							/>
						</Link>
					</div>
				))}
			</div>
		</div>
	);
};

export default MediasList;

