import { IdType } from 'types/common';
import { GlossaryData } from 'types/glossary';

const getGlossaryValue = <T extends GlossaryData>(valueId: IdType, glossary: T[], keyName: keyof T): string => {
	const value = glossary.find((item: T) => item.id === valueId)?.[keyName];

	return value ? String(value) : '';
};

export default getGlossaryValue;
