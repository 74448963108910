import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Table, Tooltip } from 'antd';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';
import { arrowDownPurple, arrowUpPurple, importantCircle } from 'assets/images';
import SalesService from 'services/sales-service';
import { getSalesListFilter, setSalesListFilter } from 'store/sales-list';
import { IdType, Sorting } from 'types/common';
import { SalesFilter, SalesListItem, SalesListOrderBy } from 'types/sales';
import { Dictionary } from 'utils/constants/dictionary';
import convertBalance from 'utils/helpers/convert-balance';
import { formatDate } from 'utils/helpers/date-time';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './SalesTable.scss';

type Props = {
	dataSource: SalesListItem[];
};

type Keys = 'defaultSortOrder' | 'onHeaderCell' | 'sortDirections' | 'sortOrder' | 'sorter' | 'showSorterTooltip';

type SorterConfig<T> = Pick<ColumnType<T>, Keys>;

type TableItem = SalesListItem & { history: SalesListItem[] };

const defaultSortParams: SorterConfig<SalesListItem> = {
	sortDirections: ['ascend', 'descend', 'ascend'],
	sorter: true,
	showSorterTooltip: false,
};

const getSortOrder = (sorting: Sorting): SortOrder => sorting === Sorting.ASC ? 'ascend' : 'descend';

const SalesTable = ({ dataSource }: Props): JSX.Element => {
	const dispatch = useAppDispatch();

	const [data, setData] = useState<TableItem[]>([]);
	const [expandedKey, setExpandedKey] = useState<IdType | null>(null);

	useEffect(() => {
		setExpandedKey(null);
		setData(dataSource.map(item => ({ ...item, history: [] })));
	}, [dataSource]);

	const salesFilter: SalesFilter = useAppSelector(getSalesListFilter);
	const { orderBy, sorting } = salesFilter;

	const getConfig = (filterOrderBy: SalesListOrderBy): SorterConfig<TableItem> => ({
		...defaultSortParams,
		sortOrder: orderBy === filterOrderBy ? getSortOrder(sorting) : undefined,
		onHeaderCell: () => ({
			onClick: e => {
				e.preventDefault();
				dispatch(setSalesListFilter({
					...salesFilter,
					orderBy: filterOrderBy,
					sorting: sorting === Sorting.ASC ? Sorting.DESC : Sorting.ASC,
				}));
			},
		}),
	});

	const getSorterConfig = (column: keyof SalesListItem): SorterConfig<TableItem> => {
		switch (column) {
			case 'price': {
				return getConfig(SalesListOrderBy.PRICE);
			}
			case 'purchaseDate': {
				return getConfig(SalesListOrderBy.PURCHASE_DATE);
			}
			case 'warrantyDate': {
				return getConfig(SalesListOrderBy.WARRANTY_DATE);
			}
			default: return {};
		}
	};

	const columns: ColumnsType<TableItem> = [
		{
			title: () => (
				<FormattedMessage defaultMessage="Transaction code" id={Dictionary.salesPage.table.transactionCode} />
			),
			dataIndex: 'hotmartTransactionCode',
			key: 'hotmartTransactionCode',
			fixed: 'left',
			width: 250,
		},
		{
			title: () => <FormattedMessage defaultMessage="Buyer" id={Dictionary.salesPage.table.buyer} />,
			dataIndex: 'clientName',
			key: 'clientName',
			width: 150,
		},
		{
			title: () => (
				<FormattedMessage defaultMessage="Purchase Date" id={Dictionary.salesPage.table.purchaseDate} />
			),
			dataIndex: 'purchaseDate',
			key: 'purchaseDate',
			width: 150,
			render: (_, record) => (
				<div>
					{formatDate(record.purchaseDate)}
				</div>
			),
			...getSorterConfig('purchaseDate'),
		},
		{
			title: () => <FormattedMessage defaultMessage="Price" id={Dictionary.salesPage.table.price} />,
			dataIndex: 'price',
			key: 'price',
			width: 150,
			render: (_, record) => (
				<div>
					{convertBalance(record.price)}
				</div>
			),
			...getSorterConfig('price'),
		},
		{
			title: () => <FormattedMessage defaultMessage="Commission" id={Dictionary.salesPage.table.commission} />,
			dataIndex: 'commission',
			key: 'commission',
			width: 150,
			render: (_, record) => (
				<div>
					{convertBalance(record.commission ?? 0)}
				</div>
			),
		},
		{
			title: () => <FormattedMessage defaultMessage="Product" id={Dictionary.salesPage.table.product} />,
			dataIndex: 'productName',
			key: 'productName',
			width: 150,
		},
		{
			title: () => (
				<FormattedMessage defaultMessage="Purchase status" id={Dictionary.salesPage.table.purchaseStatus} />
			),
			dataIndex: 'status',
			key: 'status',
			width: 150,
		},
		{
			title: () => <FormattedMessage defaultMessage="Payment type" id={Dictionary.salesPage.table.paymentType} />,
			dataIndex: 'paymentType',
			key: 'paymentType',
			width: 150,
		},
		{
			title: () => (
				<div className="sales-table__refund-period-header">
					<Tooltip
						overlayClassName="refund-period__tooltip"
						placement="top"
						title={
							<FormattedMessage
								defaultMessage="Whenever the purchase is of the subscription type, it is submitted for \
								purchase and cancellation notification. The possible values for this field are 7, 30, \
								60, 90, 180, and 360 and these values represent the periodicity in days of the \
								subscription payments."
								id={Dictionary.salesPage.table.refundPeriodMsg}
							/>
						}
					>
						<img alt="important-info-icon" src={importantCircle} />
					</Tooltip>
					<FormattedMessage defaultMessage="Refund period" id={Dictionary.salesPage.table.refundPeriod} />
				</div>
			),
			dataIndex: 'warrantyDate',
			key: 'warrantyDate',
			width: 150,
			render: (_, record) => (
				<div>
					{record.warrantyDate && formatDate(record.warrantyDate)}
				</div>
			),
			...getSorterConfig('warrantyDate'),
		},
	];

	return (
		<Table
			className="sales-table"
			columns={columns}
			dataSource={data}
			expandable={{
				childrenColumnName: 'history',
				expandedRowKeys: expandedKey ? [expandedKey] : undefined,
				expandIcon: ({ expanded, onExpand, record }) => record.history ? (
					<img
						alt={expanded ? 'arrow-up-purple' : 'arrow-down-purple'}
						className="sales-table__expand-icon"
						onClick={e => onExpand(record, e)}
						src={expanded ? arrowUpPurple : arrowDownPurple}
					/>
				) : undefined,
				onExpand: (expanded, record) => {
					if (expanded && !record.history?.length) {
						SalesService.getSalesByTransactionCode(record.hotmartTransactionCode)
							.then(history => {
								setData(
									data.map(item => item.id === record.id ? { ...item, history } : item,
									));
							});
					}

					setExpandedKey(expanded ? record.id : null);
				},
			}}
			onRow={(record) => expandedKey === record.id ? ({ className: 'sales-table__row_expanded' }) : {}}
			pagination={false}
			rowKey="id"
			scroll={{ y: '50vh' }}
		/>
	);
};

export default SalesTable;
