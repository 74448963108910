import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import MediasService from 'services/medias-service';
import { FetchStatus, IdType, ResultState } from 'types/common';
import { MediasList } from 'types/medias';
import { RootState } from './index';

const initialState: ResultState<MediasList> = {
	data: [],
	status: FetchStatus.IDLE,
	error: null,
};

export const fetchMediasAllAsync = createAsyncThunk(
	'medias/fetchAll',
	async () => {
		const response: MediasList = await MediasService.fetchAll();

		return response;
	},
);

export const mediasSlice = createSlice({
	name: 'medias',
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder
			// FETCH
			.addCase(fetchMediasAllAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchMediasAllAsync.fulfilled, (state, { payload }) => {
				state.status = FetchStatus.IDLE;
				state.data = payload;
			});
	},
});

const getMedias = (state: RootState) => state.medias;

export const getMediasData = createSelector(getMedias, medias => medias.data);
export const getMediaById = (id: IdType) => createSelector(
	getMediasData, (medias) => medias?.find((media) => media.id === id),
);

export default mediasSlice.reducer;
