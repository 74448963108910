export const AUTH = 'auth';
export const BANK_ACCOUNTS = 'bank-accounts';
export const COMPANIES = 'companies';
export const COMPLAINTS = 'complaints';
export const DOWNLOAD = 'download';
export const GLOSSARY = 'glossary';
export const GROUP_IMAGES = 'group-images';
export const GROUPS = 'groups';
export const GROUPS_USERS = 'groups-users';
export const INVOICE = 'invoice';
export const LEADS = 'leads';
export const MEDIAS = 'medias';
export const PIX = 'pix';
export const SALES = 'sales';
export const TRAINING_CATEGORIES = 'training-categories';
export const TRAININGS = 'trainings';
export const TRANSACTIONS = 'transactions';
export const UPLOAD = 'upload';
export const USER_IMAGES = 'user-images';
export const USERS = 'users';
export const USERS_DOCUMENTS = 'users-documents';
