import { useIntl } from 'react-intl';
import { PixKey } from 'types/payment-data';
import { Dictionary } from 'utils/constants/dictionary';

const GetPixKeyText = (value: PixKey): string => {
	const intl = useIntl();

	switch (value) {
		case PixKey.BANK_ACCOUNT: {
			return intl.formatMessage({ defaultMessage: 'Bank account', id: Dictionary.pix.bankAccount });
		}
		case PixKey.CNPJ: {
			return intl.formatMessage({ defaultMessage: 'CNPJ', id: Dictionary.pix.cnpj });
		}
		case PixKey.CPF: {
			return intl.formatMessage({ defaultMessage: 'CPF', id: Dictionary.pix.cpf });
		}
		case PixKey.EMAIL: {
			return intl.formatMessage({ defaultMessage: 'Email', id: Dictionary.pix.email });
		}
		case PixKey.PHONE: {
			return intl.formatMessage({ defaultMessage: 'Phone number', id: Dictionary.pix.phone });
		}
		default: return value;
	}
};

export default GetPixKeyText;
