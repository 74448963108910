import { FormattedMessage } from 'react-intl';
import { Card, Col, Row } from 'antd';
import { SalesStatisticsData } from 'types/sales';
import { Dictionary } from 'utils/constants/dictionary';
import './SalesStatistics.scss';

const SalesStatistics = ({ currentYear, currentYearSales, totalSales }: SalesStatisticsData): JSX.Element => (
	<div className="sales-statistics">
		<Card className="sales-statistics__card">
			<Row justify="space-between">
				<Col>
					<FormattedMessage defaultMessage="Sales sum" id={Dictionary.common.totalEnrollments.salesSum} />
				</Col>
				<Col>
					{totalSales}
				</Col>
			</Row>
		</Card>

		<Card className="sales-statistics__card">
			<Row justify="space-between">
				<Col>
					<FormattedMessage
						defaultMessage="This year"
						id={Dictionary.common.totalEnrollments.salesCurrentYear}
						values={{ currentYear }}
					/>
				</Col>
				<Col>
					{currentYearSales}
				</Col>
			</Row>
		</Card>
	</div>
);

export default SalesStatistics;
