import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import GroupService from 'services/group-service';
import { FetchStatus } from 'types/common';
import { RankingWeekQuery, GroupRankingItem, RankingFilter } from 'types/rating';
import { RootState } from './index';

type GroupRankingState = {
	currentWeek: GroupRankingItem[] | null;
	lastWeek: GroupRankingItem[] | null;
	all: GroupRankingItem[] | null;
	status: FetchStatus;
};

const initialState: GroupRankingState = {
	currentWeek: null,
	lastWeek: null,
	all: null,
	status: FetchStatus.IDLE,
};

export const fetchGroupRankingTopAsync = createAsyncThunk(
	'groups/rating/top',
	async (week: RankingWeekQuery) => {
		const response = await GroupService.getGroupRankingTop(week);

		return {
			week, response,
		};
	},
);

export const fetchGroupRankingAllAsync = createAsyncThunk(
	'groups/rating/all',
	async (params?: RankingFilter) => {
		const response = await GroupService.getGroupRankingAll(params);

		return response;
	},
);

export const groupRankingSlice = createSlice({
	name: 'groupRanking',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
		// Fetch top
			.addCase(fetchGroupRankingTopAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchGroupRankingTopAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				if (action.payload.week === RankingWeekQuery.CURRENT) {
					state.currentWeek = action.payload.response;
				}
				if (action.payload.week === RankingWeekQuery.LAST) {
					state.lastWeek = action.payload.response;
				}
			})
			// Fetch all
			.addCase(fetchGroupRankingAllAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchGroupRankingAllAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.all = action.payload;
			});
	},
});

const getGroupRanking = (state: RootState) => state.groupRanking;

export const getGroupRankingCurrentWeek = createSelector(getGroupRanking, groupRanking => groupRanking.currentWeek);
export const getGroupRankingLastWeek = createSelector(getGroupRanking, groupRanking => groupRanking.lastWeek);
export const getGroupRankingAll = createSelector(getGroupRanking, groupRanking => groupRanking.all);

export default groupRankingSlice.reducer;
