import { Col, Row } from 'antd';
import FormItemView from 'components/form-item-view';
import ButtonEdit from 'components/forms/button-edit';
import { getUserPersonalData } from 'store/user-personal';
import { UserProfilePersonal } from 'types/user-profile';
import { Dictionary } from 'utils/constants/dictionary';
import { formatDate } from 'utils/helpers/date-time';
import { useAppSelector } from 'utils/hooks';

type Props = {
	onClickEdit: () => void;
};

const ProfilePersonalInfo = ({ onClickEdit }: Props): JSX.Element => {
	const userPersonal: UserProfilePersonal | null = useAppSelector(getUserPersonalData);

	return (
		<div className="profile-form">
			<div className="profile-form__body">
				<Row gutter={24}>
					<Col lg={{ span: 12 }} xs={{ span: 24 }}>
						<FormItemView
							defaultLabel="Profession"
							labelId={Dictionary.personalDataPage.personalInformation.profession}
						>
							{userPersonal?.profession}
						</FormItemView>
					</Col>

					<Col lg={{ span: 12 }} xs={{ span: 24 }}>
						<FormItemView
							defaultLabel="Birth Date"
							labelId={Dictionary.personalDataPage.personalInformation.birthDate}
						>
							{userPersonal?.birthDate && formatDate(userPersonal.birthDate)}
						</FormItemView>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col lg={{ span: 24 }} xs={{ span: 24 }}>
						<FormItemView
							defaultLabel="About"
							labelId={Dictionary.personalDataPage.personalInformation.about}
						>
							{userPersonal?.about}
						</FormItemView>
					</Col>
				</Row>
			</div>

			<ButtonEdit onClick={onClickEdit} />
		</div>
	);
};

export default ProfilePersonalInfo;
