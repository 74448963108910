import { useEffect } from 'react';
import SalesStatistics from 'components/sales-statistics';
import { fetchGroupStatisticsAsync, getGroupStatisticsData } from 'store/group-statistics';
import { IdType } from 'types/common';
import { SalesStatisticsData } from 'types/sales';
import { useAppDispatch, useAppSelector } from 'utils/hooks';

type Props = {
	groupId: IdType;
};

const GroupSalesStatistics = ({ groupId }: Props): JSX.Element | null => {
	const dispatch = useAppDispatch();

	const groupStatistics: SalesStatisticsData | null = useAppSelector(getGroupStatisticsData);

	useEffect(() => {dispatch(fetchGroupStatisticsAsync(groupId));}, [dispatch, groupId]);

	if (!groupStatistics) return null;

	return (
		<SalesStatistics {...groupStatistics} />
	);
};

export default GroupSalesStatistics;
