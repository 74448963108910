import { Location } from 'types/common';
import { Country } from 'types/glossary';

const getUserLocation = <T extends Location>(record: T, countries: Country[]): string => {
	const country: Country | undefined = countries.find(c => c.id === record.countryId);

	if (!country) return '';

	const countryCode: string = country.code;

	const stateCode: string | undefined = country.states?.find(s => s.id === record.stateId)?.code;

	return `${countryCode}.${stateCode ? `${stateCode}.` : ''}${record.city}`;
};

export default getUserLocation;
