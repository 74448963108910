import Mei from './mei';
import './PersonalDocuments.scss';

const PersonalDocuments = (): JSX.Element | null => (
	<div className="app-tabs__wrap personal-documents">
		<Mei />
	</div>
);

export default PersonalDocuments;
