import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, InputProps } from 'antd';

type Props = {
	defaultLabel?: string;
	defaultPlaceholder?: string;
	labelId?: string;
	placeholderId?: string;
	validateStatus?: '' | 'error' | 'success' | 'warning' | 'validating';
	help?: string | number | boolean;
};

const InputText = ({
	defaultLabel,
	defaultPlaceholder,
	name,
	labelId,
	placeholderId,
	validateStatus,
	help,
	required,
	className,
	onBlur,
	onChange,
	value,
	...restProps
}: InputProps & Props): JSX.Element => {
	const intl = useIntl();

	return (
		<Form.Item
			className="input-text"
			colon={false}
			help={help}
			htmlFor={name}
			label={defaultLabel && labelId && <FormattedMessage defaultMessage={defaultLabel} id={labelId} />}
			name={name}
			required={required}
			validateStatus={validateStatus}
		>
			<Input
				{...restProps}
				id={name}
				onChange={onChange}
				placeholder={
					placeholderId && defaultPlaceholder &&
					intl.formatMessage({ id: placeholderId, defaultMessage: defaultPlaceholder })
				}
				value={value}
			/>
		</Form.Item>
	);
};

export default InputText;
