import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import GroupsUsersService from 'services/groups-users-service';
import { FetchStatus, ResultState } from 'types/common';
import { GroupInvitesListItem, GroupInvitesFilter } from 'types/group-invites';
import { GROUP_INVITES_FILTER_DEFAULT } from 'utils/constants/default-filters';
import { RootState } from './index';

type GroupInvitesState = ResultState<GroupInvitesListItem[] | null> & { filter: GroupInvitesFilter };

const initialState: GroupInvitesState = {
	data: null,
	status: FetchStatus.IDLE,
	error: null,
	filter: GROUP_INVITES_FILTER_DEFAULT,
};

export const fetchGroupInvitesAsync = createAsyncThunk(
	'(GET) /groups-users/group-members/status',
	async (filter: GroupInvitesFilter) => {
		const response = await GroupsUsersService.getGroupInvitesStatus(filter);

		return response;
	},
);

export const groupInvitesSlice = createSlice({
	name: 'group-invites',
	initialState,
	reducers: {
		setGroupInvitesFilter(state, { payload }: PayloadAction<GroupInvitesFilter>) {
			state.filter = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGroupInvitesAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchGroupInvitesAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			});
	},
});

const getGroupInvites = (state: RootState) => state.groupInvites;

export const { setGroupInvitesFilter } = groupInvitesSlice.actions;

export const getGroupInvitesData = createSelector(getGroupInvites, groupInvites => groupInvites.data);
export const getGroupInvitesFilter = createSelector(getGroupInvites, groupInvites => groupInvites.filter);

export default groupInvitesSlice.reducer;
