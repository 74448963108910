import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import GroupsUsersService from 'services/groups-users-service';
import { FetchStatus, IdType } from 'types/common';
import { GroupMembersRankingItem } from 'types/rating';
import { RootState } from './index';

type GroupMembersRankingState = {
	data: GroupMembersRankingItem[] | null;
	status: FetchStatus;
};

const initialState: GroupMembersRankingState = {
	data: null,
	status: FetchStatus.IDLE,
};

export const fetchGroupMembersRankingAsync = createAsyncThunk<GroupMembersRankingItem[], IdType>(
	'(GET) /groups-users/group-rating',
	async (groupId: IdType) => {
		const response = await GroupsUsersService.getGroupMembersRanking(groupId);

		return response;
	},
);

export const groupMembersRankingSlice = createSlice({
	name: 'group-members-ranking',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGroupMembersRankingAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchGroupMembersRankingAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			});
	},
});

const getGroupMembersRanking = (state: RootState) => state.groupMembersRanking;

export const getGroupMembersRankingData = createSelector(
	getGroupMembersRanking, groupMembersRanking => groupMembersRanking.data);

export default groupMembersRankingSlice.reducer;
