import { useCallback, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Layout as AntdLayout, Menu, Row } from 'antd';
import { appLogoWhite, callCenterIcoPurple, whatsAppIcoGreen } from 'assets/images';
import Button from 'components/forms/button';
import TopBar from 'components/top-bar';
import UserReferralLink from 'components/user-referral-link';
import { signOut } from 'store/auth';
import { Dictionary } from 'utils/constants/dictionary';
import { mainMenu } from 'utils/constants/main-menu';
import { useAppDispatch, useWindow } from 'utils/hooks';
import { getContentClassName } from './Layout.helper';
import UserProfileDropdown from './user-profile-dropdown';
import UserBalance from './user-balance';
import UserGreeting from './user-greeting';
import './Layout.scss';

const { Content, Footer, Header, Sider } = AntdLayout;

const Layout = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const mainContentClassName = getContentClassName(location.pathname, 'content');

	const [mainMenuMobile, showMainMenuMobile, closeMainMenuMobile] = useWindow();

	const onWindowResize = useCallback(() => {
		if (window.innerWidth > 520 && mainMenuMobile) {
			closeMainMenuMobile();
		}
	}, [closeMainMenuMobile, mainMenuMobile]);

	window.addEventListener('resize', onWindowResize);

	useEffect(() => () => {
		window.removeEventListener('resize', onWindowResize);
	}, [onWindowResize]);

	const handleClickContent = () => {
		if (mainMenuMobile) {
			closeMainMenuMobile();
		}
	};

	const handleClickLogout = (): void => {
		dispatch(signOut());
	};

	return (
		<div className="layout">
			<AntdLayout>
				<Sider
					className={`layout__sider ${mainMenuMobile ? 'layout__sider_mobile-visible' : ''}`}
					width="198"
				>
					<Row className="layout__logo" justify="center">
						<img alt="logo" className="app__logo" src={appLogoWhite} />
					</Row>

					<div className="layout__sider-close" onClick={closeMainMenuMobile} />

					<div className="layout__sider-info">
						<div className="layout__sider-greeting">
							<UserGreeting />
						</div>
						<div className="layout__sider-balance">
							<UserBalance />
						</div>
					</div>

					<div className="layout__sider-nav">
						<nav>
							<Menu defaultSelectedKeys={['/']} mode="inline">
								{mainMenu.map(item => (
									<Menu.Item key={item.link}>
										<img alt="" src={item.icon} />
										<Link to={item.link}>
											<FormattedMessage defaultMessage={item.defaultText} id={item.textId} />
										</Link>
									</Menu.Item>
								))}
							</Menu>
						</nav>
					</div>

					<div className="layout__sider-mobile">
						<div className="layout__sider-mobile-links">
							<a
								className="layout__sider-mobile-link"
								href="https://go.fluencyacademy.co/wpp-conexoes-fa"
								target="_blank"
							>
								<img alt="" src={whatsAppIcoGreen} />
								<FormattedMessage defaultMessage="WhatsApp" id={Dictionary.profileDropdown.whatsApp} />
							</a>
							{/* <a className="layout__sider-mobile-link" href="#">
								<img alt="" src={callCenterIcoPurple} />
								<FormattedMessage
									defaultMessage="Help Center"
									id={Dictionary.profileDropdown.helpCenter}
								/>
							</a> */}
						</div>

						<Button
							className="layout__sider-mobile-logout"
							defaultText="Logout"
							onClick={handleClickLogout}
							textId={Dictionary.auth.logout}
						/>
					</div>
				</Sider>

				<AntdLayout className={`layout__main-content ${mainContentClassName}`}>
					<Header>
						<div className="header__referral-link">
							<UserReferralLink />
						</div>

						<div className="header__top-bar_desktop">
							<UserProfileDropdown />
						</div>
						<div className="header__top-bar_mobile">
							<TopBar onClickMenu={showMainMenuMobile} />
						</div>
					</Header>

					<Content onClick={handleClickContent}>
						<Outlet />
					</Content>

					<Footer />
				</AntdLayout>
			</AntdLayout>
		</div>
	);
};

export default Layout;
