import { AuthorizationResponse, Credentials } from 'types/auth';
import { AUTH } from 'utils/constants/end-points';
import Http from './http';

class AuthorizationService {
	authorizationUser = (credentials: Credentials): Promise<AuthorizationResponse> => Http.post(
		`${AUTH}/user`, credentials);
}

export default new AuthorizationService();
