import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import SalesService from 'services/sales-service';
import { FetchStatus, ResultState } from 'types/common';
import { SalesUserLevel } from 'types/sales';
import { RootState } from './index';

const initialState: ResultState<SalesUserLevel | null> = {
	data: null,
	status: FetchStatus.IDLE,
	error: null,
};

export const fetchSalesUserLevelAsync = createAsyncThunk(
	'sales/user-level',
	async () => {
		const response: SalesUserLevel = await SalesService.getUserLevel();

		return response;
	},
);

export const salesUserLevelSlice = createSlice({
	name: 'salesUserLevel',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSalesUserLevelAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchSalesUserLevelAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			});
	},
});

const getSalesUserLevel = (state: RootState) => state.salesUserLevel;

export const getSalesUserLevelData = createSelector(getSalesUserLevel, salesUserLevel => salesUserLevel.data);

export default salesUserLevelSlice.reducer;
