import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Message from 'components/message';
import UserService from 'services/user-service';
import ROUTES from 'utils/constants/routes';
import NotFound from 'views/not-found';

const ProfileSetEmail = (): JSX.Element | null => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const token: string | null = searchParams.get('token');

	useEffect(() => {
		if (token) {
			UserService.setEmail(token).then(res => {
				if (res) {
					navigate(`/${ROUTES.PROFILE.BASE}/${ROUTES.PROFILE.SECURITY_SETTINGS}`);
					Message.success({ messageText: res.message });
				}
			});
		}
	}, [navigate, token]);

	if (!token) return <NotFound />;

	return null;
};

export default ProfileSetEmail;
