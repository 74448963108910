import { pencilWhite } from 'assets/images';
import Button from '../button';

type Props = {
	className?: string;
	onClick: () => void;
};

const ButtonEditRound = ({ className, onClick }: Props): JSX.Element => (
	<Button
		className={`btn-round btn-round_top-right ${className || ''}`}
		imgSrc={pencilWhite}
		onClick={onClick}
	/>
);

export default ButtonEditRound;
