import { DateRangeFilter, IdType } from 'types/common';
import { SalesFilter, SalesHistoryData, SalesListItem, SalesStatisticsData, SalesUserLevel } from 'types/sales';
import { SALES } from 'utils/constants/end-points';
import Http from './http';

class SalesService {
	getGroupStatistics = (groupId: IdType): Promise<SalesStatisticsData> => Http.get(
		`${SALES}/group-statistics`, { params: { groupId } },
	);

	getUserStatistics = (userId: IdType): Promise<SalesStatisticsData> => Http.get(
		`${SALES}/user-statistics`, { params: { userId } },
	);

	getSalesByTransactionCode = (hotmartTransactionCode: string): Promise<SalesListItem[]> => Http.get(
		`${SALES}/child`, { params: { hotmartTransactionCode } },
	);

	getSalesHistory = (params: DateRangeFilter): Promise<SalesHistoryData> => Http.get(`${SALES}/summary`, { params });

	getSalesList = (filter: SalesFilter): Promise<SalesListItem[]> => Http.get(
		`${SALES}/all`, { params: { ...filter } },
	);

	getUserLevel = (): Promise<SalesUserLevel> => Http.get(`${SALES}/user-level`);
}

export default new SalesService();
