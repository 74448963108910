import { useEffect } from 'react';
import PageTitle from 'components/page-title';
import { fetchSalesListAsync, getSalesListData, getSalesListFilter } from 'store/sales-list';
import { fetchUserBalanceAsync } from 'store/user-balance';
import { SalesFilter, SalesListItem } from 'types/sales';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import SalesTable from './sales-table';
import './Sales.scss';

const Sales = (): JSX.Element => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchUserBalanceAsync());
	}, [dispatch]);

	const salesListData: SalesListItem[] | null = useAppSelector(getSalesListData);
	const salesFilter: SalesFilter = useAppSelector(getSalesListFilter);

	useEffect(() => {
		dispatch(fetchSalesListAsync(salesFilter));
	}, [dispatch, salesFilter]);

	return (
		<div className="sales">
			<PageTitle defaultText="Sales" textId={Dictionary.salesPage.title} />

			{salesListData && <SalesTable dataSource={salesListData} />}
		</div>
	);
};

export default Sales;

