import ROUTES from 'utils/constants/routes';

export const getContentClassName = (pathname: string, additionalName?: string) => {
	let className = 'home';

	switch (true) {
		case pathname.includes(ROUTES.MEDIAS.BASE):
			className = 'medias';
			break;
		case pathname.includes(ROUTES.TRAINING.BASE):
			className = 'trainings';
			break;
		case pathname.includes(ROUTES.PROFILE.BASE):
			className = 'profile';
			pathname.includes(ROUTES.PROFILE.INVITATION) && (className = 'invitation');
			(pathname.includes(ROUTES.PROFILE.LEGAL_DOCUMENTS)) &&
			(className = 'dark');
			break;
		case pathname.includes(ROUTES.LEADS.BASE):
			className = 'leads';
			break;
		case pathname.includes(ROUTES.GROUP.BASE):
			className = 'group';
			break;
		case pathname.includes(ROUTES.RANKING.BASE):
			className = 'ranking';
			break;

		default:
			break;
	}

	className = (additionalName) ? `${className}-${additionalName}` : className;

	return className;
};
