import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { appLogoWhite } from 'assets/images';
import Button from 'components/forms/button';
import { InputText } from 'components/inputs-formik';
import Message from 'components/message';
import { getLoggedIn } from 'store/auth';
import { forgotPasswordAsync } from 'store/user-password';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { useForgotPasswordValidationSchema } from 'utils/validation/schemas';
import './ForgotPassword.scss';

type FormValues = {
	email: string;
};

const ForgotPassword = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const loggedIn = useAppSelector(getLoggedIn);
	const validationSchema = useForgotPasswordValidationSchema();

	const InnerForm = ({ dirty, isValid }: FormikProps<FormValues>): JSX.Element => (
		<Form className="auth forgot-password">
			<div className="auth__form-wrapper">
				<div className="auth__form-header">
					<img alt="app-logo" src={appLogoWhite} />
				</div>
				<div className="auth__form-body">
					<div className="auth__form-title">Log in</div>

					<div className="auth__form-texts">
						<div className="auth__form-text">
							<FormattedMessage
								defaultMessage="Enter your email to recover your password"
								id={Dictionary.auth.enterEmail}
							/>
						</div>

						<div className="auth__form-text">
							<FormattedMessage
								// eslint-disable-next-line max-len
								defaultMessage="A link to reset your email will be sent to the email registered to your account."
								id={Dictionary.auth.resetLinkMessage}
							/>
						</div>
					</div>

					<InputText defaultPlaceholder="Email" name="email" placeholderId={Dictionary.auth.email} />
				</div>

				<div className="auth__form-footer">
					<Button
						className="btn-green"
						defaultText="Submit"
						disabled={!dirty || !isValid}
						textId={Dictionary.common.send}
						type="submit"
					/>
					<NavLink className="no-underline" to={'/auth'}>
						<Button defaultText="Back" textId={Dictionary.common.back} />
					</NavLink>
				</div>
			</div>
		</Form>
	);

	const handleSubmit = ({ email }: FormValues): void => {
		dispatch(forgotPasswordAsync(email))
			.then((res) => {
				if (res.payload) {
					Message.success({ messageId: Dictionary.message.success.forgotPasswordLinkSent });
					navigate('/');
				}
			});
	};

	return loggedIn ? <Navigate to="/" /> : (
		<Formik
			component={InnerForm}
			initialValues={{ email: '' }}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
		/>
	);
};

export default ForgotPassword;
