import { SyntheticEvent } from 'react';
import { Dropdown } from 'antd';
import Avatar from 'components/avatar';
import Button from 'components/forms/button';
import { InputImageFile } from 'components/inputs';
import { uploadImageAsync } from 'store/upload';
import { IdType, ImageContainer } from 'types/common';
import { UploadImageResponse } from 'types/upload';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch } from 'utils/hooks';
import { cameraWhite } from 'assets/images';
import './EditAvatar.scss';

type Props = {
	imageId: IdType | null;
	className?: string;
	containerName: ImageContainer;
	defaultImage?: string;
	onChange: (value: IdType | null) => void;
};

const EditAvatar = ({ imageId, className, containerName, defaultImage, onChange }: Props): JSX.Element => {
	const dispatch = useAppDispatch();

	const EditAvatarMenu = (): JSX.Element => {
		const handleChange = (e: SyntheticEvent): void => {
			const target = e.target as HTMLInputElement;
			const file: File | null = target.files && target.files[0];

			if (file) {
				//TODO: image size validation
				dispatch(uploadImageAsync({ file, type: containerName }))
					.then(res => onChange((res.payload as UploadImageResponse).imageId));
			}
		};

		return (
			<div className="edit-avatar__dropdown-menu">
				<InputImageFile
					className="btn btn-green"
					defaultMessage="New"
					id="edit-avatar__input-file"
					labelId={Dictionary.avatarDropdown.newPhoto}
					onChange={handleChange}
				/>

				<Button
					defaultText="Remove"
					onClick={() => onChange(null)}
					textId={Dictionary.avatarDropdown.removePhoto}
				/>
			</div>
		);
	};

	return (
		<Dropdown className={`edit-avatar ${className || ''}`} overlay={EditAvatarMenu} trigger={['click']}>
			<div>
				<Avatar
					active
					alt={'edit-avatar-img'}
					className="edit-avatar__img"
					containerName={containerName}
					defaultImg={defaultImage}
					imageId={imageId}
				/>

				<div className="edit-avatar__img-ico">
					<img alt="" src={cameraWhite} />
				</div>
			</div>
		</Dropdown>
	);
};

export default EditAvatar;
