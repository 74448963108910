import { BaseMessageDto, IdType } from 'types/common';
import { AddGroupImageRequest, DeleteGroupImagesRequest, ImageListItem } from 'types/images';
import { GROUP_IMAGES } from 'utils/constants/end-points';
import Http from './http';

class GroupImagesService {
	addGroupImage = ({ groupId, imageId }: AddGroupImageRequest): Promise<ImageListItem> => Http.post(
		GROUP_IMAGES, { groupId, imageId });

	deleteGroupImages = ({ groupId, ids }: DeleteGroupImagesRequest): Promise<BaseMessageDto> => Http.delete(
		GROUP_IMAGES, { data: { ids }, params: { groupId } });

	getGroupImages = (groupId: IdType): Promise<ImageListItem[]> => Http.get(
		`${GROUP_IMAGES}/all`, { params: { groupId } });
}

export default new GroupImagesService();
