import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserService from 'services/user-service';
import { BaseMessageDto, FetchStatus } from 'types/common';
import { SetPasswordRequest } from 'types/user-password';

type UserPasswordState = {
	status: FetchStatus;
};

const initialState: UserPasswordState = {
	status: FetchStatus.IDLE,
};

export const forgotPasswordAsync = createAsyncThunk(
	'(PATCH) /users/forgot-password',
	async (email: string) => {
		const message: BaseMessageDto = await UserService.forgotPassword(email);

		return message;
	},
);

export const setPasswordAsync = createAsyncThunk(
	'(PATCH) /users/set-password',
	async (req: SetPasswordRequest) => {
		const message: BaseMessageDto = await UserService.setPassword(req);

		return message;
	},
);

export const userPasswordSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(forgotPasswordAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(forgotPasswordAsync.fulfilled, (state) => {
				state.status = FetchStatus.IDLE;
			})

			.addCase(setPasswordAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(setPasswordAsync.fulfilled, (state) => {
				state.status = FetchStatus.IDLE;
			});
	},
});

export default userPasswordSlice.reducer;
