const consoleError = console.error.bind(console);

console.error = (errObj, ...args) => {
	if (process.env.NODE_ENV === 'development' && typeof errObj === 'string' && args.includes('findDOMNode')) {
		return;
	}
	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	consoleError(errObj, ...args);
};

export default consoleError;
