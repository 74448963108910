import { useMemo } from 'react';
import {
	starEmpty, starEmptyWhite, starFullBlack,
	starFullPurple, starFullWhite,
} from 'assets/images';
import { AppTheme } from 'types/common';
import { RatingLevel } from 'types/rating';
import { RatingStarsMode } from 'utils/constants/rating';
import './RatingStars.scss';

type Props = {
	levelTitle: RatingLevel;
	stars: number;
	ratingStarsMode?: RatingStarsMode;
	theme?: AppTheme;
};

const getStarsAmount = (ratingLevel: RatingLevel): number => {
	switch (ratingLevel) {
		case RatingLevel.GOAL_GETTER_I: return 3;
		case RatingLevel.GOAL_GETTER_II: return 8;
		case RatingLevel.LEADER_I: return 3;
		case RatingLevel.LEADER_II: return 5;
		case RatingLevel.TRUE_LEADER_I: return 3;
		case RatingLevel.TRUE_LEADER_II: return 5;
		case RatingLevel.MASTER: return 6;
		default: return 0;
	}
};

const RatingStars = ({
	stars, levelTitle, ratingStarsMode = RatingStarsMode.BLACK, theme = AppTheme.LIGHT,
}: Props): JSX.Element => {

	const starEmptyImg: string = useMemo(() => theme === AppTheme.LIGHT ? starEmpty : starEmptyWhite, [theme]);

	const starFullImg: string = useMemo(() => {
		if (ratingStarsMode === RatingStarsMode.ORANGE) return starFullPurple;
		if (ratingStarsMode === RatingStarsMode.WHITE) return starFullWhite;

		return starFullBlack;
	}, [ratingStarsMode]);

	return (
		<div className="rating-stars">
			{Array(stars).fill(undefined).map((_, index) => (
				<img alt={`Star${index + 1}`} key={`Star${index + 1}`} src={starFullImg} />
			))}

			{Array(getStarsAmount(levelTitle) - stars).fill(undefined).map((_, index) => (
				<img alt={`Star${index + 1}`} key={`Star${index + 1}`} src={starEmptyImg} />
			))}
		</div>
	);
};

export default RatingStars;
