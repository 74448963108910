import { ReactNodeArray, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Upload } from 'antd';
import { arrowUpWhite, checkWhite } from 'assets/images';
import Button from 'components/forms/button';
import Message from 'components/message';
import UploadService from 'services/upload-service';
import UserDocumentsService from 'services/user-documents-service';
import { UserDocumentsStatus } from 'types/user-documents';
import { Dictionary } from 'utils/constants/dictionary';
import getUserDocumentsMessage from 'utils/helpers/get-user-documents-message';
import useUploadFile from 'utils/hooks/use-upload-file';
import './Mei.scss';

const MEI_FORMAT = '.pdf';

const uploadStatuses: UserDocumentsStatus[] = [UserDocumentsStatus.NOT_LOADED, UserDocumentsStatus.NOT_VERIFIED];

const Mei = (): JSX.Element | null => {
	const [status, setStatus] = useState<UserDocumentsStatus | null>();
	const [comment, setComment] = useState<string | undefined>();

	const [file, handleChangeUpload] = useUploadFile();

	const getStatus = () => {
		UserDocumentsService.getDocumentStatus().then(res => {
			setStatus(res.status);
			if (res.comment) {
				setComment(res.comment);
			}
		});
	};

	useEffect(() => {
		getStatus();
	}, []);

	if (!status) return null;

	const handleSubmit = (): void => {
		if (file) {
			UploadService.uploadUserDocument(file).then((res) => {
				if (res) {
					Message.success({
						messageId: Dictionary.message.success.documentUploaded,
						messageText: 'Your document successfully uploaded',
					});
					getStatus();
				}
			});
		}
	};

	return (
		<div className="app-tabs-tab__container mei">
			<FormattedMessage {...getUserDocumentsMessage(status)} values={{ br: <br /> }}>
				{(chunks: ReactNodeArray) => (
					<h2>
						<strong>
							{chunks}
						</strong>
					</h2>
				)}
			</FormattedMessage>

			{status === UserDocumentsStatus.NOT_VERIFIED && comment && (
				<div className="verification-comment">
					{comment}
				</div>
			)}

			{status === UserDocumentsStatus.NOT_VERIFIED && (
				<FormattedMessage
					defaultMessage="Please, upload the document again"
					id={Dictionary.personalDataPage.personalDocuments.uploadAgainText}
				>
					{(chunks: ReactNodeArray) => (
						<h2>
							<strong>
								{chunks}
							</strong>
						</h2>
					)}
				</FormattedMessage>
			)}

			{uploadStatuses.includes(status) && (
				<div className="app-tabs-tab__footer">
					<div className="upload-container">
						<Upload
							accept={MEI_FORMAT}
							beforeUpload={() => false}
							maxCount={1}
							onChange={handleChangeUpload}
						>
							<Button
								className="btn-upload-mei"
								defaultText="Upload"
								imgSrc={arrowUpWhite}
								textId={Dictionary.common.upload}
							/>
						</Upload>
						{!file && (
							<FormattedMessage
								defaultMessage={
									'Your document must be in {format} format'
								}
								id={Dictionary.personalDataPage.personalDocuments.documentFormatMsg}
								values={{
									format: MEI_FORMAT,
								}}
							/>
						)}
					</div>

					<Button
						className="btn-submit"
						defaultText="Send"
						disabled={!file}
						imgSrc={checkWhite}
						onClick={handleSubmit}
						textId={Dictionary.common.send}
					/>
				</div>
			)}
		</div>
	);
};

export default Mei;
