import { Link } from 'react-router-dom';
import Avatar from 'components/avatar';
import { getGlossaryData } from 'store/glossary';
import { getUserProfileData } from 'store/user-profile';
import { IdType, ImageContainer } from 'types/common';
import { Country, GlossaryEnum } from 'types/glossary';
import { RankingPosition } from 'types/rating';
import { UserInfo } from 'types/user';
import { UserProfile } from 'types/user-profile';
import getUserLocation from 'utils/helpers/get-user-location';
import { useAppSelector } from 'utils/hooks';
import './UserInfoColumn.scss';

type Props<T> = {
	active?: boolean;
	className?: string;
	showGroupAvatar?: boolean;
	showRatingPosition?: boolean;
	userInfo: T;
};

type GroupData = {
	groupAvatarImageId?: IdType | null;
	groupId?: IdType | null;
};

const UserInfoColumn = <T extends UserInfo & RankingPosition & GroupData>({
	active = false, className, showGroupAvatar = false, showRatingPosition = true, userInfo,
}: Props<T>): JSX.Element => {

	const countries: Country[] | null = useAppSelector(getGlossaryData<Country[] | null>(GlossaryEnum.COUNTRIES));
	const userProfile: UserProfile | null = useAppSelector(getUserProfileData);

	const isCurrentUser: boolean = userInfo.id === userProfile?.id;

	return (
		<div className={`table__row-inner ${className || ''} ${active ? 'active' : ''}`}>
			<div className="flex align-items-center">
				<Link to={`/profile${isCurrentUser ? '' : `/${userInfo.id}`}`}>
					<Avatar
						active={active}
						alt={`user-avatar_${userInfo.id}`}
						className="table__user-avatar"
						containerName={ImageContainer.USER_AVATAR}
						imageId={userInfo.avatarImageId}
						position={showRatingPosition ? userInfo.ratingNumber : undefined}
					/>
				</Link>

				<div className="table__group-avatar-container">
					{showGroupAvatar && userInfo.groupId && (
						<Link to={`/group${isCurrentUser ? '' : `/${userInfo.groupId}`}`}>
							<Avatar
								alt={`group-avatar_${userInfo.id}`}
								className="table__group-avatar"
								containerName={ImageContainer.GROUP_AVATAR}
								imageId={userInfo.groupAvatarImageId || null}
							/>
						</Link>
					)}
				</div>

			</div>

			<div className="table__user-data">
				<div className="table__user-name">
					{userInfo.nickname}
				</div>
				<div className="table__user-info">
					{getUserLocation(userInfo, countries || [])}
				</div>
			</div>
		</div>
	);
};

export default UserInfoColumn;
