import { Select } from 'antd';
import { arrowDownPurple } from 'assets/images';
import { getGroupMembersFilter, setGroupMembersFilter } from 'store/group-members';
import { Sorting } from 'types/common';
import { GroupMembersFilter, GroupMembersOrderBy } from 'types/group-members';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './GroupMembersStatusSorting.scss';

enum GroupMembersSorting {
	ALPHABET_ASC = 'A-Z',
	ALPHABET_DESC = 'Z-A',
	CREATED_ASC = '1-31',
	CREATED_DESC = '31-1',
}

const { ALPHABET_ASC, ALPHABET_DESC, CREATED_ASC, CREATED_DESC } = GroupMembersSorting;

const GroupMembersStatusSorting = (): JSX.Element => {
	const dispatch = useAppDispatch();

	const filter: GroupMembersFilter = useAppSelector(getGroupMembersFilter);

	const handleSelect = (value: GroupMembersSorting): void => {
		switch (value) {
			case ALPHABET_ASC: {
				dispatch(setGroupMembersFilter({
					...filter, orderBy: GroupMembersOrderBy.ALPHABET, sorting: Sorting.ASC,
				}));
				break;
			}
			case ALPHABET_DESC: {
				dispatch(setGroupMembersFilter({
					...filter, orderBy: GroupMembersOrderBy.ALPHABET, sorting: Sorting.DESC,
				}));
				break;
			}
			case CREATED_ASC: {
				dispatch(setGroupMembersFilter({
					...filter, orderBy: GroupMembersOrderBy.CREATED, sorting: Sorting.ASC,
				}));
				break;
			}
			case CREATED_DESC: {
				dispatch(setGroupMembersFilter({
					...filter, orderBy: GroupMembersOrderBy.CREATED, sorting: Sorting.DESC,
				}));
				break;
			}
		}
	};

	return (
		<Select
			defaultValue={CREATED_DESC}
			dropdownClassName="group-members-status-sorting__dropdown"
			onSelect={handleSelect}
			placeholder="Sort"
			suffixIcon={<img alt="" src={arrowDownPurple} />}
		>
			{Object.values(GroupMembersSorting).map(value => (
				<Select.Option key={value} value={value}>
					{value}
				</Select.Option>
			))}
		</Select>
	);
};

export default GroupMembersStatusSorting;
