import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { reportIconBlack } from 'assets/images';
import { Dictionary } from 'utils/constants/dictionary';

type Props = {
	onClick: () => void;
};

const ButtonReport = ({ onClick }: Props): JSX.Element => (
	<Button className="btn btn-report btn-report_big" onClick={onClick}>
		<img alt="" src={reportIconBlack} />
		<FormattedMessage defaultMessage="Report" id={Dictionary.common.report} />
	</Button>
);

export default ButtonReport;
