import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import TrainingsService from 'services/trainings-service';
import { FetchStatus, IdType, ResultState } from 'types/common';
import { Training } from 'types/trainings';
import { RootState } from './index';

const initialState: ResultState<Training | null> = {
	data: null,
	status: FetchStatus.IDLE,
	error: null,
};

export const fetchTrainingByIdAsync = createAsyncThunk(
	'training',
	async (trainingId: IdType) => {
		const training: Training = await TrainingsService.fetchById(trainingId);

		return training;
	},
);

export const trainingSlice = createSlice({
	name: 'training',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// FETCH
			.addCase(fetchTrainingByIdAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchTrainingByIdAsync.fulfilled, (state, { payload }) => {
				state.status = FetchStatus.IDLE;
				state.data = payload;
			});
	},
});

const getTraining = (state: RootState) => state.training;

export const getTrainingData = createSelector(getTraining, trainings => trainings.data);
export const isTrainingLoading = createSelector(getTraining, trainings => trainings.status === FetchStatus.LOADING);

export default trainingSlice.reducer;
