import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import PixService from 'services/pix-service';
import { BaseMessageDto, FetchStatus } from 'types/common';
import { CreatePixDataDto, PixData, PixDataResponse } from 'types/payment-data';
import { RootState } from './index';

type UserPixState = {
	data: PixDataResponse | null;
	status: FetchStatus;
};

const initialState: UserPixState = {
	data: null,
	status: FetchStatus.IDLE,
};

export const createUserPixAsync = createAsyncThunk(
	'(POST) /pix',
	async (data: CreatePixDataDto) => {
		const response: PixDataResponse = await PixService.createPixData(data);

		return response;
	},
);

export const fetchUserPixAsync = createAsyncThunk(
	'(GET) /pix',
	async () => {
		const response: PixDataResponse | null = await PixService.getPixData();

		return response;
	},
);

export const updateUserPixAsync = createAsyncThunk(
	'(PUT) /pix',
	async (data: PixData) => {
		const response: PixDataResponse = await PixService.updatePixData(data);

		return response;
	},
);

export const removeUserPixAsync = createAsyncThunk(
	'(DELETE) /pix',
	async () => {
		const response: BaseMessageDto = await PixService.removePixData();

		return response;
	},
);

export const userPixSlice = createSlice({
	name: 'userPix',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(createUserPixAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(createUserPixAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			})

			.addCase(fetchUserPixAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchUserPixAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			})

			.addCase(updateUserPixAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(updateUserPixAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			})

			.addCase(removeUserPixAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(removeUserPixAsync.fulfilled, (state) => {
				state.status = FetchStatus.IDLE;
				state.data = null;
			});
	},
});

const getUserPix = (state: RootState) => state.userPix;

export const getUserPixData = createSelector(getUserPix, userPix => userPix.data);

export default userPixSlice.reducer;
