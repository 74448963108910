import { Row, Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import ButtonSave from 'components/forms/button-save';
import { InputText } from 'components/inputs-formik';
import SelectGlossary from 'components/inputs-formik/SelectGlossary';
import Message from 'components/message';
import { getGlossaryData } from 'store/glossary';
import { getUserAddressData, updateUserAddressAsync } from 'store/user-address';
import { Country, GlossaryEnum } from 'types/glossary';
import { UserProfileAddress } from 'types/user-profile';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector } from 'utils/hooks';

type Props = {
	onClickSave: () => void;
};

const PersonalAddressInfoEdit = ({ onClickSave }: Props): JSX.Element | null => {
	const dispatch = useAppDispatch();

	const countries: Country[] | null = useAppSelector(getGlossaryData<Country[] | null>(GlossaryEnum.COUNTRIES));

	const userAddress: UserProfileAddress | null = useAppSelector(getUserAddressData);

	const handleSubmit = (values: UserProfileAddress): void => {
		dispatch(updateUserAddressAsync(values)).then(res => {
			if (res.payload) {
				Message.success({ messageId: Dictionary.message.success.changesSaved });
				onClickSave();
			}
		});
	};

	const InnerForm = ({ values }: FormikProps<UserProfileAddress>): JSX.Element => {
		const selectedCountry: Country | undefined = countries?.find(c => c.id === values.countryId);

		return (
			<Form className="profile-form">
				<div className="profile-form__body">
					<Row gutter={24}>
						<Col lg={{ span: 24 }} xs={{ span: 24 }}>
							<InputText
								defaultLabel="Street"
								defaultPlaceholder="Input street"
								labelId={Dictionary.personalDataPage.address.street}
								name="street"
								placeholderId={Dictionary.personalDataPage.address.streetPlaceholder}
							/>
						</Col>
					</Row>

					<Row gutter={24}>
						<Col lg={{ span: 12 }} xs={{ span: 24 }}>
							<InputText
								defaultLabel="Number"
								defaultPlaceholder="Input address number"
								labelId={Dictionary.personalDataPage.address.number}
								name="house"
								placeholderId={Dictionary.personalDataPage.address.numberPlaceholder}
							/>
						</Col>

						<Col lg={{ span: 12 }} xs={{ span: 24 }}>
							<InputText
								defaultLabel="Complement"
								defaultPlaceholder="Complement address"
								labelId={Dictionary.personalDataPage.address.complement}
								name="apartment"
								placeholderId={Dictionary.personalDataPage.address.complementPlaceholder}
							/>
						</Col>
					</Row>

					<Row gutter={24}>
						<Col lg={{ span: 24 }} xs={{ span: 24 }}>
							<InputText
								defaultLabel="Neighborhood"
								defaultPlaceholder="Input address neighborhood"
								labelId={Dictionary.personalDataPage.address.neighborhood}
								name="district"
								placeholderId={Dictionary.personalDataPage.address.neighborhoodPlaceholder}
							/>
						</Col>
					</Row>

					<Row gutter={24}>
						<Col lg={{ span: 12 }} xs={{ span: 24 }}>
							<SelectGlossary
								defaultLabel="Country"
								defaultPlaceholder="Select Country"
								keyName="name"
								labelId={Dictionary.personalDataPage.address.country}
								name="countryId"
								placeholderId={Dictionary.personalDataPage.address.countryPlaceholder}
								selectOptions={countries}
							/>
						</Col>

						<Col lg={{ span: 12 }} xs={{ span: 24 }}>
							<SelectGlossary
								defaultLabel="State"
								defaultPlaceholder="Select State"
								keyName="name"
								labelId={Dictionary.personalDataPage.address.state}
								name="stateId"
								placeholderId={Dictionary.personalDataPage.address.statePlaceholder}
								renderItem={state => state.code}
								selectOptions={selectedCountry?.states}
							/>
						</Col>
					</Row>

					<Row gutter={24}>
						<Col lg={{ span: 12 }} xs={{ span: 24 }}>
							<InputText
								defaultLabel="City"
								defaultPlaceholder="Input city"
								labelId={Dictionary.personalDataPage.address.city}
								name="city"
								placeholderId={Dictionary.personalDataPage.address.cityPlaceholder}
							/>
						</Col>

						<Col lg={{ span: 12 }} xs={{ span: 24 }}>
							<InputText
								defaultLabel="ZIP code"
								defaultPlaceholder="00000-000"
								labelId={Dictionary.personalDataPage.address.zip}
								name="zip"
								placeholderId={Dictionary.personalDataPage.address.zipPlaceholder}
							/>
						</Col>
					</Row>
				</div>

				<ButtonSave />
			</Form>
		);
	};

	if (!userAddress) return null;

	return <Formik component={InnerForm} initialValues={userAddress} onSubmit={handleSubmit} />;
};

export default PersonalAddressInfoEdit;
