import { IdType, UpdateDataPayload } from 'types/common';
import { Group, GroupByTokenResponse, GroupDataRequest, GroupDataResponse } from 'types/group';
import { GroupRankingItem, RankingFilter, RankingWeekQuery } from 'types/rating';
import { GROUPS } from 'utils/constants/end-points';
import Http from './http';

class GroupService {
	createGroup = (data: GroupDataRequest): Promise<GroupDataResponse> => Http.post(GROUPS, data);

	getGroupData = (id: IdType): Promise<Group> => Http.get(GROUPS, { params: { id } });

	getGroupIdByToken = (token: string): Promise<GroupByTokenResponse> => Http.get(
		`${GROUPS}/by-token`, { params: { token } },
	);

	getGroupRankingTop = (week: RankingWeekQuery): Promise<GroupRankingItem[]> => Http.get(
		`${GROUPS}/rating/top`, { params: { week } },
	);

	getGroupRankingAll = (params?: RankingFilter): Promise<GroupRankingItem[]> => Http.get(
		`${GROUPS}/rating/all`, { params },
	);

	updateGroup = ({ id, data }: UpdateDataPayload<GroupDataRequest>): Promise<GroupDataResponse> => Http.put(
		GROUPS, data, { params: { id } },
	);
}

export default new GroupService();
