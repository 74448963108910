import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import UserService from 'services/user-service';
import { FetchStatus } from 'types/common';
import { UserProfileAddress } from 'types/user-profile';
import { RootState } from './index';

type UserAddressState = {
	data: UserProfileAddress | null;
	status: FetchStatus;
};

const initialState: UserAddressState = {
	data: null,
	status: FetchStatus.IDLE,
};

export const fetchUserAddressAsync = createAsyncThunk(
	'(GET) users/profile/address',
	async () => {
		const response: UserProfileAddress = await UserService.getProfileAddress();

		return response;
	},
);

export const updateUserAddressAsync = createAsyncThunk(
	'(PUT) users/profile/address',
	async (data: UserProfileAddress) => {
		const response: UserProfileAddress = await UserService.updateProfileAddress(data);

		return response;
	},
);

export const userAddressSlice = createSlice({
	name: 'userAddress',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserAddressAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchUserAddressAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			})

			.addCase(updateUserAddressAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(updateUserAddressAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;

				if (state.data) {
					state.data = { ...state.data, ...action.payload };
				}
			});
	},
});

const getUserAddress = (state: RootState) => state.userAddress;

export const getUserAddressData = createSelector(getUserAddress, userAddress => userAddress.data);

export default userAddressSlice.reducer;
