import { ReactNodeArray, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { arrowUpWhite, checkWhite } from 'assets/images';
import Button from 'components/forms/button';
import Message from 'components/message';
import { TextPurple } from 'components/text-formatters';
import UploadService from 'services/upload-service';
import { getUserBalanceData } from 'store/user-balance';
import { UserBalance } from 'types/transactions';
import { Dictionary } from 'utils/constants/dictionary';
import convertBalance from 'utils/helpers/convert-balance';
import { useAppSelector } from 'utils/hooks';
import './Withdrawal.scss';

const INVOICE_FORMAT = '.pdf';

type Props = {
	onSubmit: () => void;
};

const Withdrawal = ({ onSubmit }: Props): JSX.Element | null => {
	const userBalance: UserBalance | null = useAppSelector(getUserBalanceData);

	const [file, setFile] = useState<File | null>(null);

	if (!userBalance) return null;

	const { amount, currency } = userBalance;

	const actionDenied: boolean = amount === 0;

	const handleChangeUpload = ({ file }: UploadChangeParam<UploadFile>): void => {
		if (file.status === 'removed') {
			setFile(null);
			return;
		}

		setFile(file as unknown as File);
	};

	const handleSubmit = (): void => {
		if (file) {
			UploadService.uploadInvoice(file).then(() => {
				Message.success({
					messageId: Dictionary.message.success.invoiceSent,
					messageText: 'Your invoice successfully sent to the financial team!',
				});
				onSubmit();
			});
		}
	};

	return (
		<div className="commissions-tab__container withdrawal-page">
			<FormattedMessage
				defaultMessage={
					`Your Entrepreneur Fluency balance: <purple>{currency} {balance}</purple>{br}${actionDenied
						? "You can't make a withdrawal request." : 'Upload an invoice to receive your money!'}`
				}
				id={Dictionary.commissionsPage.withdrawal[actionDenied ? 'actionDeniedText' : 'actionText']}
				values={{
					balance: convertBalance(amount),
					br: <br />,
					currency,
					purple: TextPurple,
				}}
			>
				{(chunks: ReactNodeArray) => (
					<h2>
						<strong>
							{chunks}
						</strong>
					</h2>
				)}
			</FormattedMessage>

			<div className="commissions-tab__footer">
				<div className="upload-container">
					<Upload
						accept={INVOICE_FORMAT}
						beforeUpload={() => false}
						disabled={actionDenied}
						maxCount={1}
						onChange={handleChangeUpload}
					>
						<Button
							className="btn-upload-invoice"
							defaultText="Upload"
							disabled={actionDenied}
							imgSrc={arrowUpWhite}
							textId={Dictionary.commissionsPage.withdrawal.uploadBtnText}
						/>
					</Upload>
					{!actionDenied && !file && (
						<FormattedMessage
							defaultMessage={
								'Your invoice must be in {format} format'
							}
							id={Dictionary.commissionsPage.withdrawal.invoiceFormatText}
							values={{
								format: INVOICE_FORMAT,
							}}
						/>
					)}
				</div>

				<Button
					className="btn-send-invoice"
					defaultText="Send"
					disabled={!file || actionDenied}
					imgSrc={checkWhite}
					onClick={handleSubmit}
					textId={Dictionary.commissionsPage.withdrawal.submitBtnText}
				/>
			</div>
		</div>
	);
};

export default Withdrawal;
