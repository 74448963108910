import { useState } from 'react';

export const useWindow = (initialState = false): [boolean, () => void, () => void] => {
	const [windowVisible, setWindowVisible] = useState<boolean>(initialState);

	const showWindow = (): void => setWindowVisible(true);
	const hideWindow = (): void => setWindowVisible(false);

	return [windowVisible, showWindow, hideWindow];
};
