import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import { groupFreeUsers, inviteStatuses } from 'assets/images';
import ShareLink from 'components/share-link';
import GroupsUsersService from 'services/groups-users-service';
import { IdType } from 'types/common';
import { Dictionary } from 'utils/constants/dictionary';
import './GroupInvitationModal.scss';

type Props = {
	groupId: IdType;
	onClose: () => void;
};

const GroupInvitationModal = ({ groupId, onClose }: Props): JSX.Element => {
	const [invitationLink, setInvitationLink] = useState<string | null>(null);

	useEffect(() => {
		if (!invitationLink) {
			GroupsUsersService.getGroupInvitationLink().then(res => {
				setInvitationLink(res.link);
			});
		}
	}, [invitationLink]);

	return (
		<Modal
			className="group-invitation-modal"
			closable={false}
			footer={null}
			onCancel={onClose}
			title={
				<h2>
					<FormattedMessage defaultMessage="Invitation menu" id={Dictionary.groupInvitationModal.title} />
				</h2>
			}
			visible
			width={700}
		>
			<div className="group-invitation-modal__body">
				<div className="card">
					<div className="group-invitation-modal__btns">
						<Link className="btn btn-purple btn-left" to={'/group/free-users'}>
							<img alt="" src={groupFreeUsers} />
							<FormattedMessage
								defaultMessage="Users without a group"
								id={Dictionary.groupInvitationModal.groupFreeUsers}
							/>
						</Link>
						<Link className="btn btn-purple btn-right" to={`/group/${groupId}/invites`}>
							<img alt="" src={inviteStatuses} />
							<FormattedMessage
								defaultMessage="Status of invites"
								id={Dictionary.groupInvitationModal.statusOfInvites}
							/>
						</Link>
					</div>
				</div>

				<div className="card">
					<div className="page-block-title">
						<FormattedMessage
							defaultMessage="Invitation link"
							id={Dictionary.groupInvitationModal.invitationLink}
						/>
					</div>
					<div className="card-body card-body_gray">
						<ShareLink link={invitationLink ?? ''} />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default GroupInvitationModal;

