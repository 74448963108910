import { BaseMessageDto, IdType } from 'types/common';
import { ImageListItem } from 'types/images';
import { USER_IMAGES } from 'utils/constants/end-points';
import Http from './http';

class UserImagesService {
	addUserImage = (imageId: IdType): Promise<ImageListItem> => Http.post(USER_IMAGES, { imageId });

	deleteUserImages = (ids: IdType[]): Promise<BaseMessageDto> => Http.delete(
		USER_IMAGES, { data: { ids } });

	getUserImages = (userId: IdType): Promise<ImageListItem[]> => Http.get(
		`${USER_IMAGES}/all`, { params: { userId } });
}

export default new UserImagesService();
