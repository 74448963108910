import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import TrainingsService from 'services/trainings-service';
import { FetchStatus, ResultState } from 'types/common';
import { TrainingsList } from 'types/trainings';
import { RootState } from './index';

const initialState: ResultState<TrainingsList | null> = {
	data: null,
	status: FetchStatus.IDLE,
	error: null,
};

export const fetchTrainingsFeedAsync = createAsyncThunk(
	'trainings/fetchFeed',
	async () => {
		const trainings: TrainingsList = await TrainingsService.fetchFeed();

		return trainings;
	},
);

export const trainingsFeedSlice = createSlice({
	name: 'trainings/feed',
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder
			// FETCH
			.addCase(fetchTrainingsFeedAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchTrainingsFeedAsync.fulfilled, (state, { payload }) => {
				state.status = FetchStatus.IDLE;
				state.data = payload;
			});
	},
});

const getTrainingsFeed = (state: RootState) => state.trainingsFeed;

export const getTrainingsFeedData = createSelector(getTrainingsFeed, trainings => trainings.data);

export default trainingsFeedSlice.reducer;
