import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { editWhite } from 'assets/images';
import Button from 'components/forms/button';
import PageTitle from 'components/page-title';
import UserInfoPanel from 'components/user-info-panel';
import { getUserId } from 'store/auth';
import { fetchGlossaryAsync } from 'store/glossary';
import { fetchUserDataAsync, getUserInfoData } from 'store/user-info';
import { IdType } from 'types/common';
import { GlossaryEnum } from 'types/glossary';
import { UserProfile } from 'types/user-profile';
import { Dictionary } from 'utils/constants/dictionary';
import { RatingStarsMode } from 'utils/constants/rating';
import { useAppDispatch, useAppSelector, useWindow } from 'utils/hooks';
import ProfileImages from '../profile-images';
import ProfileMenu from '../profile-menu';
import ProfileSalesStatistics from '../profile-sales-statistics';
import './ProfilePage.scss';

type Props = {
	profileId: IdType;
};

type AboutProps = { about: string };

const ProfileAbout = ({ about }: AboutProps): JSX.Element => (
	<div className="card">
		<div className="page-block-title">
			<FormattedMessage defaultMessage="About" id={Dictionary.profilePage.about} />
		</div>
		<div className="card-body">
			{about}
		</div>
	</div>
);

const ProfilePage = ({ profileId }: Props): JSX.Element | null => {
	const dispatch = useAppDispatch();

	const [profileMenu, showProfileMenu, hideProfileMenu] = useWindow();
	const userId: IdType | null = useAppSelector(getUserId);
	const profile: UserProfile | null = useAppSelector(getUserInfoData);

	useEffect(() => {!isNaN(profileId) && dispatch(fetchUserDataAsync(profileId));}, [dispatch, profileId]);

	useEffect(() => {dispatch(fetchGlossaryAsync(GlossaryEnum.COUNTRIES));}, [dispatch]);

	if (!userId || !profile || profile.id !== profileId) return null;

	const isCurrentUserProfile: boolean = profileId === userId;

	return (
		<div className="profile-page">
			{isCurrentUserProfile && <PageTitle defaultText="Profile" textId={Dictionary.profilePage.title} />}

			<div className="profile-group">
				{isCurrentUserProfile && (
					<>
						{/* lg-xl */}
						<Button
							className="btn-edit profile-edit-btn"
							defaultText="Edit"
							imgSrc={editWhite}
							onClick={showProfileMenu}
							textId={Dictionary.common.edit}
						/>

						{/* xs-md */}
						<Button
							className="btn-round profile-edit-btn-round"
							imgSrc={editWhite}
							onClick={showProfileMenu}
						/>
					</>
				)}

				<div className="card">
					<UserInfoPanel
						className="card-body card-body_black"
						profile={profile}
						ratingStarsMode={RatingStarsMode.WHITE}
					/>
				</div>

				{profile?.about && <ProfileAbout about={profile.about} />}

				<div className="card">
					<div className="page-block-title">
						<FormattedMessage
							defaultMessage="Total Enrollments"
							id={Dictionary.common.totalEnrollments.title}
						/>
					</div>
					{<ProfileSalesStatistics userId={profileId} />}
				</div>
			</div>

			<ProfileImages allowEdit={isCurrentUserProfile} profileId={profileId} />

			{profileMenu && <ProfileMenu onClose={hideProfileMenu} />}
		</div>
	);
};

export default ProfilePage;
