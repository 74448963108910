/* eslint-disable max-len */
import { BaseMessageDto, IdType, LinkResponse } from 'types/common';
import { GroupFreeUsersFilter, GroupFreeUsersListItem } from 'types/group-free-users';
import { RankingFilter, RankingWeekQuery, UserRankingItem } from 'types/rating';
import { ConfirmEmailRequest, UserRegistrationData, UserRegistrationResponse } from 'types/user';
import { SetPasswordRequest } from 'types/user-password';
import {
	UpdateProfileRequest, UpdateProfileResponse, UserProfile,
	UserProfileAddress, UserProfilePersonal,
} from 'types/user-profile';
import { USERS } from 'utils/constants/end-points';
import { ChangePasswordFormValues } from 'views/profile/profile-change-password/change-password-form/ChangePasswordForm';
import Http from './http';

class UserService {
	changePhoneNumber = (phoneNumber: string): Promise<BaseMessageDto> => Http.patch(
		`${USERS}/change-phone-number`, { phoneNumber },
	);

	changeUserPassword = (data: ChangePasswordFormValues): Promise<BaseMessageDto> => Http.patch(
		`${USERS}/change-password`, data,
	);

	confirmChangeEmail = (): Promise<BaseMessageDto> => Http.patch(`${USERS}/confirm-change-email`, null);

	confirmNewEmail = (data: ConfirmEmailRequest): Promise<BaseMessageDto> => Http.patch(`${USERS}/confirm-new-email`, data);

	forgotPassword = (email: string): Promise<BaseMessageDto> => Http.patch(`${USERS}/forgot-password`, { email });

	getProfileAddress = (): Promise<UserProfileAddress> => Http.get(`${USERS}/profile/address`);

	getProfilePersonal = (): Promise<UserProfilePersonal> => Http.get(`${USERS}/profile/personal`);

	getRegistrationLink = (): Promise<LinkResponse> => Http.get(
		`${USERS}/registration-link`,
	);

	getReferralLink = (): Promise<{ link: string }> => Http.get(
		`${USERS}/referral-link`,
	);

	getSecuritySettings = (): { email: string; phoneNumber: string } => ({ email:'myTest@mail.com', phoneNumber: '+5511123456789' });

	getUserProfile = (id: IdType): Promise<UserProfile> => Http.get(
		`${USERS}/profile`, { params: { id: id.toString() } },
	);

	getUsersWithoutGroup = (params: GroupFreeUsersFilter): Promise<GroupFreeUsersListItem[]> => Http.get(
		`${USERS}/without-group`, { params });

	getUserRankingTop = (week: RankingWeekQuery): Promise<UserRankingItem[]> => Http.get(
		`${USERS}/rating/top`, { params: { week } },
	);

	getUserRankingAll = (params?: RankingFilter ): Promise<UserRankingItem[]> => Http.get(
		`${USERS}/rating/all`, { params },
	);

	registerUser = (data: UserRegistrationData): Promise<UserRegistrationResponse> => Http.post(`${USERS}/register`, data);

	sendConfirmLink = (email: string): Promise<BaseMessageDto> => Http.patch(
		`${USERS}/send-confirm-link`, { email },
	);

	setEmail = (token: string): Promise<BaseMessageDto> => Http.patch(
		`${USERS}/set-email`, { token }	);

	setPassword = (data: SetPasswordRequest): Promise<BaseMessageDto> => Http.patch(
		`${USERS}/set-password`, data	);

	updateUserProfile = (data: UpdateProfileRequest): Promise<UpdateProfileResponse> => Http.put(
		`${USERS}/profile`, data,
	);

	updateProfileAddress = (data: UserProfileAddress): Promise<UserProfileAddress> => Http.put(
		`${USERS}/profile/address`, data,
	);

	updateProfilePersonal = (data: UserProfilePersonal): Promise<UserProfilePersonal> => Http.put(
		`${USERS}/profile/personal`, data,
	);
}

export default new UserService();
