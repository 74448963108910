import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { Formik, FormikProps, FormikValues } from 'formik';
import { Form, FormItem } from 'formik-antd';
import { E164Number } from 'libphonenumber-js';
import { InputText } from 'components/inputs-formik';
import SelectGlossary from 'components/inputs-formik/SelectGlossary';
import PhoneNumberInput from 'components/phone-number-input';
import { fetchGlossaryAsync, getGlossaryData } from 'store/glossary';
import { IdType } from 'types/common';
import { Country, GlossaryEnum } from 'types/glossary';
import { UserRegistrationData } from 'types/user';
import { BRAZIL_ID } from 'utils/constants/countries';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { useUserRegistrationSchema } from 'utils/validation/schemas';
import './UserRegistrationForm.scss';

type Props = {
	onSubmit: (values: UserRegistrationData) => void;
};

const InnerForm = ({
	dirty, isValid, setFieldValue, setFieldTouched, values,
}: FormikProps<FormikValues>): JSX.Element => {
	const intl = useIntl();
	const countries: Country[] | null = useAppSelector(getGlossaryData<Country[] | null>(GlossaryEnum.COUNTRIES));

	const selectedCountry: Country | undefined = countries?.find(c => c.id === values.countryId);

	const handleChangeCountry = (countryId: IdType | null): void => {
		if (countryId && countryId !== BRAZIL_ID && values.stateId) {
			setFieldValue('stateId', null);
		}
	};

	return (
		<Form className="registration-page__form user-registration">
			<div className="registration-page__form-header">
				<FormattedMessage defaultMessage="Join our team!" id={Dictionary.registrationPage.userForm.title} />
			</div>

			<div className="registration-page__form-body">
				<InputText
					defaultLabel="Full name"
					defaultPlaceholder="Enter your full name"
					labelId={Dictionary.registrationPage.userForm.fullName}
					name="fullName"
					placeholderId={Dictionary.registrationPage.userForm.fullNamePlaceholder}
					required
				/>

				<InputText
					defaultLabel="Email"
					defaultPlaceholder="Enter your email"
					labelId={Dictionary.registrationPage.userForm.email}
					name="email"
					placeholderId={Dictionary.registrationPage.userForm.emailPlaceholder}
					required
				/>

				<FormItem
					colon={false}
					label={intl.formatMessage({
						defaultMessage: 'Phone', id: Dictionary.registrationPage.userForm.phone,
					})}
					name="phoneNumber"
					required
				>
					<PhoneNumberInput
						onBlur={() => {setFieldTouched('phoneNumber', true);}}
						onChange={(value: E164Number | undefined) => {
							setFieldValue('phoneNumber', value);
						}}
						value={values.phoneNumber}
					/>
				</FormItem>

				<Row gutter={24}>
					<Col lg={{ span: 8 }} xs={{ span: 24 }}>
						<SelectGlossary
							defaultLabel="Country"
							defaultPlaceholder="Brasil"
							keyName="name"
							labelId={Dictionary.registrationPage.userForm.country}
							name="countryId"
							onChange={handleChangeCountry}
							placeholderId={Dictionary.registrationPage.userForm.countryPlaceholder}
							required
							selectOptions={countries}
						/>
					</Col>

					{(!values.countryId || values.countryId === BRAZIL_ID) && (
						<Col lg={{ span: 8 }} xs={{ span: 24 }}>
							<SelectGlossary
								defaultLabel="State"
								defaultPlaceholder="Rio de Janeiro"
								keyName="name"
								labelId={Dictionary.registrationPage.userForm.state}
								name="stateId"
								placeholderId={Dictionary.registrationPage.userForm.statePlaceholder}
								renderItem={state => state.code}
								required
								selectOptions={selectedCountry?.states}
							/>
						</Col>
					)}

					<Col lg={{ span: 8 }} xs={{ span: 24 }}>
						<InputText
							defaultLabel="City"
							defaultPlaceholder="City"
							labelId={Dictionary.registrationPage.userForm.city}
							name="city"
							placeholderId={Dictionary.registrationPage.userForm.cityPlaceholder}
							required
						/>
					</Col>
				</Row>
			</div>

			<div className="registration-page__form-footer">
				<button className="user-registration__submit-btn" disabled={!dirty || !isValid} type="submit">
					<FormattedMessage
						defaultMessage="I want to become a Striker"
						id={Dictionary.registrationPage.userForm.submitBtnText}
					/>
				</button>
			</div>
		</Form>
	);
};

const UserRegistrationForm = ({ onSubmit }: Props): JSX.Element => {
	const dispatch = useAppDispatch();

	const [searchParams] = useSearchParams();

	const referCode: string | null = searchParams.get('referCode');

	useEffect(() => {dispatch(fetchGlossaryAsync(GlossaryEnum.COUNTRIES));}, [dispatch]);

	return (
		<Formik<UserRegistrationData>
			component={InnerForm}
			initialValues={{ referCode } as UserRegistrationData}
			onSubmit={onSubmit}
			validationSchema={useUserRegistrationSchema()}
		/>
	);
};

export default UserRegistrationForm;
