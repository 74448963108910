import { getUserId } from 'store/auth';
import { IdType } from 'types/common';
import { useAppSelector } from 'utils/hooks';
import ProfilePage from '../profile-page';

const ProfileGeneral = (): JSX.Element | null => {
	const userId: IdType | null = useAppSelector(getUserId);

	if (!userId) return null;

	return (
		<ProfilePage profileId={userId} />
	);
};

export default ProfileGeneral;
