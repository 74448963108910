import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';

type Props = {
	className?: string;
	defaultText: string;
	disabled?: boolean;
	textId: string;
	imgSrc?: string;
	onClick?: () => void;
};

const ButtonText = ({ className, defaultText, disabled, textId, imgSrc, onClick }: Props): JSX.Element => (
	<Button
		className={className}
		disabled={disabled}
		onClick={onClick}
		type="text"
	>
		{imgSrc && <img alt="" src={imgSrc} />}
		<FormattedMessage defaultMessage={defaultText} id={textId} />
	</Button>
);

export default ButtonText;
