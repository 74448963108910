import RatingStars from 'components/rating/rating-stars';
import StatusLabel from 'components/rating/status-label';
import { AppTheme } from 'types/common';
import { Rating, RatingLevel } from 'types/rating';
import { RatingStarsMode } from 'utils/constants/rating';
import './UserRating.scss';

type Props = Rating & {
	className?: string;
	ratingStarsMode?: RatingStarsMode;
	theme?: AppTheme;
};

const UserRating = (
	{ levelTitle, ratingStarsMode = RatingStarsMode.BLACK, stars, theme = AppTheme.LIGHT }: Props,
): JSX.Element => (
	<div className="user-rating">
		<StatusLabel levelTitle={levelTitle} />

		{levelTitle !== RatingLevel.ULTIMATE_CHALLENGE && (
			<RatingStars
				levelTitle={levelTitle}
				ratingStarsMode={ratingStarsMode}
				stars={stars}
				theme={theme}
			/>
		)}
	</div>
);

export default UserRating;
