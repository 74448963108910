import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import GroupImagesService from 'services/group-images-service';
import { FetchStatus, IdType } from 'types/common';
import { AddGroupImageRequest, DeleteGroupImagesRequest, ImageListItem } from 'types/images';
import { RootState } from './index';

type GroupImagesState = {
	data: ImageListItem[] | null;
	status: FetchStatus;
};

const initialState: GroupImagesState = {
	data: null,
	status: FetchStatus.IDLE,
};

export const fetchGroupImagesAsync = createAsyncThunk(
	'(GET) /group-images/all',
	async (groupId: IdType) => {
		const response = await GroupImagesService.getGroupImages(groupId);

		return response;
	},
);

export const addGroupImageAsync = createAsyncThunk(
	'(POST) /group-images',
	async (payload: AddGroupImageRequest) => {
		const response = await GroupImagesService.addGroupImage(payload);

		return response;
	},
);

export const deleteGroupImagesAsync = createAsyncThunk(
	'(DELETE) /group-images',
	async (payload: DeleteGroupImagesRequest) => {
		const response = await GroupImagesService.deleteGroupImages(payload);

		if (response) return payload.ids;
	},
);

export const groupImagesSlice = createSlice({
	name: 'group-images',
	initialState,
	reducers: {
		hideGroupImages: (state, { payload }: PayloadAction<IdType[]>) => {
			if (state.data) {
				state.data = state.data.map(item => payload.includes(item.id) ? { ...item, isHidden: true } : item);
			}
		},

		showGroupImage: (state, { payload }: PayloadAction<IdType>) => {
			if (state.data) {
				state.data = state.data.map(item => item.id === payload ? { ...item, isHidden: false } : item);
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGroupImagesAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchGroupImagesAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE,
				state.data = action.payload;
			})

			.addCase(addGroupImageAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(addGroupImageAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE,
				state.data = state.data ? [...state.data, action.payload] : [action.payload];
			})

			.addCase(deleteGroupImagesAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(deleteGroupImagesAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE,
				state.data = state.data?.filter(item => !action.payload?.includes(item.id)) || [];
			});
	},
});

const getGroupImages = (state: RootState) => state.groupImages;

export const { hideGroupImages, showGroupImage } = groupImagesSlice.actions;

export const getGroupImagesData = createSelector(getGroupImages, groupImages => groupImages.data);

export default groupImagesSlice.reducer;
