import { RatingLevel } from 'types/rating';
import { Dictionary } from 'utils/constants/dictionary';

export const getTierTextId = (levelTitle: RatingLevel): string => {
	switch (levelTitle) {
		case RatingLevel.GOAL_GETTER_I: return Dictionary.tier.goalGetter_I;
		case RatingLevel.GOAL_GETTER_II: return Dictionary.tier.goalGetter_II;
		case RatingLevel.LEADER_I: return Dictionary.tier.leader_I;
		case RatingLevel.LEADER_II: return Dictionary.tier.leader_II;
		case RatingLevel.TRUE_LEADER_I: return Dictionary.tier.trueLeader_I;
		case RatingLevel.TRUE_LEADER_II: return Dictionary.tier.trueLeader_II;
		case RatingLevel.MASTER: return Dictionary.tier.master;
		case RatingLevel.ULTIMATE_CHALLENGE: return Dictionary.tier.ultimateChallenge;
		default: return 'default-id';
	}
};

export const getTierClassname = (levelTitle: RatingLevel): string => {
	switch (levelTitle) {
		case RatingLevel.GOAL_GETTER_I:
		case RatingLevel.GOAL_GETTER_II: {
			return 'tier_goal-getter';
		}
		case RatingLevel.LEADER_I:
		case RatingLevel.LEADER_II: {
			return 'tier_leader';
		}
		case RatingLevel.TRUE_LEADER_I:
		case RatingLevel.TRUE_LEADER_II: {
			return 'tier_true-leader';
		}
		case RatingLevel.MASTER: return 'tier_master';
		case RatingLevel.ULTIMATE_CHALLENGE: return 'tier_ultimate-challenge';

		default: return '';
	}
};
