import * as Yup from 'yup';
import { Dictionary } from 'utils/constants/dictionary';
import validateCnpj from 'utils/helpers/validate-cnpj';
import validateCpf from 'utils/helpers/validate-cpf';
import validatePhoneNumber from 'utils/helpers/validate-phone-number';
import { useIntlMessage } from 'utils/hooks';
import { REQUIRED, INVALID_EMAIL, PASSWORD_TOO_SHORT, INVALID_PHONE } from 'utils/validation/default-messages';

export const useRequired = (): string => useIntlMessage({
	defaultMessage: REQUIRED, id: Dictionary.validation.required,
});

const PASSWORD_MIN = 6;

export const EmailValidationRule = () => Yup.string()
	.email(useIntlMessage({
		defaultMessage: INVALID_EMAIL, id: Dictionary.validation.invalidEmail,
	}))
	.required(useRequired());

export const PasswordValidationRule = () => Yup.string()
	.required(useRequired())
	.min(PASSWORD_MIN, useIntlMessage({
		defaultMessage: PASSWORD_TOO_SHORT,
		id: Dictionary.validation.passwordTooShort,
	}));

export const PhoneNumberValidationRule = () => Yup.string()
	.required(useRequired())
	.nullable()
	.test(
		'check-valid-number',
		useIntlMessage({ defaultMessage: INVALID_PHONE, id: Dictionary.validation.invalidPhone }),
		value => value ? validatePhoneNumber(value) : true,
	);

export const CnpjValidationRule = () => Yup.string()
	.nullable()
	.test(
		'check-valid-cnpj',
		useIntlMessage({ defaultMessage: 'Invalid CNPJ value', id: Dictionary.validation.invalidCnpj }),
		value => value ? validateCnpj(value) : true,
	);

export const CpfValidationRule = () => Yup.string()
	.nullable()
	.test(
		'check-valid-cpf',
		useIntlMessage({ defaultMessage: 'Invalid CPF value', id: Dictionary.validation.invalidCpf }),
		value => value ? validateCpf(value) : true,
	);

export const AccountValidationRule = () => Yup.string()
	.nullable()
	.test(
		'check-valid-account',
		useIntlMessage({
			defaultMessage: 'Value must be in format {format}',
			id: Dictionary.validation.valueFormat,
		}, { format: '\'00000-0\'' }),
		value => value ? new RegExp('[0-9]{5}-[0-9]', 'g').test(value) : true,
	);

export const AgencyValidationRule = () => Yup.string()
	.nullable()
	.test(
		'check-valid-agency',
		useIntlMessage({
			defaultMessage: 'Value must be in format {format}',
			id: Dictionary.validation.valueFormat,
		}, { format: '\'0000\'' }),
		value => value ? value.length === 4 && !isNaN(+value) : true,
	);

