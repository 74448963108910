import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Dropdown } from 'antd';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { arrowDownPurple } from 'assets/images';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './MultipleSelect.scss';

export type MultipleSelectItem<T> = {
	filterKey: keyof T;
	className?: string;
	defaultMessage: string;
	labelId: string;
};

type Props<T> = {
	className?: string;
	defaultMessage: string;
	labelId: string;
	filterSelector: (state: RootState) => T;
	setFilterAction: ActionCreatorWithPayload<T, string>;
	buttons: MultipleSelectItem<T>[];
};

const MultipleSelect = <T,>({
	className, buttons, labelId, defaultMessage, filterSelector, setFilterAction,
}: Props<T>): JSX.Element => {
	const dispatch = useAppDispatch();
	const [visible, setVisible] = useState<boolean>(false);

	const filter: T = useAppSelector(filterSelector);

	const handleClick = (key: keyof T) => {
		dispatch(setFilterAction({ ...filter, [key]: !filter[key] }));
	};

	const handleVisibleChange = (visible: boolean) => {
		setVisible(visible);
	};

	const DropdownMenu = (): JSX.Element => (
		<div className="multiple-select__dropdown">
			<div className="multiple-select-btns-wrap">
				{buttons.map(btn => {
					const highlighted = !!filter[btn.filterKey];

					return (
						// <div className="multiple-select__item-wrap" key={btn.labelId}>
						<div
							className={`multiple-select__btn-wrap ${highlighted
								? 'multiple-select__btn-wrap_highlighted'
								: ''}`} key={btn.labelId}
						>
							<div
								className={`multiple-select__btn ${btn.className || ''}`}
								onClick={() => handleClick(btn.filterKey)}
							>
								<FormattedMessage defaultMessage={btn.defaultMessage} id={btn.labelId} />
							</div>
						</div>
						// </div>
					);
				})}
			</div>
		</div>
	);

	return (
		<Dropdown
			onVisibleChange={handleVisibleChange}
			overlay={DropdownMenu}
			overlayClassName={className}
			trigger={['click']}
			visible={visible}
		>
			<Button className="multiple-select__trigger-btn">
				<div className="multiple-select__trigger">
					{ defaultMessage && labelId && (
						<FormattedMessage defaultMessage={defaultMessage} id={labelId} />
					)}
					<img alt="" src={arrowDownPurple} />
				</div>
			</Button>
		</Dropdown>
	);
};

export default MultipleSelect;
