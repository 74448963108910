import { union } from 'assets/images';
import { FormattedMessage } from 'react-intl';
import { Dictionary } from 'utils/constants/dictionary';
import './GroupRatingCard.scss';

type Props = {
	position: number;
};

const getClassname = (position: number): string => {
	if (position === 1) return 'group-rating-card_top-1';
	if (position === 2) return 'group-rating-card_top-2';
	if (position === 3) return 'group-rating-card_top-3';

	return 'group-rating-card_top-4-10';
};

const GroupRatingCard = ({ position }: Props): JSX.Element | null => {
	if (position > 10) return null;

	return (
		<div className={`group-rating-card ${getClassname(position)}`}>
			<div className="group-rating-card__left">
				<img alt="union" src={union} />
			</div>
			<div className="group-rating-card__right">
				<div className="group-rating-card__data">
					<div className="group-rating-card__data_top">
						{`TOP ${position}`}
					</div>
					<div className="group-rating-card__data_subtext">
						<FormattedMessage defaultMessage="Group of this week" id={Dictionary.groupPage.info.rating} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default GroupRatingCard;
