type DownloadFileProps = {
	readonly apiDefinition: () => Promise<Blob>;
	readonly preDownloading?: () => void;
	readonly postDownloading?: () => void;
	readonly onError?: () => void;
	readonly getFileName: () => string;
};

export const useDownloadFile = ({
	apiDefinition,
	preDownloading,
	postDownloading,
	onError,
	getFileName,
}: DownloadFileProps): () => Promise<void> => {
	const download = async () => {
		try {
			preDownloading && preDownloading();
			const data: Blob = await apiDefinition();
			const url = URL.createObjectURL(new Blob([data]));

			const element = document.createElement('a');

			element.href = url;
			element.download = getFileName();
			document.body.appendChild(element);
			element.click();
			postDownloading && postDownloading();

			document.body.removeChild(element);
			URL.revokeObjectURL(url);
		} catch (error) {
			onError && onError();
		}
	};

	return download;
};
