import ShareLink from 'components/share-link';
import { useEffect } from 'react';
import { fetchUserReferralLinkAsync, getUsersReferralLinkData } from 'store/user-referral-link';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './UserReferralLinkMobile.scss';

const UserReferralLinkMobile = (): JSX.Element | null => {
	const dispatch = useAppDispatch();

	const referralLink: string | null = useAppSelector(getUsersReferralLinkData);

	useEffect(() => {
		dispatch(fetchUserReferralLinkAsync());
	}, [dispatch]);

	if (!referralLink) return null;

	return (
		<div className="card user-referral-link-mobile">
			<ShareLink link={referralLink} />
		</div>
	);
};

export default UserReferralLinkMobile;
