import { FormattedMessage } from 'react-intl';
import Avatar from 'components/avatar';
import ButtonEdit from 'components/forms/button-edit';
import ButtonEditRound from 'components/forms/button-edit-round';
import ButtonReport from 'components/forms/button-report';
import ButtonReportRound from 'components/forms/button-report-round';
import GroupRatingCard from 'components/rating/group-rating-card';
import ReportModal from 'components/report-modal';
import { ImageContainer } from 'types/common';
import { Group } from 'types/group';
import { Dictionary } from 'utils/constants/dictionary';
import { useWindow } from 'utils/hooks';
import './GroupInfoPanel.scss';

type Props = {
	className?: string;
	editable?: boolean;
	group: Group;
	onClickEdit?: () => void;
};

const GroupInfoPanel = ({ className, editable = false, group, onClickEdit }: Props): JSX.Element => {
	const [reportModal, showReportModal, hideReportModal] = useWindow();

	const amount: number = group.memberAmount;

	return (
		<div className={`group-info-panel ${className || ''}`}>
			<div className="group-info-panel__left">
				<Avatar
					alt="group-avatar"
					className="group-info-panel__avatar"
					containerName={ImageContainer.GROUP_AVATAR}
					imageId={group.avatarImageId}
				/>

				<div className="group-info-panel__group-data">
					<h2>
						{group.name}
					</h2>
					<h3>
						<FormattedMessage id={Dictionary.groupPage.membersAmount} values={{ amount }} />
					</h3>
				</div>
			</div>

			<div className="group-info-panel__right">
				{group.ratingNumber && <GroupRatingCard position={group.ratingNumber} />}

			</div>

			{/* lg-xl */}
			{editable && onClickEdit && <ButtonEdit onClick={onClickEdit} />}

			{!editable && group.avatarImageId && (
				<ButtonReport onClick={showReportModal} />
			)}

			{/* xs-md */}
			{editable && onClickEdit && <ButtonEditRound onClick={onClickEdit} />}

			{!editable && group.avatarImageId && (
				<ButtonReportRound onClick={showReportModal} />
			)}

			{reportModal && group.avatarImageId && (
				<ReportModal groupId={group.id} onClose={hideReportModal} onFulfilled={hideReportModal} />
			)}
		</div>
	);
};

export default GroupInfoPanel;
