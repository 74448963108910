import { Routes, Route } from 'react-router-dom';
import Commissions from 'views/commissions';
import NotFound from 'views/not-found';

const CommissionRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<Commissions />} index />
		<Route element={<NotFound />} path="*" />
	</Routes>
);

export default CommissionRoutes;
