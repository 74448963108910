import { TrainingCategoriesList } from 'types/trainings';
import { TRAINING_CATEGORIES } from 'utils/constants/end-points';
import Http from './http';

class TrainingsService {
	fetchAll = (): Promise<TrainingCategoriesList> => Http.get<TrainingCategoriesList>(
		TRAINING_CATEGORIES + '/all/user',
	);
}

export default new TrainingsService();
