import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import SalesService from 'services/sales-service';
import { FetchStatus, IdType, ResultState } from 'types/common';
import { SalesStatisticsData } from 'types/sales';
import { RootState } from './index';

const initialState: ResultState<SalesStatisticsData | null> = {
	data: null,
	status: FetchStatus.IDLE,
	error: null,
};

export const fetchUserStatisticsAsync = createAsyncThunk(
	'sales/user-statistics',
	async (userId: IdType) => {
		const response: SalesStatisticsData = await SalesService.getUserStatistics(userId);

		return response;
	},
);

export const userStatisticsSlice = createSlice({
	name: 'userStatistics',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserStatisticsAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchUserStatisticsAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			});
	},
});

const getUserStatistics = (state: RootState) => state.userStatistics;

export const getUserStatisticsData = createSelector(getUserStatistics, userStatistics => userStatistics.data);

export default userStatisticsSlice.reducer;
