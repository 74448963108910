import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import UserService from 'services/user-service';
import { FetchStatus, ResultState } from 'types/common';
import { RootState } from './index';

const initialState: ResultState<string | null> = {
	data: null,
	status: FetchStatus.IDLE,
	error: null,
};

export const fetchUserRegistrationLinkAsync = createAsyncThunk(
	'users/registration-link',
	async () => {
		const response = await UserService.getRegistrationLink();

		return response.link;
	},
);

export const userRegistrationLinkSlice = createSlice({
	name: 'userRegistrationLink',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserRegistrationLinkAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchUserRegistrationLinkAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			});
	},
});

const getUserRegistrationLink = (state: RootState) => state.userRegistrationLink;

export const getUserRegistrationLinkData = createSelector(getUserRegistrationLink, link => link.data);

export default userRegistrationLinkSlice.reducer;
