import { BaseEntity } from './common';

export enum InvoiceStatus {
	ACCEPTED = 'Accepted',
	ANALYSIS = 'Analysis',
	REJECTED = 'Rejected',
}

export type InvoiceListItem = BaseEntity & {
	status: InvoiceStatus;
	comment: string;
	actualRequestBalance: number;
	currentBalance: number;
};
