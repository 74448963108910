import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import SalesService from 'services/sales-service';
import { FetchStatus, IdType, ResultState } from 'types/common';
import { SalesStatisticsData } from 'types/sales';
import { RootState } from './index';

const initialState: ResultState<SalesStatisticsData | null> = {
	data: null,
	status: FetchStatus.IDLE,
	error: null,
};

export const fetchGroupStatisticsAsync = createAsyncThunk(
	'sales/group-statistics',
	async (groupId: IdType) => {
		const response: SalesStatisticsData = await SalesService.getGroupStatistics(groupId);

		return response;
	},
);

export const groupStatisticsSlice = createSlice({
	name: 'groupStatistics',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGroupStatisticsAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchGroupStatisticsAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			});
	},
});

const getGroupStatistics = (state: RootState) => state.groupStatistics;

export const getGroupStatisticsData = createSelector(getGroupStatistics, groupStatistics => groupStatistics.data);

export default groupStatisticsSlice.reducer;
