import { useIntl } from 'react-intl';
import { useField } from 'formik';
import { FormItem, DatePickerProps, DatePicker as AntDatePicker } from 'formik-antd';
import { Dayjs } from 'dayjs';
import { Moment } from 'moment';
import { DD_MM_YYYY } from 'utils/constants/date-time';
import './DatePicker.scss';

type LimitDates = {
	from?: Dayjs;
	to?: Dayjs;
};

type Props = {
	className?: string;
	defaultLabel?: string;
	defaultPlaceholder?: string;
	fixedTime?: boolean;
	labelId?: string;
	limitDates?: LimitDates;
	name: string;
	placeholderId?: string;
	required?: boolean;
};

const setDatePickerRange = (payload: {
	current: moment.Moment | null;
	from?: Dayjs;
	to?: Dayjs;
}): boolean => {
	const { current, from, to } = payload;

	return (current && from && current < from.startOf('day')) || (current && to && current > to.endOf('day')) || false;
};

const DatePicker = (props: DatePickerProps & Props): JSX.Element => {
	const {
		className,
		defaultLabel,
		defaultPlaceholder,
		disabled,
		fixedTime = true,
		format = DD_MM_YYYY,
		labelId,
		limitDates,
		name,
		onChange,
		placeholderId,
		required,
		suffixIcon,
		...restProps
	} = props;

	const intl = useIntl();

	const [, , actions] = useField<string | undefined>(name);

	const handleChange = (date: Moment | null, dateString: string): void => {
		// If fixedTime, we send date value as ISO string where time is 12:00:00.000
		fixedTime && actions.setValue(date
			? date
				.set({
					hours: 12, minutes: 0, seconds: 0, milliseconds: 0,
				})
				.utc(true)
				.toISOString()
			: undefined,
		);

		onChange && onChange(date, dateString);
	};

	return (
		<FormItem
			colon={false}
			htmlFor={name}
			label={defaultLabel && labelId &&
        intl.formatMessage({ defaultMessage: defaultLabel, id: labelId })} name={name}
			required={required}
		>
			<AntDatePicker
				{...restProps}
				{...{ disabled, format, name }}
				className={`form-date-picker ${className || ''}`}
				disabledDate={
					(current: Moment | null) => setDatePickerRange(
						{ current, from: limitDates?.from, to: limitDates?.to },
					)
				}
				id={name}
				onChange={handleChange}
				placeholder={defaultPlaceholder && placeholderId &&
          intl.formatMessage({ defaultMessage: defaultPlaceholder, id: placeholderId })}
				suffixIcon={suffixIcon}
			/>
		</FormItem>
	);
};

export default DatePicker;

