import PhoneInput, { DefaultInputComponentProps } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './PhoneNumberInput.scss';

type Props = DefaultInputComponentProps;

const PhoneNumberInput = ({ onChange, ...restProps }: Props): JSX.Element | null => (
	<div className="phone-number-input">
		<PhoneInput
			{...restProps}
			international
			onChange={onChange}
		/>
	</div>
);

export default PhoneNumberInput;
