import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import TrainingCategoriesService from 'services/training-categories-service';
import { FetchStatus, ResultState } from 'types/common';
import { TrainingCategoriesList } from 'types/trainings';
import { RootState } from './index';

const initialState: ResultState<TrainingCategoriesList | null> = {
	data: null,
	status: FetchStatus.IDLE,
	error: null,
};

export const fetchTrainingCategoriesAsync = createAsyncThunk(
	'training-categories/fetchAll',
	async () => {
		const categories: TrainingCategoriesList = await TrainingCategoriesService.fetchAll();

		return categories;
	},
);

export const trainingCategoriesSlice = createSlice({
	name: 'training-categories',
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder
			// FETCH
			.addCase(fetchTrainingCategoriesAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchTrainingCategoriesAsync.fulfilled, (state, { payload }) => {
				state.status = FetchStatus.IDLE;
				state.data = payload;
			});
	},
});

const getTrainingCategories = (state: RootState) => state.trainingCategories;

export const getTrainingCategoriesData = createSelector(getTrainingCategories, cat => cat.data);
export const isTrainingCategoriesLoading = createSelector(
	getTrainingCategories, cat => cat.status === FetchStatus.LOADING,
);

export default trainingCategoriesSlice.reducer;
