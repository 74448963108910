import { FormattedMessage, IntlProvider } from 'react-intl';
import { message as antdMessage } from 'antd';
import { MessageType } from 'antd/lib/message';
import { nanoid } from 'nanoid';
import { successGreen, errorRed, cross } from 'assets/images';
import English from '../../lang/en.json';
import './Message.scss';

antdMessage.config({
	duration: 3,
	maxCount: 10,
	top: 10,
});

type MessageProps = {
	messageId?: string;
	messageText?: string;
	submessageId?: string;
	submessageText?: string;
	titleId?: string;
	titleText?: string;
};

type MessageTemplateProps = MessageProps & {
	idKey: string;
};

const className = 'message';

const MessageTemplate = ({
	idKey,
	messageId,
	messageText,
	submessageId,
	submessageText,
	titleId,
	titleText,
}: MessageTemplateProps): JSX.Element => (
	<IntlProvider locale="en" messages={English}>
		<div>
			<div className="message__title">
				{titleId
					? <FormattedMessage defaultMessage={titleText} id={titleId} />
					: titleText
				}
			</div>
			<div className="message__text ">
				{messageId
					? <FormattedMessage defaultMessage={messageText} id={messageId} />
					: messageText
				}
			</div>
			<div className="message__submessage">
				{submessageId
					? <FormattedMessage defaultMessage={submessageText} id={submessageId} />
					: submessageText
				}
			</div>
			<div className="message__close_btn" onClick={() => antdMessage.destroy(idKey)}>
				<img alt="" src={cross} />
			</div>
		</div>
	</IntlProvider>
)
;

export default class Message {
	public static config(configData = {}): void {
		antdMessage.config(configData);
	}

	public static success(data: MessageProps): MessageType {
		const key = nanoid();

		return antdMessage.success({
			content: (
				<MessageTemplate idKey={key} {...data} />
			),
			className,
			icon: <img alt="" src={successGreen} />,
			key,
		});
	}

	public static info(data: MessageProps): MessageType {
		const key = nanoid();

		return antdMessage.info({
			content: (
				<MessageTemplate idKey={key} {...data} />
			),
			className,
			icon: <></>,
			key,
		});
	}

	public static warn(data: MessageProps): MessageType {
		const key = nanoid();

		console.log(data);

		return antdMessage.warn({
			content: (
				<MessageTemplate idKey={key} {... data} />
			),
			className,
			icon: <></>,
			key,
		});
	}

	public static error(data: MessageProps): MessageType {
		const key = nanoid();

		return antdMessage.error({
			content: (
				<MessageTemplate idKey={key} {...data} />
			),
			className,
			icon: <img alt="" src={errorRed} />,
			key,
		});
	}
}
