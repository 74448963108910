import { Sorting } from 'types/common';
import { GroupFreeUsersFilter } from 'types/group-free-users';
import { GroupInvitesFilter } from 'types/group-invites';
import { GroupMembersFilter, GroupMembersOrderBy } from 'types/group-members';
import { LeadsFilter, LeadsOrderBy } from 'types/leads';
import { SalesFilter, SalesListOrderBy } from 'types/sales';

export const GROUP_FREE_USERS_FILTER_DEFAULT: GroupFreeUsersFilter = {
	goalGetterI: true,
	goalGetterII: true,
	leaderI: true,
	leaderII: true,
	trueLeaderI: true,
	trueLeaderII: true,
	master: true,
	ultimateChallenge: true,
	search: '',
};

export const GROUP_INVITES_FILTER_DEFAULT: GroupInvitesFilter = {
	acceptedStatus: true,
	rejectedStatus: true,
	sentStatus: true,
	search: '',
};

export const GROUP_MEMBERS_FILTER_DEFAULT: GroupMembersFilter = {
	orderBy: GroupMembersOrderBy.CREATED,
	search: '',
	sorting: Sorting.DESC,
};

export const LEADS_FILTER_DEFAULT: LeadsFilter = {
	contactedStatus: true,
	inNegotiationsStatus: true,
	linkSentStatus: true,
	lostStatus: true,
	newLeadStatus: true,
	orderBy: LeadsOrderBy.CREATED,
	postponedStatus: true,
	search: '',
	soldStatus: true,
	sorting: Sorting.DESC,
};

export const SALES_FILTER_DEFAULT: SalesFilter = {
	orderBy: SalesListOrderBy.PURCHASE_DATE,
	sorting: Sorting.DESC,
};
