import { checkWhite } from 'assets/images';
import Button from '../button';

type Props = {
	className?: string;
};

const ButtonSaveRound = ({ className }: Props): JSX.Element => (
	<Button
		className={`btn-round btn-round_top-right btn-round-save ${className || ''}`}
		imgSrc={checkWhite}
		type="submit"
	/>
);

export default ButtonSaveRound;
