const ROUTES = {
	AUTH: {
		BASE: 'auth',
		FORGOT_PASSWORD: 'forgot-password',
		NEW_PASSWORD: 'new-password',
		RESET_PASSWORD: 'reset-password',
	},
	COMMISSIONS: {
		BASE: 'commissions',
	},
	COURSES: {
		BASE: 'courses',
	},
	GROUP: {
		BASE: 'group',
		ITEM: ':id',
		INVITES: ':id/invites',
		JOIN: 'join',
		MEMBERS: ':id/members',
		FREE_USERS: 'free-users',
	},
	LEADS: {
		BASE: 'leads',
	},
	MEDIAS: {
		BASE: 'medias',
		ITEM: ':id',
	},
	PROFILE: {
		BASE: 'profile',
		CHANGE_EMAIL: 'change-email',
		CHANGE_PASSWORD: 'change-password',
		INVITATION: 'invitation',
		ITEM: ':id',
		LEGAL_DOCUMENTS: 'legal-documents',
		PERSONAL_DATA: 'personal-data',
		SECURITY_SETTINGS: 'security-settings',
		SET_EMAIL: 'set-email',
	},
	RANKING: {
		BASE: 'ranking',
	},
	REGISTRATION: {
		BASE: 'registration',
	},
	SALES: {
		BASE: 'sales',
	},
	TRAINING: {
		BASE: 'trainings',
		ITEM: ':id',
	},
};

export default ROUTES;
