import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import Chart from 'components/chart';
import SwitchPrevNext from 'components/switch-prev-next';
import UserRating from 'components/rating/user-rating';
import { fetchSalesHistoryAsync, getSalesHistoryData } from 'store/sales-history';
import { fetchSalesUserLevelAsync, getSalesUserLevelData } from 'store/sales-user-level';
import { RatingLevel } from 'types/rating';
import { DD_MM } from 'utils/constants/date-time';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './UserSales.scss';

const UserSales = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const salesHistory = useAppSelector(getSalesHistoryData);
	const userLevel = useAppSelector(getSalesUserLevelData);

	const chartData = useMemo(
		() => salesHistory?.history.map((h) => h.numberOfSales) || [],
		[salesHistory],
	);
	const chartLabels = useMemo(
		() => salesHistory?.history.map((h) => dayjs(h.date).format(DD_MM)) || [],
		[salesHistory],
	);
	const weekSales = salesHistory?.total || 0;
	//const salesAmount = userLevel?.salesToNextLevel;
	const isLevelUltimate = userLevel?.levelTitle === RatingLevel.ULTIMATE_CHALLENGE;

	const today = dayjs().utc()
		.set('hours', 12)
		.set('minutes', 0)
		.set('seconds', 0)
		.set('milliseconds', 0);

	const [weekDay, setWeekDay] = useState<Dayjs>(today);

	const weekStart = useMemo(() => weekDay.startOf('week')
		.add(1, 'day'), [weekDay]);
	const weekEnd = useMemo(() => weekDay.endOf('week')
		.add(1, 'day'), [weekDay]);

	const setWeekAfter = (): void => {
		setWeekDay(weekDay.add(1, 'week'));
	};

	const setWeekBefore = (): void => {
		setWeekDay(weekDay.subtract(1, 'week'));
	};

	useEffect(() => {
		dispatch(fetchSalesHistoryAsync({ startDate: weekStart.toISOString(), endDate: weekEnd.toISOString() }));
	}, [dispatch, weekEnd, weekStart]);

	useEffect(() => {
		dispatch(fetchSalesUserLevelAsync());
	}, [dispatch]);

	const SwitchText = (): JSX.Element => (
		<div>
			{`${weekStart.format(DD_MM)} - ${weekEnd.format(DD_MM)}`}
		</div>
	);

	return (
		<div className="card-body user-sales">
			{!isLevelUltimate && userLevel &&
				<div className="user-sales__top">
					<UserRating levelTitle={userLevel.levelTitle} stars={userLevel.stars} />

					{/* <div className="user-sales__top-text">
						{typeof salesAmount === 'number' &&
							<FormattedMessage
								defaultMessage={`${salesAmount} left for next level`}
								id={Dictionary.homePage.salesStatistics.salesToNextLevel}
								values={{ salesAmount }}
							/>
						}
					</div> */}
				</div>
			}

			{!isLevelUltimate && <hr />}

			<div className="user-sales__chart">
				<div className="user-sales__chart-top">
					<div className="user-sales__chart-top-title">
						<FormattedMessage
							defaultMessage={`${weekSales} sales`}
							id={Dictionary.homePage.salesStatistics.weekSales}
							values={{ weekSales }}
						/>
					</div>
					<SwitchPrevNext
						nextDisabled={weekStart.isSame(today.startOf('week').add(1, 'day'))}
						onClickNext={setWeekAfter}
						onClickPrev={setWeekBefore}
						text={<SwitchText />}
					/>
				</div>
				<Chart data={chartData} labels={chartLabels} />
			</div>
		</div>
	);
};

export default UserSales;
