/* eslint-disable max-len */
import { BaseMessageDto } from 'types/common';
import { BankAccountData, BankAccountResponse, CreateBankAccountDto } from 'types/payment-data';
import { BANK_ACCOUNTS } from 'utils/constants/end-points';
import Http from './http';

class BankAccountService {
	createBankAccountData = (data: CreateBankAccountDto): Promise<BankAccountResponse> => Http.post(BANK_ACCOUNTS, data);

	getBankAccountData = (): Promise<BankAccountResponse> => Http.get(BANK_ACCOUNTS);

	updateBankAccountData = (data: BankAccountData): Promise<BankAccountResponse> => Http.put(BANK_ACCOUNTS, data);

	removeBankAccountData = (): Promise<BaseMessageDto> => Http.delete(BANK_ACCOUNTS);
}

export default new BankAccountService();
