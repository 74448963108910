import MediasList from './list';
import './Medias.scss';

const Medias = (): JSX.Element => (
	<div className="Medias">
		<MediasList />
	</div>
);

export default Medias;

