import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataGrid from 'components/data-grid';
import VideoListItem from 'components/video/video-list-item';
import { fetchMediasFeedAsync, getMediasFeedData } from 'store/medias-feed';
import { Media } from 'types/medias';
import { Dictionary } from 'utils/constants/dictionary';
import ROUTES from 'utils/constants/routes';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './MediasFeed.scss';

const MediasFeed = (): JSX.Element | null => {
	const dispatch = useAppDispatch();
	const mediasFeed: Media[] | null = useAppSelector(getMediasFeedData);

	useEffect(() => {
		dispatch(fetchMediasFeedAsync());
	}, [dispatch]);

	if (!mediasFeed?.length) return null;

	return (
		<div className="home__feed medias-feed">
			<DataGrid<Media>
				defaultTitle="Medias"
				items={mediasFeed}
				renderItem={media => (
					<Link to={`/${ROUTES.MEDIAS.BASE}/${String(media.id)}`}>
						<VideoListItem
							previewImageId={media.previewImageId}
							title={media.title}
						/>
					</Link>
				)}
				titleId={Dictionary.homePage.medias}
			/>
		</div>
	);
};

export default MediasFeed;

