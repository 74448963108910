import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import UserService from 'services/user-service';
import { RootState } from 'store';
import { FetchStatus, IdType } from 'types/common';
import { UserProfile } from 'types/user-profile';

type UserInfoState = {
	data: UserProfile | null;
	status: FetchStatus;
};

const initialState: UserInfoState = {
	data: null,
	status: FetchStatus.IDLE,
};

export const fetchUserDataAsync = createAsyncThunk(
	'(GET) /users',
	async (id: IdType) => {
		const response: UserProfile = await UserService.getUserProfile(id);

		return response;
	},
);

export const userInfoSlice = createSlice({
	name: 'user-info',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserDataAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchUserDataAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			});
	},
});

const getUserInfo = (state: RootState) => state.userInfo;

export const getUserInfoData = createSelector(getUserInfo, userInfo => userInfo.data);

export default userInfoSlice.reducer;
