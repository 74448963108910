import { IdType, Sorting } from './common';
import { Sales } from './rating';
import { UserInfo } from './user';

export enum GroupMembersOrderBy {
	ALPHABET = 'alphabet',
	CREATED = 'created',
}

export interface GroupMembersListItem extends UserInfo, Sales {}

export type FetchGroupMembersRequest = {
	groupId: IdType;
	filter: GroupMembersFilter;
};

export type GroupMembersFilter = {
	orderBy: GroupMembersOrderBy;
	search: string;
	sorting: Sorting;
};

