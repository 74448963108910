import { useState } from 'react';
import { eyeActive, eyeNonActive } from 'assets/images';
import { getUserBalanceData } from 'store/user-balance';
import { UserBalance as UserBalanceType } from 'types/transactions';
import convertBalance from 'utils/helpers/convert-balance';
import { useAppSelector } from 'utils/hooks';
import './UserBalance.scss';

const DEFAULT_CURRENCY = 'BRL';
const PLACEHOLDER = '**********';

const UserBalance = (): JSX.Element | null => {
	const [hidden, setHidden] = useState<boolean>(false);

	const userBalance: UserBalanceType | null = useAppSelector(getUserBalanceData);

	const toggleView = (): void => {
		setHidden(!hidden);
	};

	return (
		<div className="user-balance">
			<div className={`user-balance__data ${hidden ? 'user-balance__data_hidden' : ''}`}>
				{hidden
					? PLACEHOLDER
					: `${userBalance?.currency ?? DEFAULT_CURRENCY} ${convertBalance(userBalance?.amount ?? 0)}`}
			</div>
			<div className="user-balance__view">
				<img alt="" onClick={toggleView} src={hidden ? eyeNonActive : eyeActive} />
			</div>
		</div>
	);
};

export default UserBalance;
