import { useEffect, useState, MouseEvent } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import Table, { ColumnsType } from 'antd/lib/table';
import DownloadService from 'services/download-service';
import InvoiceService from 'services/invoice-service';
import { getUserId } from 'store/auth';
import { InvoiceListItem, InvoiceStatus } from 'types/commissions';
import { IdType } from 'types/common';
import { Dictionary } from 'utils/constants/dictionary';
import { formatDate } from 'utils/helpers/date-time';
import { useAppSelector } from 'utils/hooks';
import { useDownloadFile } from 'utils/hooks/use-download-file';
import './InvoicesHistory.scss';

const getMsgDescriptor = (status: InvoiceStatus): MessageDescriptor => {
	switch (status) {
		case InvoiceStatus.ACCEPTED: {
			return { defaultMessage: 'Accepted', id: Dictionary.common.invoiceStatus.accepted };
		}
		case InvoiceStatus.ANALYSIS: {
			return { defaultMessage: 'Analysis', id: Dictionary.common.invoiceStatus.analysis };
		}
		case InvoiceStatus.REJECTED: {
			return { defaultMessage: 'Rejected', id: Dictionary.common.invoiceStatus.rejected };
		}
		default: return { defaultMessage: 'Unknown', id: 'default-id' };
	}
};

const INVOICE_DATE_FORMAT = 'DD.MM.YYYY HH:mm:ss';

const DownloadLink = (invoice: InvoiceListItem): JSX.Element => {
	const downloadInvoice = useDownloadFile({
		apiDefinition: () => DownloadService.downloadInvoice(invoice.id),
		getFileName: () => `invoice_${formatDate(invoice.updatedAt, INVOICE_DATE_FORMAT).replace(' ', '__')}.pdf`,
	});

	const handleClick = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
		e.preventDefault();
		downloadInvoice();
	};

	return (
		<a href="" onClick={handleClick}>
			<FormattedMessage
				defaultMessage="Click to view"
				id={Dictionary.commissionsPage.history.invoiceLinkText}
			/>
		</a>
	);
};

const InvoicesHistory = (): JSX.Element | null => {
	const [history, setHistory] = useState<InvoiceListItem[] | null>(null);
	const userId: IdType | null = useAppSelector(getUserId);

	useEffect(() => {
		if (userId) {
			InvoiceService.getUserInvoices(userId).then(res => res && setHistory(res));
		}
	}, [userId]);

	useEffect(() => () => {
		setHistory(null);
	}, []);

	if (!history || !userId) return null;

	const columns: ColumnsType<InvoiceListItem> = [
		{
			title: () => (
				<FormattedMessage defaultMessage="Date" id={Dictionary.commissionsPage.history.tableColumns.date} />
			),
			dataIndex: 'updatedAt',
			width: 200,
			key: 'updatedAt',
			render: (date: string) => (
				<div>
					{formatDate(date, INVOICE_DATE_FORMAT)}
				</div>
			),
		},
		{
			title: () => (
				<FormattedMessage
					defaultMessage="Invoice"
					id={Dictionary.commissionsPage.history.tableColumns.invoice}
				/>
			),
			dataIndex: 'fileId',
			key: 'fileId', width: 200,
			render: (_, record) => (
				<div>
					{DownloadLink(record)}
				</div>
			),
		},
		{
			title: () => (
				<FormattedMessage defaultMessage="Status" id={Dictionary.commissionsPage.history.tableColumns.status} />
			),
			dataIndex: 'status',
			key: 'status', width: 200,
			render: (status: InvoiceStatus) => (
				<div>
					<FormattedMessage {...getMsgDescriptor(status)} />
				</div>
			),
		},
		{
			title: () => (
				<FormattedMessage
					defaultMessage="Comment"
					id={Dictionary.commissionsPage.history.tableColumns.comment}
				/>
			),
			dataIndex: 'comment',
			key: 'comment', width: 400,
		},
	];

	return (
		<div className="commissions-tab__container invoices-history">
			<Table columns={columns} dataSource={history} pagination={false} rowKey="id" />
		</div>
	);
};

export default InvoicesHistory;
