import { useState } from 'react';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

const useUploadFile = (): [File | null, ({ file }: UploadChangeParam<UploadFile>) => void] => {
	const [file, setFile] = useState<File | null>(null);

	const onUpload = ({ file }: UploadChangeParam<UploadFile>): void => {
		if (file.status === 'removed') {
			setFile(null);
			return;
		}

		setFile(file as unknown as File);
	};

	return [file, onUpload];
};

export default useUploadFile;
