import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { appLogoWhite, registrationFormLogo } from 'assets/images';
import Message from 'components/message';
import UserService from 'services/user-service';
import { UserRegistrationData } from 'types/user';
import { Dictionary } from 'utils/constants/dictionary';
import { getLoggedIn } from 'store/auth';
import { useAppSelector } from 'utils/hooks';
import UserRegistrationForm from './user-registration-form';
import './RegistrationPage.scss';

const RegistrationPage = (): JSX.Element | null => {
	const navigate = useNavigate();
	const loggedIn = useAppSelector(getLoggedIn);

	useEffect(() => {
		loggedIn && navigate('/');
	}, [loggedIn, navigate]);

	if (loggedIn) return null;

	const handleSubmitUserRegistration = (data: UserRegistrationData): void => {
		UserService.registerUser({ ...data, stateId: data.stateId ?? null }).then(res => {
			if (res) {
				Message.success({ messageId: Dictionary.message.success.registeredSuccessfully });
				navigate('/');
			}
		});
	};

	return (
		<div className="registration-page__wrap">
			<div className="registration__container">
				<div className="registration__header">
					<img alt="" className="image" loading="lazy" src={appLogoWhite} />
				</div>

				<div className="registration-page__form-container">
					<img alt="" loading="lazy" src={registrationFormLogo} />
					<UserRegistrationForm onSubmit={handleSubmitUserRegistration} />
				</div>
			</div>
		</div>
	);
};

export default RegistrationPage;
