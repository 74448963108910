import { BaseEntity, IdType, Sorting } from './common';

export enum LeadStatus {
	CONTACTED = 'Contacted',
	IN_NEGOTIATIONS = 'In negotiations',
	LINK_SENT = 'Link sent',
	LOST = 'Lost',
	NEW_LEAD = 'New lead',
	POSTPONED = 'Postponed',
	SOLD = 'Sold',
}

export enum LeadsOrderBy {
	ALPHABET = 'alphabet',
	CREATED = 'created',
}

export type LeadsFilter = {
	contactedStatus: boolean;
	inNegotiationsStatus: boolean;
	linkSentStatus: boolean;
	lostStatus: boolean;
	newLeadStatus: boolean;
	orderBy: LeadsOrderBy;
	postponedStatus: boolean;
	search: string;
	soldStatus: boolean;
	sorting: Sorting;
};

export interface Lead extends LeadDto, BaseEntity {
	userId: IdType;
	userName: string;
}

export interface LeadDto {
	name: string;
	email: string | null;
	phoneNumber: string;
	status: LeadStatus;
	comment: string | null;
}
