import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import MediasService from 'services/medias-service';
import { FetchStatus, IdType, ResultState } from 'types/common';
import { MediaItem } from 'types/medias';
import { RootState } from './index';

const initialState: ResultState<MediaItem | null> = {
	data: null,
	status: FetchStatus.IDLE,
	error: null,
};

export const fetchMediasByIdAsync = createAsyncThunk(
	'media/fetchByIdAll',
	async (mediaId: IdType) => {
		const response: MediaItem = await MediasService.fetchById(mediaId);

		return response;
	},
);

export const mediaSlice = createSlice({
	name: 'media',
	initialState,
	reducers: {	},
	extraReducers: (builder) => {
		builder
			// FETCH
			.addCase(fetchMediasByIdAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchMediasByIdAsync.fulfilled, (state, { payload }) => {
				state.status = FetchStatus.IDLE;
				state.data = payload;
			});
	},
});

const getMedia = (state: RootState) => state.media;

export const getMediaData = createSelector(getMedia, media => media.data);
export const isMediaLoading = createSelector(getMedia, media => media.status === FetchStatus.LOADING);

export default mediaSlice.reducer;
