import { Checkbox, Modal } from 'antd';
import { showGroupImage } from 'store/group-images';
import { showUserImage } from 'store/user-images';
import { IdType, ImageContainer } from 'types/common';
import { ImageListItem } from 'types/images';
import { useAppDispatch, useWindow } from 'utils/hooks';
import { useProtectedImage } from 'utils/hooks/use-protected-image';
import './ImageGalleryItem.scss';

type Props = ImageListItem & {
	containerName: ImageContainer;
	selected: boolean;
	showSelectIcon: boolean;
	onClickSelectIcon?: (id: IdType) => void;
};

const ImageGalleryItem = ({
	containerName, id, imageId, isHidden, selected, showSelectIcon, onClickSelectIcon,
}: Props): JSX.Element => {
	const dispatch = useAppDispatch();

	const [fullSizeView, showFullSize, closeFullSize] = useWindow();

	const src = useProtectedImage(imageId, containerName, '');

	const isSelectMode: boolean = showSelectIcon && !!onClickSelectIcon;

	const handleClickShow = (): void => {
		if (containerName === ImageContainer.GROUP_IMAGES) {
			dispatch(showGroupImage(id));
		}

		if (containerName === ImageContainer.USER_IMAGES) {
			dispatch(showUserImage(id));
		}
	};

	const handleSelect = (): void => onClickSelectIcon && onClickSelectIcon(id);

	return (
		<div className="image-gallery-item">
			{
				isHidden ? (
					<div className="image-gallery-item__hidden">
						<div>Current image is hidden</div>
						<div
							className="image-gallery-item__hidden-btn"
							onClick={handleClickShow}
						>
							Show
						</div>
					</div>
				) : (
					<div className="image-gallery-item__body">
						{isSelectMode && (
							<Checkbox
								checked={selected}
								className="image-gallery-item__select-checkbox"
								onChange={handleSelect}
							/>
						)}
						<img
							alt={`${containerName}_${imageId}`}
							onClick={isSelectMode ? handleSelect : showFullSize}
							src={src}
						/>
					</div>
				)
			}

			{fullSizeView && (
				<Modal
					centered footer={null} onCancel={closeFullSize} visible
					wrapClassName="my-modal"
				>
					<img alt="" src={src} />
				</Modal>
			)}
		</div>
	);
};

export default ImageGalleryItem;

