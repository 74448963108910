import { BaseEntity, IdType, SortingFilter } from './common';
import { Rating } from './rating';

export type SalesStatisticsData = {
	totalSales: number;
	currentYearSales: number;
	currentYear: number;
};

export type SalesHistory = {
	date: string;
	numberOfSales: number;
};

export type SalesHistoryData = {
	history: SalesHistory[];
	total: number;
};

export interface SalesUserLevel extends Rating {
	id: IdType;
	salesToNextLevel: number;
}

export interface SalesListItem extends BaseEntity {
	hotmartTransactionCode: string;
	purchaseDate: string;
	productName: string;
	price: number;
	clientName: string;
	status: string;
	paymentType: string;
	warrantyDate: string | null;
	commission: number;
}

export enum SalesListOrderBy {
	PRICE = 'price',
	PURCHASE_DATE = 'purchaseDate',
	WARRANTY_DATE = 'warrantyDate',
}

export interface SalesFilter extends SortingFilter {
	orderBy: SalesListOrderBy;
}

