import { Navigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { appLogoWhite } from 'assets/images';
import { InputPassword } from 'components/inputs-formik';
import { getLoggedIn } from 'store/auth';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppSelector } from 'utils/hooks';
import { useSetPasswordValidationSchema } from 'utils/validation/schemas';
import Button from '../button';

type FormValues = {
	password: string;
	passwordConfirmation: string;
};

type InnerProps = {
	messageTextDefault?: string;
	messageTextId?: string;
	submitBtnTextDefault?: string;
	submitBtnTextId?: string;
};

type Props = InnerProps & {
	onSubmit: (password: string) => void;
};

const InnerForm = ({
	messageTextDefault = 'Create your new password',
	messageTextId = Dictionary.auth.createNewPassword,
	submitBtnTextDefault = 'Submit',
	submitBtnTextId = Dictionary.common.submit,
	dirty, isValid, values,
}: InnerProps & FormikProps<FormValues>): JSX.Element => (
	<Form className="auth set-password">
		<div className="auth__form-wrapper">
			<div className="auth__form-header">
				<img alt="app-logo" src={appLogoWhite} />
			</div>

			<div className="auth__form-body">
				<div className="set-password__text text-center">
					<FormattedMessage defaultMessage={messageTextDefault} id={messageTextId} />
				</div>

				<InputPassword
					defaultLabel="New Password"
					labelId={Dictionary.auth.newPassword}
					name="password"
				/>

				<InputPassword
					defaultLabel="Confirm New Password"
					disabled={!values.password}
					labelId={Dictionary.auth.confirmNewPassword}
					name="passwordConfirmation"
				/>

				<div className="auth__form-footer">
					<Button
						defaultText={submitBtnTextDefault}
						disabled={!dirty || !isValid}
						textId={submitBtnTextId}
						type="submit"
					/>
				</div>
			</div>
		</div>
	</Form>
);

const SetPasswordForm = ({
	messageTextDefault,
	messageTextId,
	submitBtnTextDefault,
	submitBtnTextId,
	onSubmit,
}: Props): JSX.Element => {
	const loggedIn = useAppSelector(getLoggedIn);
	const validationSchema = useSetPasswordValidationSchema();

	return loggedIn ? <Navigate to="/" /> : (
		<Formik
			initialValues={{ password: '', passwordConfirmation: '' }}
			onSubmit={values => onSubmit(values.password)}
			validationSchema={validationSchema}
		>
			{(formikProps) => (
				<InnerForm
					{...formikProps}
					messageTextDefault={messageTextDefault}
					messageTextId={messageTextId}
					submitBtnTextDefault={submitBtnTextDefault}
					submitBtnTextId={submitBtnTextId}
				/>
			)}
		</Formik>
	);
};

export default SetPasswordForm;
