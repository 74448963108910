import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, Menu } from 'antd';
import { callCenterIco, whatsAppIco } from 'assets/images';
import Avatar from 'components/avatar';
import Button from 'components/forms/button';
import { getUserId, signOut } from 'store/auth';
import { fetchUserProfileAsync, getUserProfileData } from 'store/user-profile';
import { IdType, ImageContainer } from 'types/common';
import { UserProfile } from 'types/user-profile';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './UserProfileDropdown.scss';

const UserProfileMenu = (): JSX.Element => {
	const dispatch = useAppDispatch();

	return (
		<Menu className="user-profile-dropdown__menu">
			<Menu.Item key="1">
				<a
					className="user-profile-dropdown__menu-item"
					href="https://go.fluencyacademy.co/wpp-conexoes-fa"
					target="_blank"
				>
					<img alt="" src={whatsAppIco} />
					<FormattedMessage defaultMessage="WhatsApp" id={Dictionary.profileDropdown.whatsApp} />
				</a>
			</Menu.Item>
			{/* <Menu.Item key="2">
				<div className="user-profile-dropdown__menu-item">
					<img alt="" src={callCenterIco} />
					<FormattedMessage defaultMessage="Help Center" id={Dictionary.profileDropdown.helpCenter} />
				</div>
			</Menu.Item> */}
			<Menu.Item key="3">
				<div className="flex justify-content-center">
					<Button
						defaultText="Logout"
						onClick={() => dispatch(signOut())}
						textId={Dictionary.auth.logout}
					/>
				</div>
			</Menu.Item>
		</Menu>
	);
};

const UserProfileDropdown = (): JSX.Element => {
	const dispatch = useAppDispatch();

	const userId: IdType | null = useAppSelector(getUserId);
	const userProfile: UserProfile | null = useAppSelector(getUserProfileData);

	useEffect(() => {
		if (userId && !userProfile) {
			dispatch(fetchUserProfileAsync(userId));
		}
	}, [dispatch, userId, userProfile]);

	return (
		<Dropdown overlay={<UserProfileMenu />} trigger={['click']}>
			<div className="user-profile-dropdown">
				<Avatar
					alt="user-profile-dropdown__avatar"
					containerName={ImageContainer.USER_AVATAR}
					imageId={userProfile?.avatarImageId || null}
				/>
				<span className="user-profile-dropdown__label">
					{userProfile?.nickname}
				</span>
			</div>
		</Dropdown>
	);
};

export default UserProfileDropdown;
