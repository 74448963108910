import Message from 'components/message';
import PageTitle from 'components/page-title';
import UserService from 'services/user-service';
import { Dictionary } from 'utils/constants/dictionary';
import ChangePasswordForm from './change-password-form';
import { ChangePasswordFormValues } from './change-password-form/ChangePasswordForm';
import './ChangePassword.scss';

const ChangePassword = (): JSX.Element => {

	const handleSubmit = (values: ChangePasswordFormValues)=>{
		UserService.changeUserPassword(values)
			.then(res => res && Message.success({ messageId: Dictionary.message.success.passwordChanged }));
	};

	return (
		<div className="change-password">
			<PageTitle backLink defaultText="Change Password" textId={Dictionary.changePasswordPage.title} />

			<div className="change-password__wrap">
				<ChangePasswordForm
					onSubmit={handleSubmit}
					submitBtnTextDefault="Change"
					submitBtnTextId={Dictionary.common.change}
				/>
			</div>
		</div>
	);
};

export default ChangePassword;
