import { BaseEntity } from './common';
import { VideoFile } from './video-file';

export const TRAINING_COLOR = '#E6581F';

export interface Training extends BaseEntity {
	title: string;
	description: string;
	link: string;
	previewImageId: number;
	level: number;
	categoryId: number;
	categoryTitle: string;
	files: VideoFile[];
}

export type TrainingsList = Training[];

export interface TrainingCategory extends BaseEntity {
	title: string;
}

export interface TrainingData extends BaseEntity {
	title: string;
	description: string;
	previewImageId: number;
}

export interface TrainingCategoriesListItem extends BaseEntity {
	title: string;
	trainings: TrainingData[];
}

export type TrainingCategoriesList = TrainingCategoriesListItem[];
