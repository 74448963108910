import MultipleSelect from 'components/multiple-select';
import { MultipleSelectItem } from 'components/multiple-select/MultipleSelect';
import { getGroupInvitesFilter, setGroupInvitesFilter } from 'store/group-invites';
import { GroupInvitesFilter } from 'types/group-invites';
import { Dictionary } from 'utils/constants/dictionary';
import './GroupInvitesStatusSelector.scss';

const filterButtons: MultipleSelectItem<GroupInvitesFilter>[] = [
	{
		className: 'group-invite-status_accepted',
		defaultMessage: 'Accepted',
		filterKey: 'acceptedStatus',
		labelId:Dictionary.groupInvitesPage.status.accepted,
	},
	{
		className: 'group-invite-status_rejected',
		defaultMessage: 'Rejected',
		filterKey: 'rejectedStatus',
		labelId:Dictionary.groupInvitesPage.status.rejected,
	},
	{
		className: 'group-invite-status_sent',
		defaultMessage: 'Sent',
		filterKey: 'sentStatus',
		labelId:Dictionary.groupInvitesPage.status.sent,
	},
];

const GroupInvitesStatusSelector = (): JSX.Element => (
	<MultipleSelect<GroupInvitesFilter>
		buttons={filterButtons}
		className="group-invites-status-selector"
		defaultMessage="Status"
		filterSelector={getGroupInvitesFilter}
		labelId={Dictionary.groupInvitesPage.statusPlaceholder}
		setFilterAction={setGroupInvitesFilter}
	/>
);

export default GroupInvitesStatusSelector;
