import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import GroupService from 'services/group-service';
import { FetchStatus, IdType, UpdateDataPayload } from 'types/common';
import { Group, GroupDataRequest, GroupDataResponse } from 'types/group';
import { RootState } from './index';

type GroupState = {
	data: Group | null;
	status: FetchStatus;
};

const initialState: GroupState = {
	data: null,
	status: FetchStatus.IDLE,
};

export const createGroupAsync = createAsyncThunk(
	'(POST) /groups',
	async (payload: GroupDataRequest) => {
		const response: GroupDataResponse = await GroupService.createGroup(payload);

		return response;
	},
);

export const fetchGroupDataAsync = createAsyncThunk<Group, IdType>(
	'(GET) /groups',
	async (id: IdType) => {
		const response = await GroupService.getGroupData(id);

		return response;
	},
);

export const updateGroupAsync = createAsyncThunk(
	'(PUT) /groups',
	async (payload: UpdateDataPayload<GroupDataRequest>) => {
		const response: GroupDataResponse = await GroupService.updateGroup(payload);

		return response;
	},
);

export const groupSlice = createSlice({
	name: 'group',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(createGroupAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(createGroupAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;

				if (state.data) {
					state.data = { ...state.data, ...action.payload };
				}
			})

			.addCase(fetchGroupDataAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchGroupDataAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			})

			.addCase(updateGroupAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(updateGroupAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;

				if (state.data) {
					state.data = { ...state.data, ...action.payload };
				}
			});
	},
});

const getGroup = (state: RootState) => state.group;

export const getGroupData = createSelector(getGroup, group => group.data);

export default groupSlice.reducer;
