import { useIntl } from 'react-intl';
import { PropsWithChildren } from 'react';
import { Form } from 'antd';
import './FormItemView.scss';

type Props = {
	className?: string;
	defaultLabel?: string;
	labelId?: string;
};

const FormItemView = ({ className, children, defaultLabel, labelId }: PropsWithChildren<Props>): JSX.Element => {

	const intl = useIntl();

	return (
		<Form.Item
			className={`form-item-view ${className || ''}`}
			colon={false}
			label={defaultLabel && labelId && intl.formatMessage({ defaultMessage: defaultLabel, id: labelId })}
		>
			{children}
		</Form.Item>
	);
};

export default FormItemView;
