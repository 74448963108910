import { FormattedMessage } from 'react-intl';
import { RatingLevel } from 'types/rating';
import { getTierClassname, getTierTextId } from 'utils/helpers/tiers';
import './StatusLabel.scss';

const getFormattedMsgProps = (levelTitle: RatingLevel): {
	defaultMessage: string;
	id: string;
} => ({ defaultMessage: levelTitle, id: getTierTextId(levelTitle) });

type Props = {
	levelTitle: RatingLevel;
};

const StatusLabel = ({ levelTitle }: Props): JSX.Element => (
	<div className={`status-label ${getTierClassname(levelTitle)}`}>
		<FormattedMessage {...getFormattedMsgProps(levelTitle)} />
	</div>
);

export default StatusLabel;
