import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import BankAccountService from 'services/bank-account-service';
import { BaseMessageDto, FetchStatus } from 'types/common';
import { BankAccountData, BankAccountResponse, CreateBankAccountDto } from 'types/payment-data';
import { RootState } from './index';

type UserBankAccountState = {
	data: BankAccountResponse | null;
	status: FetchStatus;
};

const initialState: UserBankAccountState = {
	data: null,
	status: FetchStatus.IDLE,
};

export const createUserBankAccountAsync = createAsyncThunk(
	'(POST) /bank-accounts',
	async (data: CreateBankAccountDto) => {
		const response: BankAccountResponse = await BankAccountService.createBankAccountData(data);

		return response;
	},
);

export const fetchUserBankAccountAsync = createAsyncThunk(
	'(GET) /bank-accounts',
	async () => {
		const response: BankAccountResponse | null = await BankAccountService.getBankAccountData();

		return response;
	},
);

export const updateUserBankAccountAsync = createAsyncThunk(
	'(PUT) /bank-accounts',
	async (data: BankAccountData) => {
		const response: BankAccountResponse = await BankAccountService.updateBankAccountData(data);

		return response;
	},
);

export const removeUserBankAccountAsync = createAsyncThunk(
	'(DELETE) /bank-accounts',
	async () => {
		const response: BaseMessageDto = await BankAccountService.removeBankAccountData();

		return response;
	},
);

export const userBankAccountSlice = createSlice({
	name: 'userBankAccount',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(createUserBankAccountAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(createUserBankAccountAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			})

			.addCase(fetchUserBankAccountAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchUserBankAccountAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			})

			.addCase(updateUserBankAccountAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(updateUserBankAccountAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			})

			.addCase(removeUserBankAccountAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(removeUserBankAccountAsync.fulfilled, (state) => {
				state.status = FetchStatus.IDLE;
				state.data = null;
			});
	},
});

const getUserBankAccount = (state: RootState) => state.userBankAccount;

export const getUserBankAccountData = createSelector(getUserBankAccount, bankAccount => bankAccount.data);

export default userBankAccountSlice.reducer;
