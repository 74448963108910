import { ReactNodeArray, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Tabs } from 'antd';
import { lockPurple } from 'assets/images';
import Button from 'components/forms/button';
import PageTitle from 'components/page-title';
import { Dictionary } from 'utils/constants/dictionary';
import UserDocumentsService from 'services/user-documents-service';
import { fetchUserBalanceAsync } from 'store/user-balance';
import { UserDocumentsStatus } from 'types/user-documents';
import ROUTES from 'utils/constants/routes';
import { useAppDispatch } from 'utils/hooks';
import InvoicesHistory from './invoices-history';
import Withdrawal from './withdrawal';
import './Commissions.scss';

const { TabPane } = Tabs;

type CommissionsTabProps = {
	locked?: boolean;
	defaultMessage: string;
	id: string;
};

const CommissionsTab = ({ locked = false, defaultMessage, id }: CommissionsTabProps): JSX.Element => (
	<div className="commissions-tab">
		{locked && <img alt="locked" src={lockPurple} />}

		<FormattedMessage defaultMessage={defaultMessage} id={id} />
	</div>
);

const withdrawalTabKey = '1';
const historyTabKey = '2';

const Commissions = (): JSX.Element | null => {
	const dispatch = useAppDispatch();

	const [activeKey, setActiveKey] = useState<string>(withdrawalTabKey);
	const [meiStatus, setMeiStatus] = useState<UserDocumentsStatus | null>(null);

	useEffect(() => {
		UserDocumentsService.getDocumentStatus().then(res => {
			if (res) {
				setMeiStatus(res.status);
			}
		});
	}, []);

	useEffect(() => {
		dispatch(fetchUserBalanceAsync());
	}, [dispatch]);

	if (!meiStatus) return null;

	const meiVerified: boolean = meiStatus === UserDocumentsStatus.VERIFIED;

	const handleSubmitWithdrawal = (): void => {
		setActiveKey(historyTabKey);
	};

	const getTabContent = (Element: JSX.Element): JSX.Element => {
		if (meiVerified) return Element;

		return (
			<div className="tab-content-container">
				<FormattedMessage
					defaultMessage="You need to upload your MEI to receive your commission"
					id={Dictionary.commissionsPage.verificationText}
				>
					{(chunks: ReactNodeArray) => (
						<h2>
							<strong>
								{chunks}
							</strong>
						</h2>
					)}
				</FormattedMessage>

				<NavLink to={`/${ROUTES.PROFILE.BASE}/${ROUTES.PROFILE.PERSONAL_DATA}#personal-documents`}>
					<Button
						className="btn-nav"
						defaultText="Go to Personal Data"
						textId={Dictionary.commissionsPage.personalDocumentsLinkText}
					/>
				</NavLink>
			</div>
		);
	};

	return (
		<div className="commissions">
			<PageTitle defaultText="Commissions" textId={Dictionary.commissionsPage.title} />

			<div className="commissions__wrap">
				<Tabs activeKey={activeKey} centered destroyInactiveTabPane onChange={setActiveKey}>
					<TabPane
						key={withdrawalTabKey}
						tab={
							<CommissionsTab
								defaultMessage="Withdrawal"
								id={Dictionary.commissionsPage.withdrawal.title}
							/>
						}
					>
						{getTabContent(<Withdrawal onSubmit={handleSubmitWithdrawal} />)}
					</TabPane>
					<TabPane
						key={historyTabKey}
						tab={
							<CommissionsTab
								defaultMessage="History"
								id={Dictionary.commissionsPage.history.title}
							/>}
					>
						{getTabContent(<InvoicesHistory />)}
					</TabPane>
				</Tabs>
			</div>
		</div>
	);
};

export default Commissions;
