/* eslint-disable max-len */
import { BaseMessageDto } from 'types/common';
import { CreatePixDataDto, PixData, PixDataResponse } from 'types/payment-data';
import { PIX } from 'utils/constants/end-points';
import Http from './http';

class PixService {
	createPixData = (data: CreatePixDataDto): Promise<PixDataResponse> => Http.post(PIX, data);

	getPixData = (): Promise<PixDataResponse> => Http.get(PIX);

	updatePixData = (data: PixData): Promise<PixDataResponse> => Http.put(PIX, data);

	removePixData = (): Promise<BaseMessageDto> => Http.delete(PIX);
}

export default new PixService();
