import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import Avatar from 'components/avatar/Avatar';
import StatusLabel from 'components/rating/status-label';
import { getUserProfileData } from 'store/user-profile';
import { IdType, ImageContainer } from 'types/common';
import { GroupRankingItem } from 'types/rating';
import { UserProfile } from 'types/user-profile';
import { Dictionary } from 'utils/constants/dictionary';
import ROUTES from 'utils/constants/routes';
import { useAppSelector } from 'utils/hooks';
import './GroupRankingTable.scss';

type Props = {
	dataSource: GroupRankingItem[];
};

const GroupRankingTable = ({ dataSource }: Props): JSX.Element => {
	const userProfile: UserProfile | null = useAppSelector(getUserProfileData);

	const getLink = (groupId: IdType): string => userProfile?.groupId === groupId
		? `/${ROUTES.GROUP.BASE}`
		: `/${ROUTES.GROUP.BASE}/${groupId}`;

	const columns = [
		{
			title: () => <FormattedMessage defaultMessage="Group" id={Dictionary.common.rankingTable.group} />,
			dataIndex: 'groupName',
			key: 'groupName',
			render: (name: string, record: GroupRankingItem) => (
				<div className="group-ranking-table__item">
					<Link to={getLink(record.id)}>
						<Avatar
							active={record.id === userProfile?.groupId}
							alt={`group-creator-avatar_${record.creatorId}`}
							className="group-rating__user-avatar"
							containerName={ImageContainer.GROUP_AVATAR}
							imageId={record.groupAvatarImageId}
						/>
					</Link>
					{name}
				</div>
			),
		},
		{
			title: () => <FormattedMessage defaultMessage="Leader" id={Dictionary.common.rankingTable.leader} />,
			dataIndex: 'creatorNickname',
			key: 'creatorNickname',
			render: (creatorNickname: string, record: GroupRankingItem) => (
				<div className="group-ranking-table__item">
					<StatusLabel levelTitle={record.levelTitle} />
					<span className="group-ranking-table__item-text">
						{creatorNickname}
					</span>
				</div>
			),
		},
		{
			title: () => <FormattedMessage defaultMessage="Score" id={Dictionary.common.rankingTable.score} />,
			dataIndex: 'salesAmount',
			key: 'salesAmount',
			render: (salesAmount: number, record: GroupRankingItem) => (
				<div className={`group-ranking-table__count ${record.id === userProfile?.groupId ? 'active' : ''}`}>
					{salesAmount}
				</div>
			),
		}];

	return (
		<Table
			className="group-ranking-table table"
			columns={columns} dataSource={dataSource} pagination={false} rowKey="id"
			scroll={{ y: '50vh' }}
		/>
	);
};

export default GroupRankingTable;
