import { checkWhite } from 'assets/images';
import { Dictionary } from 'utils/constants/dictionary';
import Button from '../button';

type Props = {
	className?: string;
	disabled?: boolean;
};

const ButtonSave = ({ className, disabled }: Props): JSX.Element => (
	<Button
		className={`btn-small btn-save ${className || ''}`}
		defaultText="Save"
		disabled={disabled}
		imgSrc={checkWhite}
		textId={Dictionary.common.save}
		type="submit"
	/>
);

export default ButtonSave;
