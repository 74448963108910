import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import AuthorizationService from 'services/authorization-service';
import { Credentials } from 'types/auth';
import { FetchStatus, IdType } from 'types/common';
import { RootState } from './index';

type AuthState = {
	accessToken: string | null;
	expirationDate: string | null;
	userId: IdType | null;
	status: FetchStatus;
};

const initialState: AuthState = {
	accessToken: null,
	expirationDate: null,
	userId: null,
	status: FetchStatus.IDLE,
};

export const loginAsync = createAsyncThunk(
	'auth/authorization',
	async (credentials: Credentials) => {
		const response = await AuthorizationService.authorizationUser(credentials);

		return response;
	},
);

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		signOut: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(loginAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE,
				state.accessToken = action.payload.accessToken;
				state.expirationDate = action.payload.expirationDate;
				state.userId = action.payload.userId;
			});
	},
});

const getAuth = (state: RootState) => state.auth;

export const getAccessToken = createSelector(getAuth, auth => auth.accessToken);

export const getLoggedIn = createSelector(getAuth, auth => !!auth.accessToken);

export const getUserId = createSelector(getAuth, auth => auth.userId);

const { signOut } = authSlice.actions;

export { signOut };

export default authSlice.reducer;
