import { useEffect } from 'react';
import { linkPurple } from 'assets/images';
import ShareLink from 'components/share-link';
import { fetchUserReferralLinkAsync, getUsersReferralLinkData } from 'store/user-referral-link';
import { useAppDispatch, useAppSelector } from 'utils/hooks';

const UserReferralLink = (): JSX.Element | null => {
	const dispatch = useAppDispatch();

	const referralLink: string | null = useAppSelector(getUsersReferralLinkData);

	useEffect(() => {
		dispatch(fetchUserReferralLinkAsync());
	}, [dispatch]);

	if (!referralLink) return null;

	return (
		<ShareLink imgSrc={linkPurple} link={referralLink} />
	);
};

export default UserReferralLink;
