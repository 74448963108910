import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import UserService from 'services/user-service';
import { FetchStatus, IdType } from 'types/common';
import { UpdateProfileRequest, UpdateProfileResponse, UserProfile } from 'types/user-profile';
import { RootState } from './index';

type UserProfileState = {
	data: UserProfile | null;
	status: FetchStatus;
};

const initialState: UserProfileState = {
	data: null,
	status: FetchStatus.IDLE,
};

export const fetchUserProfileAsync = createAsyncThunk(
	'(GET) users/profile',
	async (id: IdType) => {
		const response: UserProfile = await UserService.getUserProfile(id);

		return response;
	},
);

export const updateUserProfileAsync = createAsyncThunk(
	'(PUT) users/profile',
	async (data: UpdateProfileRequest) => {
		const response: UpdateProfileResponse = await UserService.updateUserProfile(data);

		return response;
	},
);

export const userProfileSlice = createSlice({
	name: 'userProfile',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserProfileAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchUserProfileAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			})

			.addCase(updateUserProfileAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(updateUserProfileAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;

				if (state.data) {
					state.data = { ...state.data, ...action.payload };
				}
			});
	},
});

const getUserProfile = (state: RootState) => state.userProfile;

export const getUserProfileData = createSelector(getUserProfile, userProfile => userProfile.data);

export default userProfileSlice.reducer;
