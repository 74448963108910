import { FormattedMessage } from 'react-intl';
import { nanoid } from 'nanoid';
import './DataGrid.scss';

type Props<T> = {
	className?: string;
	defaultTitle?: string;
	items: T[];
	renderItem: (item: T) => JSX.Element;
	titleId?: string;
};

const DataGrid = <T,>({ className, defaultTitle, items, renderItem, titleId }: Props<T>): JSX.Element => (
	<div className="data-grid">
		{defaultTitle && titleId && (
			<h2>
				<FormattedMessage defaultMessage={defaultTitle} id={titleId} />
			</h2>
		)}

		<div className={`data-grid__wrap ${className ?? ''}`}>
			{items.map(item => (
				<div className="data-grid__item" key={nanoid()}>
					{renderItem(item)}
				</div>
			))}
		</div>
	</div>
);

export default DataGrid;
