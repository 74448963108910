import { useParams } from 'react-router-dom';
import GroupPage from '../group-page';

const GroupItem = (): JSX.Element | null => {
	const { id } = useParams<{ id: string }>();

	if (!id) return null;

	return (
		<GroupPage groupId={+id} />
	);
};

export default GroupItem;
