import { FormattedMessage, useIntl } from 'react-intl';
import { useField } from 'formik';
import { FormItem, Input, InputProps } from 'formik-antd';

type Props = {
	defaultLabel?: string;
	defaultPlaceholder?: string;
	labelId?: string;
	nullable?: boolean;
	placeholderId?: string;
	placeholderValues?: Record<string, string>;
	validateStatus?: '' | 'error' | 'success' | 'warning' | 'validating';
	help?: string | number | boolean;
};

const InputText = ({
	defaultLabel,
	defaultPlaceholder,
	name,
	labelId,
	placeholderId,
	placeholderValues,
	nullable = true,
	validateStatus,
	help,
	required,
	className,
	onBlur,
	onChange,
	...restProps
}: InputProps & Props): JSX.Element => {
	const [field, meta, actions] = useField(name);
	const intl = useIntl();

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		nullable && typeof e.target.value === 'string' && actions.setValue(e.target.value.trim() || null);
		onBlur && onBlur(e);
	};

	const validStatus = meta.touched ? (validateStatus || (meta.error ? 'error' : '')) : '';
	const helpMessage = meta.touched && (help || meta.error);

	return (
		<FormItem
			colon={false}
			help={helpMessage}
			htmlFor={name}
			label={defaultLabel && labelId && <FormattedMessage defaultMessage={defaultLabel} id={labelId} />}
			name={name}
			required={required}
			validateStatus={validStatus}
		>
			<Input
				{...restProps}
				{...field}
				id={name}
				onBlur={handleBlur}
				onChange={onChange}
				placeholder={
					placeholderId && defaultPlaceholder &&
					intl.formatMessage(
						{ id: placeholderId, defaultMessage: defaultPlaceholder },
						{ ...placeholderValues },
					)
				}
				value={restProps.value ?? field.value}
			/>
		</FormItem>
	);
};

export default InputText;
