import { Routes, Route } from 'react-router-dom';
import ROUTES from 'utils/constants/routes';
import GroupFreeUsersPage from 'views/group/group-free-users-page';
import GroupInvitesPage from 'views/group/group-invites-page';
import GroupItem from 'views/group/group-item';
import GroupJoin from 'views/group/group-join';
import GroupLayout from 'views/group/group-layout';
import GroupMembersPage from 'views/group/group-members-page';
import NotFound from 'views/not-found';

const { FREE_USERS, ITEM, INVITES, JOIN, MEMBERS } = ROUTES.GROUP;

const GroupRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<GroupLayout />} index />
		<Route element={<GroupFreeUsersPage />} path={FREE_USERS} />
		<Route element={<GroupItem />} path={ITEM} />
		<Route element={<GroupInvitesPage />} path={INVITES} />
		<Route element={<GroupJoin />} path={JOIN} />
		<Route element={<GroupMembersPage />} path={MEMBERS} />
		<Route element={<NotFound />} path="*" />
	</Routes>
);

export default GroupRoutes;
