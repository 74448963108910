import { emptyAvatar } from 'assets/images';
import { SyntheticEvent } from 'react';
import { IdType, ImageContainer } from 'types/common';
import { useProtectedImage } from 'utils/hooks/use-protected-image';
import './Avatar.scss';

type Props = {
	alt: string;
	active?: boolean;
	className?: string;
	containerName: ImageContainer;
	defaultImg?: string;
	imageId: IdType | null;
	position?: number;
};

const Avatar = ({
	active = false, alt, className, containerName, defaultImg = emptyAvatar, imageId, position,
}: Props): JSX.Element => {

	const src = useProtectedImage(imageId, containerName, defaultImg);

	const handleError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
		e.currentTarget.src = defaultImg;
	};

	return (
		<div className={`avatar ${active ? 'active' : ''}`}>
			<img alt={alt} className={`avatar__img ${className || ''}`} onError={handleError} src={src} />

			{position && (
				<div className="avatar__position-ico">
					{position}
				</div>
			)}
		</div>
	);
};

export default Avatar;

