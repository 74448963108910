import { IdType } from 'types/common';
import { MediaItem, MediasList } from 'types/medias';
import { MEDIAS } from 'utils/constants/end-points';
import Http from './http';

class MediasService {
	fetchById = (mediaId: IdType): Promise<MediaItem> => Http.get<MediaItem>(
		MEDIAS + '/user', { params: { id:mediaId } },
	);

	fetchAll = (): Promise<MediasList> => Http.get(`${MEDIAS}/all/user`);

	fetchFeed = (): Promise<MediasList> => Http.get<MediasList>(MEDIAS + '/feed');
}

export default new MediasService();
