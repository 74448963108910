import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import TransactionsService from 'services/transactions-service';
import { FetchStatus } from 'types/common';
import { UserBalance } from 'types/transactions';
import { RootState } from './index';

type UserBalanceState = {
	data: UserBalance | null;
	status: FetchStatus;
};

const initialState: UserBalanceState = {
	data: null,
	status: FetchStatus.IDLE,
};

export const fetchUserBalanceAsync = createAsyncThunk(
	'(GET) /transactions/balance/user',
	async () => {
		const res: UserBalance = await TransactionsService.getUserBalance();

		return res;
	},
);

export const userBalanceSlice = createSlice({
	name: 'userBalance',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserBalanceAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchUserBalanceAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			});
	},
});

const getUserBalance = (state: RootState) => state.userBalance;

export const getUserBalanceData = createSelector(getUserBalance, userBalance => userBalance.data);

export default userBalanceSlice.reducer;
