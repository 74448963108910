import * as Yup from 'yup';
import { Dictionary } from 'utils/constants/dictionary';
import { useIntlMessage } from 'utils/hooks';
import { PixKey } from 'types/payment-data';
import { IdType } from 'types/common';
import { PASSWORDS_MUST_MATCH } from './default-messages';
import {
	AccountValidationRule,
	AgencyValidationRule, CnpjValidationRule, CpfValidationRule,
	EmailValidationRule, PasswordValidationRule, PhoneNumberValidationRule,
	useRequired,
} from './helpers';

export const useCredentialsValidationSchema = () => Yup.object({
	username: EmailValidationRule().nullable(),
	password: PasswordValidationRule().nullable(),
});

export const useForgotPasswordValidationSchema = () => Yup.object({
	email: EmailValidationRule().nullable(),
});

export const useSetPasswordValidationSchema = () => Yup.object({
	password: PasswordValidationRule().nullable(),
	passwordConfirmation: PasswordValidationRule().nullable()
		.oneOf([Yup.ref('password'), null], useIntlMessage({
			defaultMessage: PASSWORDS_MUST_MATCH,
			id: Dictionary.validation.passwordsMustMatch,
		})),
});

export const useChangePasswordValidationSchema = () => Yup.object({
	currentPassword: PasswordValidationRule().nullable(),
	newPassword: PasswordValidationRule().nullable(),
	newPasswordConfirmation: PasswordValidationRule().nullable()
		.oneOf([Yup.ref('newPassword'), null], useIntlMessage({
			defaultMessage: PASSWORDS_MUST_MATCH,
			id: Dictionary.validation.passwordsMustMatch,
		})),
});

export const useSecuritySettingsValidatingSchema = () => Yup.object({
	phoneNumber: PhoneNumberValidationRule(),
});

export const useChangeEmailValidationSchema = () => Yup.object({
	email: EmailValidationRule().nullable(),
});

export const useGroupValidationSchema = () => Yup.object({
	name: Yup.string().required(useRequired()).nullable(),
});

export const useLeadValidationSchema = () => Yup.object({
	email: EmailValidationRule().nullable(),
	name: Yup.string().required(useRequired()).nullable(),
	phoneNumber: PhoneNumberValidationRule(),
	status: Yup.string().required(useRequired()).nullable(),
});

export const useUserRegistrationSchema = () => Yup.object({
	email: EmailValidationRule().nullable(),
	fullName: Yup.string().required(useRequired()).nullable(),
	phoneNumber: PhoneNumberValidationRule(),
	countryId: Yup.number().required(useRequired()),
	stateId: Yup.number().nullable(),
	city: Yup.string().required(useRequired()).nullable(),
});

export const usePaymentDataValidationSchema = () => Yup.object({
	bankAccountData: Yup.object({
		cpf: CpfValidationRule().nullable(),
		bankId: Yup.number().nullable(),
		agency: Yup.string().nullable().when('bankId', {
			is: (value: IdType | undefined) => !!value,
			then: AgencyValidationRule().required(useRequired()),
		}),
		accountNumber: Yup.string().nullable().when('bankId', {
			is: (value: IdType | undefined) => !!value,
			then: AccountValidationRule().required(useRequired()),
		}),
	}).nullable(),
	pixData: Yup.object({
		key: Yup.string().nullable(),
		cnpj: Yup.string().nullable()
			.when('key', { is: PixKey.CNPJ, then: CnpjValidationRule().required(useRequired()) }),
		cpf: Yup.string().nullable()
			.when('key', { is: PixKey.CPF, then: CpfValidationRule().required(useRequired()) }),
		email: Yup.string().nullable()
			.when('key', { is: PixKey.EMAIL, then: EmailValidationRule().nullable() }),
		phone: Yup.string().nullable()
			.when('key', { is: PixKey.PHONE, then: PhoneNumberValidationRule() }),
		bankId: Yup.number().nullable()
			.when('key', { is: PixKey.BANK_ACCOUNT, then: Yup.number().required(useRequired()) }),
		agency: Yup.string().nullable()
			.when('key', { is: PixKey.BANK_ACCOUNT, then: AgencyValidationRule() }),
		accountNumber: Yup.string().nullable()
			.when('key', { is: PixKey.BANK_ACCOUNT, then: Yup.string().required(useRequired()).nullable() }),
		stateId: Yup.number().nullable()
			.when('key', { is: PixKey.BANK_ACCOUNT, then: Yup.number().required(useRequired()) }),
		city: Yup.string().nullable()
			.when('key', { is: PixKey.BANK_ACCOUNT, then: Yup.string().required(useRequired()).nullable() }),
	}).nullable(),
});
