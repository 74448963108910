import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import GroupsUsersService from 'services/groups-users-service';
import { BaseMessageDto, FetchStatus, IdType, ResultState } from 'types/common';
import { GroupMembersFilter, GroupMembersListItem } from 'types/group-members';
import { GROUP_MEMBERS_FILTER_DEFAULT } from 'utils/constants/default-filters';
import { RootState } from './index';

type GroupMembersState = ResultState<GroupMembersListItem[] | null> & { filter: GroupMembersFilter };

const initialState: GroupMembersState = {
	data: null,
	status: FetchStatus.IDLE,
	error: null,
	filter: GROUP_MEMBERS_FILTER_DEFAULT,
};

export const fetchGroupMembersAsync = createAsyncThunk(
	'(GET) /groups-users/group-members/user',
	async (filter: GroupMembersFilter) => {
		const response = await GroupsUsersService.getGroupMembers(filter);

		return response;
	},
);

export const removeGroupMemberAsync = createAsyncThunk(
	'(PUT) /groups-users/ungroup',
	async (groupId: IdType) => {
		const response: BaseMessageDto = await GroupsUsersService.removeGroupMember(groupId);

		if (response) return groupId;
	},
);

export const groupMembersSlice = createSlice({
	name: 'group-members',
	initialState,
	reducers: {
		setGroupMembersFilter(state, { payload }: PayloadAction<GroupMembersFilter>) {
			state.filter = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGroupMembersAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchGroupMembersAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			})

			.addCase(removeGroupMemberAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(removeGroupMemberAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;

				if (state.data) {
					state.data = state.data.filter(item => item.id !== action.payload);
				}
			});
	},
});

const getGroupMembers = (state: RootState) => state.groupMembers;

export const { setGroupMembersFilter } = groupMembersSlice.actions;

export const getGroupMembersData = createSelector(getGroupMembers, groupMembers => groupMembers.data);
export const getGroupMembersFilter = createSelector(getGroupMembers, groupMembers => groupMembers.filter);

export default groupMembersSlice.reducer;
