import { SearchFilter } from './common';
import { UserInfo } from './user';

export enum GroupInviteStatus {
	ACCEPTED = 'Accepted',
	REJECTED = 'Rejected',
	SENT = 'Sent',
}

export type GroupInvitesListItem = UserInfo & {
	status: GroupInviteStatus;
};

export type GroupInvitesFilter = SearchFilter & {
	acceptedStatus: boolean;
	rejectedStatus: boolean;
	sentStatus: boolean;
};

export type GroupInvitationLinkRespose = {
	link: string;
};

