import {
	menuIcoGroup,
	menuIcoHome,
	//menuIcoLeads,
	menuIcoMedia,
	menuIcoCommissions,
	menuIcoProfile,
	menuIcoRanking,
	menuIcoTraining,
	menuIcoSales,
} from 'assets/images';
import { Dictionary } from './dictionary';
import ROUTES from './routes';

export type MainMenuItem = {
	defaultText: string;
	icon: string;
	link: string;
	textId: string;
};

export const mainMenu: MainMenuItem[] = [
	{
		defaultText: 'Home',
		icon: menuIcoHome,
		link: '/',
		textId: Dictionary.mainMenu.home,
	},
	// {
	// 	defaultText: 'Leads base',
	// 	icon: menuIcoLeads,
	// 	link: ROUTES.LEADS.BASE,
	// 	textId: Dictionary.mainMenu.leadsBase,
	// },
	{
		defaultText: 'Commissions',
		icon: menuIcoCommissions,
		link: ROUTES.COMMISSIONS.BASE,
		textId: Dictionary.mainMenu.commissions,
	},
	{
		defaultText: 'Sales',
		icon: menuIcoSales,
		link: ROUTES.SALES.BASE,
		textId: Dictionary.mainMenu.sales,
	},
	{
		defaultText: 'Ranking',
		icon: menuIcoRanking,
		link: ROUTES.RANKING.BASE,
		textId: Dictionary.mainMenu.ranking,
	},
	{
		defaultText: 'Training',
		icon: menuIcoTraining,
		link: ROUTES.TRAINING.BASE,
		textId: Dictionary.mainMenu.training,
	},
	{
		defaultText: 'Media',
		icon: menuIcoMedia,
		link: ROUTES.MEDIAS.BASE,
		textId: Dictionary.mainMenu.media,
	},
	{
		defaultText: 'Profile',
		icon: menuIcoProfile,
		link: ROUTES.PROFILE.BASE,
		textId: Dictionary.mainMenu.profile,
	},
	{
		defaultText: 'Group',
		icon: menuIcoGroup,
		link: ROUTES.GROUP.BASE,
		textId: Dictionary.mainMenu.group,
	},
];
