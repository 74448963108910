import { Routes, Route } from 'react-router-dom';
import ROUTES from 'utils/constants/routes';
import NotFound from 'views/not-found';
import ProfileChangeEmail from 'views/profile/profile-change-email';
import ProfileChangePassword from 'views/profile/profile-change-password';
import ProfileGeneral from 'views/profile/profile-general';
//import ProfileInvitation from 'views/profile/profile-invitation';
import ProfileItem from 'views/profile/profile-item';
//import ProfileLegalDocuments from 'views/profile/profile-legal-documents';
import ProfilePersonalData from 'views/profile/profile-personal-data';
import ProfileSecuritySettings from 'views/profile/profile-security-settings';
import ProfileSetEmail from 'views/profile/profile-set-email';

const {
	CHANGE_EMAIL,
	CHANGE_PASSWORD,
	//INVITATION,
	ITEM,
	//LEGAL_DOCUMENTS,
	PERSONAL_DATA,
	SECURITY_SETTINGS,
	SET_EMAIL,
} = ROUTES.PROFILE;

const ProfileRoutes = (): JSX.Element => (
	<Routes>
		<Route element={<ProfileGeneral />} index />
		<Route element={<ProfileChangeEmail />} path={CHANGE_EMAIL} />
		<Route element={<ProfileChangePassword />} path={CHANGE_PASSWORD} />
		<Route element={<ProfileItem />} path={ITEM} />
		{/* <Route element={<ProfileInvitation />} path={INVITATION} /> */}
		{/* <Route element={<ProfileLegalDocuments />} path={LEGAL_DOCUMENTS} /> */}
		<Route element={<ProfilePersonalData />} path={PERSONAL_DATA} />
		<Route element={<ProfileSecuritySettings />} path={SECURITY_SETTINGS} />
		<Route element={<ProfileSetEmail />} path={SET_EMAIL} />
		<Route element={<NotFound />} path="*" />
	</Routes>
);

export default ProfileRoutes;
