import { AnchorHTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import './CoursesLink.scss';

type Props = {
	className?: string;
	defaultText?: string;
	href: string;
	imgSrc?: string;
	textId: string;
};

const CoursesLink = ({
	className, defaultText, href, textId, imgSrc, ...restProps
}: Props & AnchorHTMLAttributes<HTMLAnchorElement>): JSX.Element => (
	<a className={`courses-link ${className ?? ''}`} href={href} {...restProps}>
		{defaultText && textId && <FormattedMessage defaultMessage={defaultText} id={textId} />}

		{imgSrc && <img alt="" src={imgSrc} />}
	</a>
);

export default CoursesLink;
