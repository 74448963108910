import { Col, Row } from 'antd';
import { useProtectedImage } from 'utils/hooks/use-protected-image';
import { ImageContainer } from 'types/common';
import { appLogoWhite as backgroundImage, arrowRightWhite } from 'assets/images';
import './VideoListItem.scss';

type Props = {
	previewImageId: number;
	title?: string;
};

const VideoListItem = ({ previewImageId, title }: Props) => {
	const src = useProtectedImage(previewImageId, ImageContainer.PREVIEW_IMAGES, backgroundImage);

	return (
		<Row className="VideoListItem">
			<Col span={24}>
				<img alt="" className="VideoListItem__video" src={src} />
			</Col>
			{title && <Col span={24}>
				<Row className="VideoListItem__title" justify="space-between">
					<Col>
						<div>
							{title}
						</div>
					</Col>
					<Col className="flex">
						<img alt="" src={arrowRightWhite} />
					</Col>
				</Row>
			</Col>
			}
		</Row>
	);
};

export default VideoListItem;
