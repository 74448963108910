/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BASE_API_URL } from 'utils/constants/base';

export type RequestConfig = AxiosRequestConfig;
export type Response<T = any> = AxiosResponse<T>;
export type Fulfilled<T> = (value: T) => T | Promise<T>;
export type Rejected<E = any, T = any> = (error: E) => T;

export default class Http {
	static baseUrl = BASE_API_URL || '';

	static httpLib = axios;

	static setInterceptorRequest(onFulfilled?: Fulfilled<RequestConfig>, onRejected?: Rejected): void {
		axios.interceptors.request.use(onFulfilled, onRejected);
	}

	static setInterceptorResponse(onFulfilled?: Fulfilled<Response>, onRejected?: Rejected): void {
		axios.interceptors.response.use(onFulfilled, onRejected);
	}

	static get<T>(url: string, config?: RequestConfig): Promise<T> {
		return this.httpLib.get<T>(`${this.baseUrl}/${url}`, config)
			.then(res => Http.handlerResponse(res));
	}

	static post<T, D>(url: string, data: D, config?: RequestConfig): Promise<T> {
		return this.httpLib.post<T>(`${this.baseUrl}/${url}`, data, config)
			.then(res => Http.handlerResponse(res));
	}

	static patch<T, D>(url: string, data: D, config?: RequestConfig): Promise<T> {
		return this.httpLib.patch<T>(`${this.baseUrl}/${url}`, data, config)
			.then(res => Http.handlerResponse(res));
	}

	static put<T, D>(url: string, data: D, config?: RequestConfig): Promise<T> {
		return this.httpLib.put<T>(`${this.baseUrl}/${url}`, data, config)
			.then(res => Http.handlerResponse(res));
	}

	static delete<T>(url: string, config?: RequestConfig): Promise<T> {
		return this.httpLib.delete<T>(`${this.baseUrl}/${url}`, config)
			.then(res => Http.handlerResponse(res));
	}

	private static handlerResponse<R>(res: Response<R>): R {
		return res.data;
	}
}
