import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ComplaintsService from 'services/complaints-service';
import { FetchStatus } from 'types/common';
import { ComplaintRequest } from 'types/complaints';

type ComplaintsState = {
	status: FetchStatus;
};

const initialState: ComplaintsState = {
	status: FetchStatus.IDLE,
};

export const reportComplaintAsync = createAsyncThunk(
	'(POST) /complaints',
	async (request: ComplaintRequest) => {
		const response = await ComplaintsService.reportComplaint(request);

		return response;
	},
);

export const complaintsSlice = createSlice({
	name: 'complaints',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(reportComplaintAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(reportComplaintAsync.fulfilled, (state) => {
				state.status = FetchStatus.IDLE;
			});
	},
});

export default complaintsSlice.reducer;
