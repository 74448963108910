import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { InputText } from 'components/inputs';
import PageTitle from 'components/page-title';
import { fetchGlossaryAsync } from 'store/glossary';
import {
	fetchGroupFreeUsersAsync, getGroupFreeUsersData, getGroupFreeUsersFilter, setGroupFreeUsersFilter,
} from 'store/group-free-users';
import { GlossaryEnum } from 'types/glossary';
import { GroupFreeUsersFilter, GroupFreeUsersListItem } from 'types/group-free-users';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { GROUP_FREE_USERS_FILTER_DEFAULT } from 'utils/constants/default-filters';
import { searchIcoWhite } from 'assets/images';
import GroupFreeUsersTable from './group-free-users-table';
import UserLevelSelector from './user-level-selector';
import './GroupFreeUsersPage.scss';

const GroupFreeUsersPage = (): JSX.Element | null => {
	const dispatch = useAppDispatch();

	const [search, setSearch] = useState<string>('');

	const groupFreeUsers: GroupFreeUsersListItem[] | null = useAppSelector(getGroupFreeUsersData);
	const filter: GroupFreeUsersFilter = useAppSelector(getGroupFreeUsersFilter);

	const submitSearchFilter = (search: string)=> {
		dispatch(setGroupFreeUsersFilter({ ...filter, search }));
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
		if (e.target.value === '') {
			dispatch(setGroupFreeUsersFilter({ ...filter, search: '' }));
		}
		setSearch(e.target.value);
	};

	const handlePressEnter = (e: KeyboardEvent<HTMLInputElement>): void => {
		dispatch(setGroupFreeUsersFilter({ ...filter, search: e.currentTarget.value }));
	};

	useEffect(() => {
		dispatch(fetchGlossaryAsync(GlossaryEnum.COUNTRIES));
	}, [dispatch]);

	useEffect(() => {
		dispatch(fetchGroupFreeUsersAsync(filter));
	}, [dispatch, filter]);

	useEffect(() => () => {
		dispatch(setGroupFreeUsersFilter(GROUP_FREE_USERS_FILTER_DEFAULT));
	}, [dispatch]);

	return (
		<div className="group-free-users">
			<div className="group-free-users__top">
				<PageTitle
					backLink={'/group'}
					defaultText="Invite new members"
					textId={Dictionary.groupFreeUsersPage.title}
				/>

				<div className="group-free-users__top-right">
					<div className="group-free-users__top-search">
						<InputText
							defaultPlaceholder="Search"
							onChange={handleChange}
							onPressEnter={handlePressEnter}
							placeholderId={Dictionary.common.search}
							suffix={<img alt="" onClick={() => submitSearchFilter(search)} src={searchIcoWhite} />}
						/>
					</div>

					<div className="group-free-users__top-sort">
						<UserLevelSelector />
					</div>
				</div>
			</div>

			<GroupFreeUsersTable dataSource={groupFreeUsers || []} />
		</div>
	);
};

export default GroupFreeUsersPage;
