import { pencilWhite } from 'assets/images';
import { Dictionary } from 'utils/constants/dictionary';
import Button from '../button';

type Props = {
	className?: string;
	onClick: () => void;
};

const ButtonEdit = ({ className, onClick }: Props): JSX.Element => (
	<Button
		className={`btn-edit ${className || ''}`}
		defaultText="Edit"
		imgSrc={pencilWhite}
		onClick={onClick}
		textId={Dictionary.common.edit}
	/>
);

export default ButtonEdit;
