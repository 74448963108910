import { InvoiceListItem } from 'types/commissions';
import { IdType } from 'types/common';
import { INVOICE } from 'utils/constants/end-points';
import Http from './http';

class InvoiceService {
	getUserInvoices = (userId: IdType): Promise<InvoiceListItem[]> => Http.get(
		`${INVOICE}/history`, { params: { userId } },
	);
}

export default new InvoiceService();
