import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import TrainingsService from 'services/trainings-service';
import { FetchStatus, IdType, ResultState } from 'types/common';
import { TrainingsList } from 'types/trainings';
import { RootState } from './index';

const initialState: ResultState<TrainingsList | null> = {
	data: null,
	status: FetchStatus.IDLE,
	error: null,
};

export const fetchTrainingsByIdAsync = createAsyncThunk(
	'trainings/list',
	async (categoryId: IdType) => {
		const trainings: TrainingsList = await TrainingsService.fetchAllByCategory(categoryId);

		return trainings;
	},
);

export const trainingsListSlice = createSlice({
	name: 'trainings/list',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// FETCH
			.addCase(fetchTrainingsByIdAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchTrainingsByIdAsync.fulfilled, (state, { payload }) => {
				state.status = FetchStatus.IDLE;
				state.data = payload;
			});
	},
});

const getTrainings = (state: RootState) => state.trainingsList;

export const getTrainingsData = createSelector(getTrainings, trainings => trainings.data);
export const isTrainingsLoading = createSelector(getTrainings, trainings => trainings.status === FetchStatus.LOADING);

export default trainingsListSlice.reducer;
