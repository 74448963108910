import { IdType } from 'types/common';
import { Training, TrainingsList } from 'types/trainings';
import { TRAININGS } from 'utils/constants/end-points';
import Http from './http';

class TrainingsService {
	fetchById = (trainingId: IdType): Promise<Training> => Http.get<Training>(
		TRAININGS + '/user', { params: { id:trainingId } },
	);

	fetchAllByCategory = (categoryId: IdType): Promise<TrainingsList> => Http.get<TrainingsList>(
		TRAININGS + '/all/user', { params: { categoryId } },
	);

	fetchFeed = (): Promise<TrainingsList> => Http.get<TrainingsList>(
		TRAININGS + '/feed',
	);
}

export default new TrainingsService();
