import { BaseMessageDto, IdType } from 'types/common';
import { LeadDto, Lead, LeadsFilter } from 'types/leads';
import { LEADS } from 'utils/constants/end-points';
import Http from './http';

class LeadsService {
	fetchLeads = (filter: LeadsFilter): Promise<Lead[]> => Http.get(`${LEADS}/all/user`, { params: { ...filter } });

	addLead = (leadDto: LeadDto): Promise<Lead> => Http.post(LEADS, { ...leadDto });

	updateLead = (id: IdType, lead: LeadDto): Promise<Lead> => Http.put(
		LEADS, { ...lead }, { params: { id: id.toString() } });

	deleteLead = (id: IdType): Promise<BaseMessageDto> => Http.delete(LEADS, { params: { id: id.toString() } });
}

export default new LeadsService();
