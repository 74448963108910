import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import GlossaryService from 'services/glossary-service';
import { FetchStatus } from 'types/common';
import { Glossary, GlossaryEnum } from 'types/glossary';
import { RootState } from './index';

type Glossaries = { [k in GlossaryEnum]: Glossary };

type GlossaryState = {
	data: Glossaries;
	status: FetchStatus;
};

const initialState: GlossaryState = {
	data: {
		[GlossaryEnum.BANKS]: null,
		[GlossaryEnum.COUNTRIES]: null,
		[GlossaryEnum.LEGAL_NATURE_TYPES]: null,
	},
	status: FetchStatus.IDLE,
};

export const fetchGlossaryAsync = createAsyncThunk(
	'',
	async (glossary: GlossaryEnum) => {
		const response: Glossary = await GlossaryService.getGlossary(glossary);

		return {
			glossary, response,
		};
	},
);

export const glossarySlice = createSlice({
	name: '/glossary',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGlossaryAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchGlossaryAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data[action.payload.glossary] = action.payload.response;
			});
	},
});

const getGlossary = (state: RootState) => state.glossary;

export const getGlossaryData = <T extends Glossary>(glossaryName: GlossaryEnum) => createSelector(
	getGlossary, glossary => glossary.data?.[glossaryName] as T || null,
);

export default glossarySlice.reducer;
