import { BaseMessageDto, IdType } from 'types/common';
import { UserInvitedResponse } from 'types/group';
import { GroupInvitationLinkRespose, GroupInvitesFilter, GroupInvitesListItem } from 'types/group-invites';
import { GroupMembersFilter, GroupMembersListItem } from 'types/group-members';
import { GroupMembersRankingItem } from 'types/rating';
import { GROUPS_USERS } from 'utils/constants/end-points';
import Http from './http';

class GroupsUsersService {
	acceptJoinGroup = (groupId: IdType): Promise<BaseMessageDto> => Http.patch(
		`${GROUPS_USERS}/accept`, null, { params: { groupId } },
	);

	getGroupInvitationLink = (): Promise<GroupInvitationLinkRespose> => Http.get(`${GROUPS_USERS}/invitation-link`);

	getGroupInvitesStatus = (filter: GroupInvitesFilter): Promise<GroupInvitesListItem[]> => Http.get(
		`${GROUPS_USERS}/group-members/status`, { params: { ...filter } },
	);

	getGroupMembers = (filter: GroupMembersFilter): Promise<GroupMembersListItem[]> => Http.get(
		`${GROUPS_USERS}/group-members/user`, { params: { ...filter } },
	);

	getGroupMembersRanking = (groupId: IdType): Promise<GroupMembersRankingItem[]> => Http.get(
		`${GROUPS_USERS}/rating/top/user`, { params: { groupId } },
	);

	inviteToGroup = (userId: IdType): Promise<BaseMessageDto> => Http.patch(
		`${GROUPS_USERS}/invite-to-group`, null, { params:{ userId } },
	);

	joinGroupByLink = (token: string): Promise<BaseMessageDto> => Http.patch(
		`${GROUPS_USERS}/join-group-invitation-link`, { token },
	);

	leaveGroup = (): Promise<BaseMessageDto> => Http.delete(`${GROUPS_USERS}/leave`);

	rejectJoinGroup = (groupId: IdType): Promise<BaseMessageDto> => Http.patch(
		`${GROUPS_USERS}/reject`, null, { params: { groupId } },
	);

	removeGroupMember = (userId: IdType): Promise<BaseMessageDto> => Http.put(
		`${GROUPS_USERS}/ungroup`, null, { params: { userId } });

	userIsInvited = (groupId: IdType): Promise<UserInvitedResponse> => Http.get(
		`${GROUPS_USERS}/is-invited`, { params: { groupId } });
}

export default new GroupsUsersService();
