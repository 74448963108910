import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PageTitle from 'components/page-title';
import UserInfoEditPanel from 'components/user-info-edit-panel';
import UserInfoPanel from 'components/user-info-panel';
import { getUserId } from 'store/auth';
import { fetchGlossaryAsync } from 'store/glossary';
import { fetchUserAddressAsync } from 'store/user-address';
import { fetchUserBankAccountAsync } from 'store/user-bank-account';
import { fetchUserPersonalAsync } from 'store/user-personal';
import { fetchUserPixAsync } from 'store/user-pix';
import { getUserProfileData, fetchUserProfileAsync } from 'store/user-profile';
import { IdType } from 'types/common';
import { GlossaryEnum } from 'types/glossary';
import { UserProfile } from 'types/user-profile';
import { Dictionary } from 'utils/constants/dictionary';
import { RatingStarsMode } from 'utils/constants/rating';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import PersonalAddressInfo from './personal-address-info';
import PersonalAddressInfoEdit from './personal-address-info-edit';
import PersonalDocuments from './personal-documents';
import ProfilePersonalInfo from './profile-personal-info';
import ProfilePersonalInfoEdit from './profile-personal-info-edit';
import './ProfilePersonalData.scss';
import PaymentData from './payment-data';
import PaymentDataEdit from './payment-data-edit';

const ProfilePersonalData = (): JSX.Element => {
	const dispatch = useAppDispatch();

	const [addressInfoEdit, setAddressInfoEdit] = useState<boolean>(false);
	const [paymentDataEdit, setPaymentDataEdit] = useState<boolean>(false);
	const [personalInfoEdit, setPersonalInfoEdit] = useState<boolean>(false);
	const [userInfoEdit, setUserInfoEdit] = useState<boolean>(false);

	const profile: UserProfile | null = useAppSelector(getUserProfileData);
	const userId: IdType | null = useAppSelector(getUserId);

	useEffect(() => {
		if (userId) {
			dispatch(fetchUserProfileAsync(userId));
		}
	}, [dispatch, userId]);

	useEffect(() => { dispatch(fetchGlossaryAsync(GlossaryEnum.BANKS)); }, [dispatch]);
	useEffect(() => { dispatch(fetchGlossaryAsync(GlossaryEnum.COUNTRIES)); }, [dispatch]);

	useEffect(() => { dispatch(fetchUserBankAccountAsync()); }, [dispatch]);
	useEffect(() => { dispatch(fetchUserPixAsync()); }, [dispatch]);
	useEffect(() => { dispatch(fetchUserPersonalAsync()); }, [dispatch]);
	useEffect(() => { dispatch(fetchUserAddressAsync()); }, [dispatch]);

	return (
		<div className="profile-personal-data">
			<PageTitle backLink="/profile" defaultText="Personal Data" textId={Dictionary.personalDataPage.title} />

			<div className="card">
				<div className="card-body card-body_black">
					{profile && (userInfoEdit ? (
						<UserInfoEditPanel
							onClickSave={() => setUserInfoEdit(false)}
							profile={profile}
							ratingStarsMode={RatingStarsMode.WHITE}
						/>
					) : (
						<UserInfoPanel
							editable
							onClickEdit={() => setUserInfoEdit(true)}
							profile={profile}
							ratingStarsMode={RatingStarsMode.WHITE}
						/>
					))}
				</div>
			</div>

			<div className="card" id="personal-documents">
				<div className="page-block-title">
					<FormattedMessage
						defaultMessage="Your documents"
						id={Dictionary.personalDataPage.personalDocuments.title}
					/>
				</div>

				<div className="card-body">
					<PersonalDocuments />
				</div>
			</div>

			<div className="card">
				<div className="page-block-title">
					<FormattedMessage
						defaultMessage="Personal Information"
						id={Dictionary.personalDataPage.personalInformation.title}
					/>
				</div>

				<div className="card-body card-body_gray">
					{personalInfoEdit
						? <ProfilePersonalInfoEdit onClickSave={() => setPersonalInfoEdit(false)} />
						: <ProfilePersonalInfo onClickEdit={() => setPersonalInfoEdit(true)} />
					}
				</div>
			</div>

			<div className="card">
				<div className="page-block-title">
					<FormattedMessage
						defaultMessage="Address"
						id={Dictionary.personalDataPage.address.title}
					/>
				</div>

				<div className="card-body card-body_gray">
					{addressInfoEdit
						? <PersonalAddressInfoEdit onClickSave={() => setAddressInfoEdit(false)} />
						: <PersonalAddressInfo onClickEdit={() => setAddressInfoEdit(true)} />
					}
				</div>
			</div>

			<div className="card">
				<div className="page-block-title">
					<FormattedMessage
						defaultMessage="Payment Data"
						id={Dictionary.personalDataPage.paymentData.title}
					/>
				</div>

				<div className="card-body card-body_gray">
					{paymentDataEdit
						? <PaymentDataEdit onClickSave={() => setPaymentDataEdit(false)} />
						: <PaymentData onClickEdit={() => setPaymentDataEdit(true)} />
					}
				</div>
			</div>
		</div>
	);
};

export default ProfilePersonalData;
