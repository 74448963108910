import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { addMemberIcon, checkWhite, crossWhite, membersIcon } from 'assets/images';
import ConfirmModal from 'components/confirm-modal';
import Button from 'components/forms/button';
import GroupInfoEditPanel from 'components/group-info-edit-panel';
import GroupInfoPanel from 'components/group-info-panel';
import Message from 'components/message';
import PageTitle from 'components/page-title';
import UserInfoPanel from 'components/user-info-panel';
import GroupsUsersService from 'services/groups-users-service';
import { fetchGlossaryAsync } from 'store/glossary';
import { fetchGroupDataAsync, getGroupData } from 'store/group';
import { getUserProfileData } from 'store/user-profile';
import { BaseMessageDto, IdType } from 'types/common';
import { GlossaryEnum } from 'types/glossary';
import { Group } from 'types/group';
import { UserProfile } from 'types/user-profile';
import GroupSalesStatistics from 'views/group/group-sales-statistics';
import { Dictionary } from 'utils/constants/dictionary';
import { RatingStarsMode } from 'utils/constants/rating';
import ROUTES from 'utils/constants/routes';
import { useAppDispatch, useAppSelector, useWindow } from 'utils/hooks';
import GroupImages from '../group-images';
import GroupInvitationModal from '../group-invitation-modal';
import GroupMembersRanking from '../group-members-ranking';
import './GroupPage.scss';

type Props = {
	groupId: IdType;
};

const GroupPage = ({ groupId }: Props): JSX.Element | null => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [confirmModal, showConfirmModal, hideConfirmModal] = useWindow();
	const [invitationModal, showInvitationModal, hideInvitationModal] = useWindow();

	const [groupInfoEdit, setGroupInfoEdit] = useState<boolean>(false);
	const [userInvited, setUserInvited] = useState<boolean | null>(null);

	const groupData: Group | null = useAppSelector(getGroupData);
	const userProfile: UserProfile | null = useAppSelector(getUserProfileData);

	const token: string | null = searchParams.get('token');

	useEffect(() => {dispatch(fetchGlossaryAsync(GlossaryEnum.COUNTRIES));}, [dispatch]);

	useEffect(() => {
		if (!groupData || groupData.id !== groupId) {
			dispatch(fetchGroupDataAsync(groupId));
		}
	}, [dispatch, groupData, groupId]);

	useEffect(() => {
		if (!userProfile?.groupId && userInvited === null) {
			GroupsUsersService.userIsInvited(groupId).then(res => {setUserInvited(res.status);});
		}
	}, [groupId, userInvited, userProfile?.groupId]);

	useEffect(() => {
		if (token && userProfile && userProfile.groupId) {
			navigate(`/${ROUTES.GROUP.BASE}`);
		}
	}, [navigate, token, userProfile]);

	if (!groupData || !userProfile) return null;

	const isUsersGroup: boolean = userProfile.groupId === groupData.id;
	const isUserGroupCreator = userProfile.id === groupData.creator.id;

	const navigateToGroupPage = (res: BaseMessageDto): void => {
		Message.success({ messageText: res.message });
		navigate(`/${ROUTES.GROUP.BASE}`);
	};

	const rejectFulfilled = (): void => {
		navigate('/');
	};

	const handleClickAccept = (): void => {
		token
			? GroupsUsersService.joinGroupByLink(token).then(res => navigateToGroupPage(res))
			: GroupsUsersService.acceptJoinGroup(groupId).then(res => navigateToGroupPage(res));
	};

	const handleClickReject = (): void => {
		token
			? rejectFulfilled()
			: GroupsUsersService.rejectJoinGroup(groupId).then(() => rejectFulfilled());
	};

	const handleClickLeave = (): void => {
		GroupsUsersService.leaveGroup().then(res => {
			if (res) {
				hideConfirmModal();
				navigate('/');
			}
		});
	};

	return (
		<div className="group-page">
			{isUsersGroup && <PageTitle defaultText="Your Group" textId={Dictionary.groupPage.title} />}

			{(userInvited || token) && (
				<div className="group-page__join-btns">
					<Button
						defaultText="Accept"
						imgSrc={checkWhite}
						onClick={handleClickAccept}
						textId={Dictionary.common.accept}
					/>

					<h2>
						<FormattedMessage defaultMessage="Join group" id={Dictionary.groupPage.joinGroup} />
					</h2>

					<Button
						defaultText="Reject"
						imgSrc={crossWhite}
						onClick={handleClickReject}
						textId={Dictionary.common.reject}
					/>
				</div>
			)}

			<div className="card">
				<div className="card-body card-body_black">
					{groupInfoEdit
						? <GroupInfoEditPanel group={groupData} onClickSave={() => setGroupInfoEdit(false)} />
						: (
							<GroupInfoPanel
								editable={isUserGroupCreator}
								group={groupData}
								onClickEdit={() => setGroupInfoEdit(true)}
							/>
						)
					}
				</div>
			</div>

			<div className="card">
				<div className="page-block-title">
					<FormattedMessage defaultMessage="Leader" id={Dictionary.groupPage.leader.title} />
				</div>

				<UserInfoPanel
					className="card-body_black"
					linkUserAvatar={!isUserGroupCreator}
					profile={groupData.creator}
					ratingStarsMode={RatingStarsMode.WHITE}
					showGroupAvatar={false}
				/>

			</div>

			<div className="card">
				<div className="page-block-title">
					<FormattedMessage
						defaultMessage="Total enrollments"
						id={Dictionary.common.totalEnrollments.title}
					/>
				</div>
				<GroupSalesStatistics groupId={groupData.id} />
			</div>

			<div className="card">
				<div className="card-header">
					<div className="page-block-title">
						<FormattedMessage defaultMessage="Members" id={Dictionary.groupPage.members} />
					</div>

					{isUserGroupCreator && (
						<div className="group-page__group-administration-btns">
							<Link
								className="btn btn-purple group-administration-btn"
								to={`/group/${groupData.id}/members`}
							>
								<img alt="" src={membersIcon} />
								<FormattedMessage defaultMessage="Members list" id={Dictionary.groupPage.membersList} />
							</Link>

							<Button
								className="group-administration-btn"
								defaultText="Invite new members"
								imgSrc={addMemberIcon}
								onClick={showInvitationModal}
								textId={Dictionary.groupPage.inviteNewMembers}
							/>
						</div>
					)}
				</div>

				<div className="card-body">
					<GroupMembersRanking groupId={groupData.id} />
				</div>
			</div>

			<GroupImages allowEdit={isUserGroupCreator} groupId={groupData.id} />

			{
				isUsersGroup && !isUserGroupCreator && (
					<div className="group-page__leave-group">
						<Button
							defaultText="Leave group"
							onClick={showConfirmModal}
							textId={Dictionary.groupPage.leaveGroup}
						/>
					</div>
				)
			}

			{confirmModal && <ConfirmModal onClose={hideConfirmModal} onOk={handleClickLeave} />}

			{invitationModal && <GroupInvitationModal groupId={groupData.id} onClose={hideInvitationModal} />}
		</div>
	);
};

export default GroupPage;
