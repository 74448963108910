import { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { lockWhite, checkWhite, pencilWhite } from 'assets/images';
import Button from 'components/forms/button';
import { InputText } from 'components/inputs-formik';
import { useSecuritySettingsValidatingSchema } from 'utils/validation/schemas';
import { Dictionary } from 'utils/constants/dictionary';
import './SecuritySettingsForm.scss';

export type SecuritySettingsFormValues = {
	phoneNumber: string | undefined;
};

type Props = {
	phoneNumber: string | undefined;
	changeBtnTextDefault: string;
	changeBtnTextId: string;
	submitBtnTextDefault: string;
	submitBtnTextId: string;
	onSubmitPhoneNumber: (phoneNumber: string) => void;
};

const SecuritySettingsForm = ({
	phoneNumber,
	changeBtnTextId,
	changeBtnTextDefault,
	submitBtnTextDefault,
	submitBtnTextId,
	onSubmitPhoneNumber,
}: Props): JSX.Element => {
	const [isPhoneNumberEdit, setIsPhoneNumberEdit] = useState(false);

	const InnerForm = ({ submitForm }: FormikProps<SecuritySettingsFormValues>): JSX.Element => {
		const handleSubmitPhoneNumber = async () => {
			if (isPhoneNumberEdit) {
				await submitForm();
			} else {
				setIsPhoneNumberEdit(true);
			}
		};

		return (
			<Form className="security-settings__form security-settings-form">
				<div className="security-settings-form__top">
					<img alt="" src={lockWhite} />
				</div>
				<div className="security-settings-form__body">
					<div className="security-settings-form__item">
						<InputText
							defaultLabel="Phone number"
							disabled={!isPhoneNumberEdit}
							labelId={Dictionary.securitySettingsPage.phoneNumber}
							name="phoneNumber"
						/>
						<Button
							className={isPhoneNumberEdit ? 'btn-green' : 'btn-purple'}
							defaultText={isPhoneNumberEdit ? submitBtnTextDefault : changeBtnTextDefault}
							imgSrc={isPhoneNumberEdit ? checkWhite : pencilWhite}
							onClick={handleSubmitPhoneNumber}
							textId={isPhoneNumberEdit ? submitBtnTextId : changeBtnTextId}
							type={isPhoneNumberEdit ? 'submit' : undefined}
						/>
					</div>
				</div>
			</Form>
		);
	};

	return (
		<Formik
			component={InnerForm}
			enableReinitialize
			initialValues={{ phoneNumber }}
			onSubmit={(values)=>{
				if (values.phoneNumber) {
					onSubmitPhoneNumber(values.phoneNumber);
					setIsPhoneNumberEdit(false);
				}
			}}
			validationSchema={useSecuritySettingsValidatingSchema()}
		/>
	);
};

export default SecuritySettingsForm;
