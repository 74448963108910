import { SyntheticEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
	plusWhite, pencilPurple, plusWhiteCircle, trashWhite,
	crossWhite, reportIcon, arrowRightWhite,
} from 'assets/images';
import Button from 'components/forms/button';
import { InputImageFile } from 'components/inputs';
import ReportModal from 'components/report-modal';
import { uploadImageAsync } from 'store/upload';
import { IdType, ImageContainer } from 'types/common';
import { ComplaintType } from 'types/complaints';
import { ImageListItem } from 'types/images';
import { UploadImageResponse } from 'types/upload';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useWindow } from 'utils/hooks';
import ImageGalleryItem from './image-gallery-item';
import './ImageGallery.scss';

type Props = {
	complaintType: ComplaintType;
	containerName: ImageContainer;
	defaultMessage: string;
	items: ImageListItem[];
	labelId: string;
	showEdit?: boolean;
	onAdd?: (imageId: IdType) => void;
	onDelete?: (imageIds: IdType[]) => void;
};

const ImageGallery = ({
	complaintType, containerName, defaultMessage, items,
	labelId, showEdit, onAdd, onDelete,
}: Props): JSX.Element => {
	const dispatch = useAppDispatch();
	const [editMode, setEditMode] = useState<boolean>(false);
	const [reportMode, setReportMode] = useState<boolean>(false);
	const [selectedIds, setSelectedIds] = useState<IdType[]>([]);

	const [reportModal, showReportModal, hideReportModal] = useWindow();

	const showReport = !showEdit;

	const clearSelectedValues = (): void => {
		selectedIds.length && setSelectedIds([]);
	};

	const handleSelect = (id: IdType) => selectedIds.includes(id)
		? setSelectedIds(selectedIds.filter(item => item != id))
		: setSelectedIds([...selectedIds, id]);

	const handleClickRemove = (): void => {
		onDelete && onDelete(selectedIds);
		clearSelectedValues();
		setEditMode(false);
	};

	const handleClickEdit = (): void => {
		setEditMode(true);
	};

	const handleCancelEdit = (): void => {
		setEditMode(false);
		clearSelectedValues();
	};

	const handleClickReport = (): void => {
		setReportMode(true);
	};

	const handleCancelReport = (): void => {
		setReportMode(false);
		clearSelectedValues();
	};

	const handleChangeInputFile = (e: SyntheticEvent): void => {
		const target = e.target as HTMLInputElement;
		const file: File | null = target.files && target.files[0];

		if (file) {
			//TODO: image size validation
			dispatch(uploadImageAsync({ file, type: containerName }))
				.then(res => onAdd && onAdd((res.payload as UploadImageResponse).imageId));
		}
	};

	const handleReportFulfilled = (): void => {
		clearSelectedValues();
		setReportMode(false);
		hideReportModal();
	};

	const EditMenuButtons = (): JSX.Element => (
		<div className="image-gallery__buttons">
			<Button
				className="image-gallery__buttons-btn image-gallery__buttons-btn-purple cancel"
				defaultText="Cancel"
				imgSrc={crossWhite}
				onClick={handleCancelEdit}
				textId={Dictionary.common.cancel}
			/>

			<Button
				className="image-gallery__buttons-btn image-gallery__buttons-btn-purple remove"
				defaultText="Remove"
				disabled={!selectedIds.length}
				imgSrc={trashWhite}
				onClick={handleClickRemove}
				textId={Dictionary.common.remove}
			/>
		</div>
	);

	const ReportMenuButtons = (): JSX.Element => (
		<div className="image-gallery__buttons">
			<Button
				className="image-gallery__buttons-btn image-gallery__buttons-btn-purple cancel"
				defaultText="Cancel"
				imgSrc={crossWhite}
				onClick={handleCancelReport}
				textId={Dictionary.common.cancel}
			/>

			<Button
				className="image-gallery__buttons-btn image-gallery__buttons-btn-purple send"
				defaultText="Send"
				disabled={!selectedIds.length}
				imgSrc={arrowRightWhite}
				onClick={showReportModal}
				textId={Dictionary.common.send}
			/>
		</div>
	);

	const ImageControlButtons = (): JSX.Element => (
		<div className="image-gallery__buttons">
			<div className="image-gallery__buttons-btn image-gallery__buttons-btn-add">
				<img alt="" src={plusWhiteCircle} />
				<FormattedMessage defaultMessage="Add photo" id={Dictionary.common.photos.addPhoto} />
				<InputImageFile id="add-photo-input_top" onChange={handleChangeInputFile} />
			</div>
			<Button
				className="image-gallery__buttons-btn-link"
				defaultText="Edit"
				imgSrc={pencilPurple}
				onClick={handleClickEdit}
				textId={Dictionary.common.edit}
			/>
		</div>
	);

	return (
		<div className="card image-gallery">
			<div className="card-header">
				{(showEdit || (!showEdit && !!items.length)) && (
					<div className="page-block-title">
						<FormattedMessage defaultMessage={defaultMessage} id={labelId} />
					</div>
				)}

				{showEdit && !editMode && !!items.length && (
					<ImageControlButtons />
				)}

				{showEdit && editMode && (
					<EditMenuButtons />
				)}

				{showReport && !reportMode && !!items.length && (
					<div className="image-gallery__buttons">
						<Button
							className="image-gallery__buttons-btn-link image-gallery__buttons-btn-report"
							defaultText="Report"
							imgSrc={reportIcon}
							onClick={handleClickReport}
							textId={Dictionary.common.report}
						/>
					</div>
				)}

				{showReport && reportMode && (
					<ReportMenuButtons />
				)}
			</div>

			<div>
				<div className="image-gallery__wrap">
					{items.map((item) => (
						<div className="image-gallery__item" key={item.id}>
							<ImageGalleryItem
								{...item}
								containerName={containerName}
								onClickSelectIcon={handleSelect}
								selected={selectedIds.includes(item.id)}
								showSelectIcon={editMode || reportMode}
							/>
						</div>
					))}

					{showEdit && !items.length && (
						<div className="image-gallery__item image-gallery__item_add-photo">
							<div className="image-gallery__item-add-photo-btn">
								<img alt="" src={plusWhite} />
							</div>
							<div className="image-gallery__item-add-photo-label">
								<FormattedMessage defaultMessage="Add photo" id={Dictionary.common.photos.addPhoto} />
							</div>
							<InputImageFile
								id="add-photo-input_inner"
								labelId={Dictionary.common.photos.addPhoto}
								onChange={handleChangeInputFile}
							/>
							<div className="image-gallery__item-add-photo-text">
								<FormattedMessage
									defaultMessage="Only .jpg and .png"
									id={Dictionary.common.photos.photoFormatMsg}
								/>
							</div>
						</div>
					)}
				</div>

				{reportModal && (
					<ReportModal
						groupImageIds={complaintType === ComplaintType.GROUP_IMAGES ? selectedIds : null}
						onClose={hideReportModal}
						onFulfilled={handleReportFulfilled}
						userImageIds={complaintType === ComplaintType.USER_IMAGES ? selectedIds : null}
					/>
				)}
			</div>
		</div>

	);
};

export default ImageGallery;

