import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import Avatar from 'components/avatar';
import EditAvatar from 'components/edit-avatar';
import ButtonSave from 'components/forms/button-save';
import ButtonSaveRound from 'components/forms/button-save-round';
import { InputText } from 'components/inputs-formik';
import Message from 'components/message';
import UserRating from 'components/rating/user-rating';
import { getGlossaryData } from 'store/glossary';
import { updateUserProfileAsync } from 'store/user-profile';
import { AppTheme, ImageContainer } from 'types/common';
import { Country, GlossaryEnum } from 'types/glossary';
import { UpdateProfileRequest, UserProfile } from 'types/user-profile';
import { Dictionary } from 'utils/constants/dictionary';
import { RatingStarsMode } from 'utils/constants/rating';
import getUserLocation from 'utils/helpers/get-user-location';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './UserInfoEditPanel.scss';

type Props = {
	className?: string;
	profile: UserProfile;
	ratingStarsMode?: RatingStarsMode;
	onClickSave: () => void;
};

type FormValues = UpdateProfileRequest;

const UserInfoEditPanel = ({
	className, profile, ratingStarsMode = RatingStarsMode.BLACK, onClickSave,
}: Props): JSX.Element => {
	const dispatch = useAppDispatch();

	const countries: Country[] | null = useAppSelector(getGlossaryData<Country[] | null>(GlossaryEnum.COUNTRIES));

	const handleSubmit = (values: FormValues) => {
		dispatch(updateUserProfileAsync(values)).then((res) => {
			if (res.payload) {
				Message.success({ messageId: Dictionary.message.success.changesSaved });
				onClickSave();
			}
		});
	};

	const InnerForm = ({ values, setFieldValue }: FormikProps<FormValues>): JSX.Element => (
		<Form className={`user-info-edit-panel ${className || ''}`}>
			<div className="user-info-edit-panel__left">
				<div className="user-info-edit-panel__avatars">
					<EditAvatar
						className="user-info-edit-panel__profile-avatar"
						containerName={ImageContainer.USER_AVATAR}
						imageId={values.avatarImageId}
						onChange={(val) => setFieldValue('avatarImageId', val)}
					/>

					{profile.groupAvatarImageId && (
						<Avatar
							alt="user-group-avatar"
							className="user-info-edit-panel__group-avatar"
							containerName={ImageContainer.GROUP_AVATAR}
							imageId={profile.groupAvatarImageId}
						/>
					)}
				</div>

				<div className="user-info-edit-panel__user-data">
					<InputText name="nickname" />
					<h3 className="user-info-edit-panel__user-location">
						{getUserLocation(profile, countries || [])}
					</h3>
				</div>
			</div>

			<div className="user-info-edit-panel__right">
				<UserRating
					levelTitle={profile.levelTitle}
					ratingStarsMode={ratingStarsMode}
					stars={profile.stars}
					theme={AppTheme.DARK}
				/>

				{/* lg-xl */}
				<ButtonSave />

				{/* xs-md */}
				<ButtonSaveRound />
			</div>
		</Form>
	);

	return (
		<Formik
			component={InnerForm}
			initialValues={{ nickname: profile.nickname, avatarImageId: profile.avatarImageId }}
			onSubmit={handleSubmit}
		/>
	);
};

export default UserInfoEditPanel;
