import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import SalesService from 'services/sales-service';
import { DateRangeFilter, FetchStatus, ResultState } from 'types/common';
import { SalesHistoryData } from 'types/sales';
import { RootState } from './index';

const initialState: ResultState<SalesHistoryData | null> = {
	data: null,
	status: FetchStatus.IDLE,
	error: null,
};

export const fetchSalesHistoryAsync = createAsyncThunk(
	'sales/history',
	async (filter: DateRangeFilter) => {
		const response: SalesHistoryData = await SalesService.getSalesHistory(filter);

		return response;
	},
);

export const salesHistorySlice = createSlice({
	name: 'salesHistory',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSalesHistoryAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchSalesHistoryAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			});
	},
});

const getSalesHistory = (state: RootState) => state.salesHistory;

export const getSalesHistoryData = createSelector(getSalesHistory, salesHistory => salesHistory.data);

export default salesHistorySlice.reducer;
