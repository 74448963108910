import { IdType, ImageContainer } from 'types/common';
import { DOWNLOAD } from 'utils/constants/end-points';
import Http from './http';

class DownloadService {
	private download = (url: string, params?: Record<string, unknown>): Promise<File> =>
		Http.get(`${DOWNLOAD}/${url}`, { params });

	downloadImage = (imageId: IdType, containerName: ImageContainer): Promise<File> => this.download(
		'invoice', { imageId, containerName },
	);

	downloadInvoice = (invoiceId: IdType): Promise<File> => this.download('invoice', { invoiceId });
}

export default new DownloadService();
