import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UploadService from 'services/upload-service';
import UserService from 'services/user-service';
import { FetchStatus } from 'types/common';
import { UploadImageRequest, UploadImageResponse } from 'types/upload';
import { UpdateProfileRequest, UpdateProfileResponse } from 'types/user-profile';

type UploadState = {
	status: FetchStatus;
};

const initialState: UploadState = {
	status: FetchStatus.IDLE,
};

export const uploadImageAsync = createAsyncThunk(
	'(POST) upload/image',
	async (request: UploadImageRequest) => {
		const response: UploadImageResponse = await UploadService.uploadImage(request);

		return response;
	},
);

export const uploadVideoAsync = createAsyncThunk(
	'(PUT) users/profile',
	async (data: UpdateProfileRequest) => {
		const response: UpdateProfileResponse = await UserService.updateUserProfile(data);

		return response;
	},
);

export const uploadSlice = createSlice({
	name: 'upload',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(uploadImageAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(uploadImageAsync.fulfilled, (state) => {
				state.status = FetchStatus.IDLE;
			})

			.addCase(uploadVideoAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(uploadVideoAsync.fulfilled, (state) => {
				state.status = FetchStatus.IDLE;
			});
	},
});

export default uploadSlice.reducer;
