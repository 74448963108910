import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { appLogoWhite, arrowDownWhite, coursesPageLogo2, flagEN, trolley } from 'assets/images';
import CoursesLink from 'components/forms/courses-link';
import { TextPurple } from 'components/text-formatters';
import { BaseEntity } from 'types/common';
import { Dictionary } from 'utils/constants/dictionary';
import NotFound from 'views/not-found';
import './CoursesPage.scss';

type LanguageCourse = BaseEntity & {
	description: string;
	link: string;
	name: string;
};

const coursesMock: LanguageCourse[] = [
	{
		id: 1,
		createdAt: '',
		updatedAt: '',
		deletedAt: null,
		name: 'Fluency Academy [S] [R]',
		description: 'Aprenda tudo sobre inglês com os melhores professores',
		link: 'https://pay.hotmart.com/I62979451H?off=wzspcy3r&checkoutMode=10&bid=1672665112480',
	},
];

const CourseItem = ({ description, link, name }: LanguageCourse): JSX.Element => (
	<div className="course-item">
		<div className="course-item__avatar">
			<img alt="" loading="lazy" src={flagEN} />
		</div>

		<div className="course-item__data">
			<div className="course-item__name">
				{name}
			</div>
			<div className="course-item__description">
				{description}
			</div>
		</div>

		<CoursesLink
			defaultText="Purchase course"
			href={link}
			imgSrc={trolley}
			target="_blank"
			textId={Dictionary.coursesPage.available.buyLinkText}
		/>
	</div>
);

const CoursesPage = (): JSX.Element => {
	const [searchParams] = useSearchParams();

	const src: string | null = searchParams.get('src');

	if (!src) return <NotFound />;

	return (
		<div className="courses-page__wrap">
			<div className="courses__promo">
				<div className="courses__promo_header">
					<img alt="" className="image logo1" loading="lazy" src={appLogoWhite} />
					<img alt="" className="image logo2" loading="lazy" src={coursesPageLogo2} />
				</div>
				<div className="courses__promo_text-container">
					<div className="courses__promo_text">
						<FormattedMessage
							defaultMessage="Be a <purple>striker</purple> right now,{br}choose one of the courses below"
							id={Dictionary.coursesPage.promo}
							values={{
								br: <br />,
								purple: TextPurple,
							}}
						/>
					</div>
				</div>
				<div className="courses__promo_footer">
					<CoursesLink
						defaultText="Go to courses"
						href="#courses-available"
						imgSrc={arrowDownWhite}
						textId={Dictionary.coursesPage.promoBtnText}
					/>
				</div>
			</div>
			<div className="courses-available__wrap">
				<div className="courses-available-container" id="courses-available" style={{ color: '#fff' }}>
					<div className="courses-available__top">
						<FormattedMessage
							defaultMessage="Courses available"
							id={Dictionary.coursesPage.available.title}
						/>
					</div>

					<div className="courses-available__course-items_wrap">
						{coursesMock.map((item) => (
							//TEMP modify link
							<CourseItem key={item.id} {...item} link={item.link + `&src=${src}`} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CoursesPage;
