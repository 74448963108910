import { useSearchParams } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { lockWhite } from 'assets/images';
import Button from 'components/forms/button';
import { InputText } from 'components/inputs-formik';
import Message from 'components/message';
import PageTitle from 'components/page-title';
import UserService from 'services/user-service';
import { Dictionary } from 'utils/constants/dictionary';
import { useChangeEmailValidationSchema } from 'utils/validation/schemas';
import NotFound from 'views/not-found';
import './ProfileChangeEmail.scss';

type FormValues = {
	email: string;
};

const InnerForm = ({ dirty, isValid }: FormikProps<FormValues>): JSX.Element => (
	<Form className="security-settings__form change-email__form">
		<div className="security-settings-form__top">
			<img alt="" src={lockWhite} />
		</div>
		<div className="security-settings-form__body">
			<InputText
				defaultLabel="Email"
				labelId={Dictionary.changeEmailPage.email}
				name="email"
			/>
		</div>

		<div className="change-email__form-footer flex justify-content-center">
			<Button
				className="change-email__form-submit"
				defaultText="Submit"
				disabled={!dirty || !isValid}
				textId={Dictionary.common.submit}
				type="submit"
			/>
		</div>
	</Form>
);

const ProfileChangeEmail = (): JSX.Element => {
	const [searchParams] = useSearchParams();

	const validationSchema = useChangeEmailValidationSchema();

	const token: string | null = searchParams.get('token');

	if (!token) return <NotFound />;

	const handleSubmit = ({ email }: FormValues) => {
		if (token) {
			UserService.confirmNewEmail({ email, token })
				.then(res => res && Message.success({ messageText: res.message }));
		}
	};

	return (
		<div className="change-email">
			<PageTitle defaultText="Change Email" textId={Dictionary.changeEmailPage.title} />

			<div className="change-email__wrap">
				<Formik<FormValues>
					component={InnerForm}
					initialValues={{ email: '' }}
					onSubmit={handleSubmit}
					validationSchema={validationSchema}
				/>
			</div>
		</div>
	);
};

export default ProfileChangeEmail;
