/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import Message from 'components/message';
import { Rejected } from 'services/http';
import { store } from 'store';
import { signOut } from 'store/auth';

const responseRejected: Rejected = (error) => {

	if (error.response.data.statusCode === 401) {
		store.dispatch(signOut());
		return error;
	}

	if (error.response.data.message) {
		Message.error({ messageText: error.response.data.message });
	}

	return error;
};

export default responseRejected;
