export enum UserDocumentsStatus {
	NOT_LOADED = 'Not loaded',
	NOT_VERIFIED = 'Not verified',
	PROCESSING = 'Processing',
	VERIFIED = 'Verified',
}

export type UserDocumentStatusResponse = {
	status: UserDocumentsStatus;
	comment: string | null;
};
