import {
	UploadImageRequest, UploadImageResponse, UploadInvoiceResponse,
	UploadUserDocumentResponse, UploadVideoResponse,
} from 'types/upload';
import { UPLOAD } from 'utils/constants/end-points';
import Http from './http';

class UploadService {
	private upload = <T>(fileType: string, file: File, type?: string): Promise<T> => {
		const formData = new FormData();

		formData.append('file', file);

		return Http.post(`${UPLOAD}/${fileType}`, formData, { params: { type } });
	};

	uploadImage = ({ file, type }: UploadImageRequest): Promise<UploadImageResponse> => this.upload(
		'image', file, type);

	uploadInvoice = (file: File): Promise<UploadInvoiceResponse> => this.upload('invoice', file);

	uploadUserDocument = (file: File): Promise<UploadUserDocumentResponse> => this.upload('users-document', file);

	uploadVideo = (file: File): Promise<UploadVideoResponse> => this.upload('video', file);
}

export default new UploadService();
