import { useEffect } from 'react';
import ImageGallery from 'components/image-gallery';
import Message from 'components/message';
import { addUserImageAsync, deleteUserImagesAsync, fetchUserImagesAsync, getUserImagesData } from 'store/user-images';
import { IdType, ImageContainer } from 'types/common';
import { ComplaintType } from 'types/complaints';
import { ImageListItem } from 'types/images';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector } from 'utils/hooks';

type Props = {
	allowEdit?: boolean;
	profileId: IdType;
};

const ProfileImages = ({ allowEdit = false, profileId }: Props): JSX.Element | null => {
	const dispatch = useAppDispatch();

	const userImages: ImageListItem[] | null = useAppSelector(getUserImagesData);

	const addUserImage = (imageId: IdType) => {
		dispatch(addUserImageAsync(imageId))
			.then((res) => res.payload && Message.success({ messageId: Dictionary.message.success.imageAdded }));
	};

	const deleteUserImages = (imageIds: IdType[]) => {
		dispatch(deleteUserImagesAsync(imageIds))
			.then((res) => res.payload && Message.success({ messageId: Dictionary.message.success.imageDeleted }));
	};

	useEffect(() => {dispatch(fetchUserImagesAsync(profileId));}, [dispatch, profileId]);

	if (!userImages) return null;

	return (
		<div className="user-photos-edit">
			<ImageGallery
				complaintType={ComplaintType.USER_IMAGES}
				containerName={ImageContainer.USER_IMAGES}
				defaultMessage="Photos"
				items={userImages}
				labelId={Dictionary.common.photos.title}
				onAdd={addUserImage}
				onDelete={deleteUserImages}
				showEdit={allowEdit}
			/>
		</div>
	);

};

export default ProfileImages;

