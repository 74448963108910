import { IdType } from './common';

export enum GlossaryEnum {
	BANKS = 'banks',
	COUNTRIES = 'countries',
	LEGAL_NATURE_TYPES = 'legal-nature-types',
}

export interface GlossaryData {
	id: IdType;
}

interface CountryData {
	name: string;
	code: string;
}

export interface State extends GlossaryData, CountryData {}

export interface Country extends GlossaryData, CountryData {
	states: State[];
}

export type LegalNatureType = GlossaryData & {
	type: string;
};

export type Bank = GlossaryData & {
	name: string;
};

export type Glossary = Country[] | LegalNatureType[] | Bank[] | null;

