import { BaseEntity, IdType } from './common';

export enum ComplaintType {
	GROUP_AVATAR,
	GROUP_IMAGES,
	USER_AVATAR,
	USER_IMAGES,
}

export interface Complaint {
	isIndecent: boolean;
	isSensitive: boolean;
	isRude: boolean;
	description: string;
}

export interface ComplaintRequest extends Complaint {
	userId: IdType | null;
	groupId: IdType | null;
	userImageIds: IdType[] | null;
	groupImageIds: IdType[] | null;
}

type ResponseImageItem = {
	id: IdType;
	imageId: IdType;
};

export interface ComplaintResponse extends BaseEntity, Omit<ComplaintRequest, 'userImageIds' | 'groupImageIds'> {
	userAvatarImageId: IdType | null;
	groupAvatarImageId: IdType | null;
	userImages: ResponseImageItem[] | null;
	groupImages: ResponseImageItem[] | null;
}

