import { FormattedMessage } from 'react-intl';
import { Dictionary } from 'utils/constants/dictionary';
import { useUserProfile } from 'utils/hooks/use-user-profile';
import './UserGreeting.scss';

const UserGreeting = (): JSX.Element => {
	const user = useUserProfile();
	const username = user?.nickname || 'User';

	return (
		<div className="user-greeting">
			<FormattedMessage
				defaultMessage={`Hello, ${username}`}
				id={Dictionary.mainSider.userGreeting}
				values={{ username }}
			/>
		</div>
	);
};

export default UserGreeting;
