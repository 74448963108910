import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PageTitle from 'components/page-title';
import { fetchUserBalanceAsync } from 'store/user-balance';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch } from 'utils/hooks';
import HomeRanking from './home-ranking';
import MediasFeed from './medias-feed';
import TrainingsFeed from './trainings-feed';
import UserInfoMobile from './user-info-mobile/UserInfoMobile';
import UserReferralLinkMobile from './user-referral-link-mobile';
import UserSales from './user-sales';
import './Home.scss';

const Home = (): JSX.Element => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchUserBalanceAsync());
	}, [dispatch]);

	return (
		<div className="home-page">
			<PageTitle defaultText="Home" textId={Dictionary.homePage.title} />

			<div className="home-page__wrap">
				<UserInfoMobile />

				<UserReferralLinkMobile />

				<div className="left-column">
					<div className="card">
						<div className="page-block-title">
							<FormattedMessage
								defaultMessage="Statistics"
								id={Dictionary.homePage.salesStatistics.title}
							/>
						</div>
						<UserSales />
					</div>
				</div>

				<div className="right-column">
					<div className="card">
						<div className="page-block-title">
							<FormattedMessage
								defaultMessage="Weekly Ranking"
								id={Dictionary.homePage.weeklyRanking.title}
							/>
						</div>
						<HomeRanking />
					</div>
				</div>
			</div>

			<TrainingsFeed />
			<MediasFeed />
		</div>
	);
};

export default Home;

