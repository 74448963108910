import { FC } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import './Video.scss';

type Props = {
	link: string;
};

const Video: FC<Props> = ({ link }): JSX.Element => (
	<div className="Video">
		<Vimeo
			autoplay
			responsive={true}
			showByline={false}
			showPortrait={false}
			showTitle={false}
			video={link}
		/>
	</div>

);

export default Video;
