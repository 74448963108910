import { useEffect } from 'react';
import SalesStatistics from 'components/sales-statistics';
import { fetchUserStatisticsAsync, getUserStatisticsData } from 'store/user-statistics';
import { IdType } from 'types/common';
import { SalesStatisticsData } from 'types/sales';
import { useAppDispatch, useAppSelector } from 'utils/hooks';

type Props = {
	userId: IdType;
};

const ProfileSalesStatistics = ({ userId }: Props): JSX.Element | null => {
	const dispatch = useAppDispatch();

	const userStatistics: SalesStatisticsData | null = useAppSelector(getUserStatisticsData);

	useEffect(() => {dispatch(fetchUserStatisticsAsync(userId));}, [dispatch, userId]);

	if (!userStatistics) return null;

	return (
		<SalesStatistics {...userStatistics} />
	);
};

export default ProfileSalesStatistics;
