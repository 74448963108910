import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from 'components/page-title';
import Loader from 'components/loader/Loader';
import VideoItem from 'components/video/video-item';
import { fetchTrainingByIdAsync, getTrainingData, isTrainingLoading } from 'store/training';
import { Training } from 'types/trainings';
import { TRAININGS } from 'utils/constants/end-points';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import NotFound from 'views/not-found';
import TrainingsList from '../trainings-list';
import './Training.scss';

const TrainingItem: FC = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const training: Training | null = useAppSelector(getTrainingData);
	const isLoading: boolean = useAppSelector(isTrainingLoading);

	const id = Number(useParams<{ id: string | undefined }>()?.id);
	const validTraining: Training | null = training?.id === id ? training : null;

	useEffect(() => {
		!isNaN(id) && dispatch(fetchTrainingByIdAsync(id));
	}, [dispatch, id]);

	if (isLoading) return <Loader />;
	if (!validTraining) return <NotFound />;

	return (
		<div className="trainings">
			<VideoItem
				description={validTraining.description}
				files={validTraining.files}
				link={validTraining.link}
				pageTitle={<PageTitle backLink={'/' + TRAININGS} defaultText={validTraining.title} />}
				title={validTraining.title}
			/>
			<div className="training-item">
				<h2 className="training-item-title">
					{validTraining.categoryTitle}
				</h2>
				<TrainingsList categoryId={validTraining.categoryId} />
			</div>
		</div>
	);
};

export default TrainingItem;
