import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchMediasByIdAsync, getMediaData, isMediaLoading } from 'store/media';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { MediaItem } from 'types/medias';
import { MEDIAS } from 'utils/constants/end-points';
import PageTitle from 'components/page-title';
import VideoItem from 'components/video/video-item';
import Loader from 'components/loader/Loader';
import './Media.scss';

const Media = (): JSX.Element | null => {
	const dispatch = useAppDispatch();
	const media: MediaItem | null = useAppSelector(getMediaData);
	const isLoading: boolean = useAppSelector(isMediaLoading);

	const id = Number(useParams<{ id: string | undefined }>()?.id);
	const validMedia: MediaItem | null = media?.id === id ? media : null;

	useEffect(() => {
		!isNaN(id) && dispatch(fetchMediasByIdAsync(id));
	}, [dispatch, id]);

	if (isLoading) return <Loader />;
	if (!validMedia) return null;

	return (
		<div className="Media">
			<VideoItem
				description={validMedia.description}
				files={validMedia.files}
				link={validMedia.link}
				pageTitle={<PageTitle backLink={'/' + MEDIAS} defaultText={validMedia.title} />}
				title={validMedia.title}
			/>
		</div>
	);
};

export default Media;

