import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import UserService from 'services/user-service';
import { FetchStatus } from 'types/common';
import { RankingFilter, RankingWeekQuery, UserRankingItem } from 'types/rating';
import { RootState } from './index';

type UserRankingState = {
	currentWeek: UserRankingItem[] | null;
	lastWeek: UserRankingItem[] | null;
	all: UserRankingItem[] | null;
	status: FetchStatus;
};

const initialState: UserRankingState = {
	currentWeek: null,
	lastWeek: null,
	all: null,
	status: FetchStatus.IDLE,
};

export const fetchUserRankingTopAsync = createAsyncThunk(
	'users/rating/top',
	async (week: RankingWeekQuery) => {
		const response = await UserService.getUserRankingTop(week);

		return { week, response };
	},
);

export const fetchUserRankingAllAsync = createAsyncThunk(
	'users/rating/all',
	async (params?: RankingFilter) => {
		const response = await UserService.getUserRankingAll(params);

		return response;
	},
);

export const userRankingSlice = createSlice({
	name: 'userRanking',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			//Fetch top
			.addCase(fetchUserRankingTopAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchUserRankingTopAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				if (action.payload.week === RankingWeekQuery.CURRENT) {
					state.currentWeek = action.payload.response;
				}
				if (action.payload.week === RankingWeekQuery.LAST) {
					state.lastWeek = action.payload.response;
				}
			})
			// Fetch all
			.addCase(fetchUserRankingAllAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchUserRankingAllAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.all = action.payload;
			});
	},
});

const getUserRanking = (state: RootState) => state.userRanking;

export const getUserRankingCurrentWeek = createSelector(getUserRanking, userRanking => userRanking.currentWeek);
export const getUserRankingLastWeek = createSelector(getUserRanking, userRanking => userRanking.lastWeek);
export const getUserRankingAll = createSelector(getUserRanking, userRanking => userRanking.all);

export default userRankingSlice.reducer;
