import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InputText } from 'components/inputs';
import PageTitle from 'components/page-title';
import { fetchGlossaryAsync } from 'store/glossary';
import {
	fetchGroupInvitesAsync, getGroupInvitesData, getGroupInvitesFilter, setGroupInvitesFilter,
} from 'store/group-invites';
import { GlossaryEnum } from 'types/glossary';
import { GroupInvitesFilter, GroupInvitesListItem } from 'types/group-invites';
import { GROUP_INVITES_FILTER_DEFAULT } from 'utils/constants/default-filters';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { searchIcoWhite } from 'assets/images';
import GroupInvitesStatusSelector from './group-invites-status-selector';
import GroupInvitesTable from './group-invites-table';
import './GroupInvitesPage.scss';

const GroupInvitesPage = (): JSX.Element | null => {
	const dispatch = useAppDispatch();
	const { id } = useParams<{ id?: string }>();

	const [search, setSearch] = useState<string>('');

	const filter: GroupInvitesFilter = useAppSelector(getGroupInvitesFilter);
	const groupInvites: GroupInvitesListItem[] | null = useAppSelector(getGroupInvitesData);

	const submitSearchFilter = (search: string)=> {
		dispatch(setGroupInvitesFilter({ ...filter, search }));
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
		if (e.target.value === '') {
			dispatch(setGroupInvitesFilter({ ...filter, search: '' }));
		}
		setSearch(e.target.value);
	};

	const handlePressEnter = (e: KeyboardEvent<HTMLInputElement>): void => {
		dispatch(setGroupInvitesFilter({ ...filter, search: e.currentTarget.value }));
	};

	useEffect(() => {
		dispatch(fetchGlossaryAsync(GlossaryEnum.COUNTRIES));
	}, [dispatch]);

	useEffect(() => {
		dispatch(fetchGroupInvitesAsync(filter));
	}, [dispatch, filter, id]);

	useEffect(() => () => {
		dispatch(setGroupInvitesFilter(GROUP_INVITES_FILTER_DEFAULT));
	}, [dispatch]);

	if (!id) return null;

	return (
		<div className="group-invites">
			<div className="group-invites__top">
				<PageTitle
					backLink={`/group/${id}`}
					defaultText="Invited by you"
					textId={Dictionary.groupInvitesPage.title}
				/>

				<div className="group-invites__top-right">
					<div className="group-invites__top-search">
						<InputText
							defaultPlaceholder="Search"
							onChange={handleChange}
							onPressEnter={handlePressEnter}
							placeholderId={Dictionary.common.search}
							suffix={<img alt="" onClick={() => submitSearchFilter(search)} src={searchIcoWhite} />}
						/>
					</div>

					<div className="group-invites__top-sort">
						<GroupInvitesStatusSelector />
					</div>
				</div>
			</div>

			<GroupInvitesTable dataSource={groupInvites || []} />
		</div>
	);
};

export default GroupInvitesPage;
