import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { appLogoWhite } from 'assets/images';
import { InputPassword, InputText } from 'components/inputs-formik';
import Button from 'components/forms/button';
import { loginAsync } from 'store/auth';
import { Credentials } from 'types/auth';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch } from 'utils/hooks';
import { useCredentialsValidationSchema } from 'utils/validation/schemas';
import './Login.scss';

type LocationState = {
	from?: string;
	search?: string;
};

const InnerForm = ({ dirty, isValid }: FormikProps<Credentials>): JSX.Element => (
	<Form className="auth login">
		<div className="auth__form-wrapper">
			<div className="auth__form-header">
				<img alt="" src={appLogoWhite} />
			</div>

			<div className="auth__form-body">
				{/* <div className="auth__social">
					<div className="auth__social-title">Conecte-se</div>
					<div className="auth__social-items">
						<a className="auth__social-item" href="#">
							<img alt="" src={googlePlus} />
						</a>
						<a className="auth__social-item" href="#">
							<img alt="" src={twitter} />
						</a>
						<a className="auth__social-item" href="#">
							<img alt="" src={facebook} />
						</a>
					</div>
				</div> */}

				{/* <div className="auth__form-text">ou use sua conta</div> */}

				<div className="auth__form-title">Log in</div>

				<InputText defaultPlaceholder="Email" name="username" placeholderId={Dictionary.auth.email} />

				<InputPassword defaultPlaceholder="Password" name="password" placeholderId={Dictionary.auth.password} />
			</div>

			<div className="auth__form-footer">
				<Button
					defaultText="Login"
					disabled={!dirty || !isValid}
					textId={Dictionary.auth.login}
					type="submit"
				/>

				<div className="forgot-password-link">
					<a href="/auth/forgot-password">
						<FormattedMessage defaultMessage="Forgot Password" id={Dictionary.auth.forgotPassword} />
					</a>
				</div>
			</div>
		</div>
	</Form>
);

const Login = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const state = useLocation().state as LocationState;

	const handleSubmit = (credentials: Credentials): void => {
		dispatch(loginAsync(credentials)).then(() => {
			if (state.from) {
				navigate(`${state.from}${state.search ?? ''}`);
			} else {
				navigate('/');
			}
		});
	};

	return (
		<Formik
			component={InnerForm}
			initialValues={{ username: '', password: '' }}
			onSubmit={handleSubmit}
			validationSchema={useCredentialsValidationSchema()}
		/>
	);
};

export default Login;
