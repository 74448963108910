import { FormattedMessage } from 'react-intl';
import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import EditAvatar from 'components/edit-avatar';
import ButtonSave from 'components/forms/button-save';
import ButtonSaveRound from 'components/forms/button-save-round';
import { InputText } from 'components/inputs-formik';
import Message from 'components/message';
import GroupRatingCard from 'components/rating/group-rating-card';
import { updateGroupAsync } from 'store/group';
import { ImageContainer } from 'types/common';
import { Group, GroupDataRequest } from 'types/group';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch } from 'utils/hooks';
import './GroupInfoEditPanel.scss';

type Props = {
	className?: string;
	group: Group;
	onClickSave: () => void;
};

type FormValues = GroupDataRequest;

const GroupInfoEditPanel = ({ className, group, onClickSave }: Props): JSX.Element => {
	const dispatch = useAppDispatch();

	const amount = group.memberAmount;

	const handleSubmit = (data: FormValues) => {
		dispatch(updateGroupAsync({ id: group.id, data })).then(res => {
			res.payload && Message.success({ messageId: Dictionary.message.success.changesSaved });
			onClickSave();
		});
	};

	const InnerForm = ({ values, setFieldValue }: FormikProps<FormValues>): JSX.Element => (
		<Form className={`group-info-edit-panel ${className || ''}`}>
			<div className="group-info-edit-panel__left">
				<EditAvatar
					className="group-info-edit-panel__avatar"
					containerName={ImageContainer.GROUP_AVATAR}
					imageId={values.avatarImageId}
					onChange={(val) => setFieldValue('avatarImageId', val)}
				/>

				<div className="group-info-edit-panel__group-data">
					<InputText name="name" />
					<h3>
						<FormattedMessage id={Dictionary.groupPage.membersAmount} values={{ amount }} />
					</h3>
				</div>
			</div>

			<div className="group-info-edit-panel__right">
				{group.ratingNumber && <GroupRatingCard position={group.ratingNumber} />}
			</div>

			{/* lg-xl */}
			<ButtonSave />

			{/* xs-md */}
			<ButtonSaveRound />
		</Form>
	);

	return (
		<Formik
			component={InnerForm}
			initialValues={{ name: group.name, avatarImageId: group.avatarImageId }}
			onSubmit={handleSubmit}
		/>
	);
};

export default GroupInfoEditPanel;
