import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import UserService from 'services/user-service';
import { FetchStatus } from 'types/common';
import { UserProfilePersonal } from 'types/user-profile';
import { RootState } from './index';

type UserPersonalState = {
	data: UserProfilePersonal | null;
	status: FetchStatus;
};

const initialState: UserPersonalState = {
	data: null,
	status: FetchStatus.IDLE,
};

export const fetchUserPersonalAsync = createAsyncThunk(
	'(GET) users/profile/personal',
	async () => {
		const response: UserProfilePersonal = await UserService.getProfilePersonal();

		return response;
	},
);

export const updateUserPersonalAsync = createAsyncThunk(
	'(PUT) users/profile/personal',
	async (data: UserProfilePersonal) => {
		const response: UserProfilePersonal = await UserService.updateProfilePersonal(data);

		return response;
	},
);

export const userPersonalSlice = createSlice({
	name: 'userPersonal',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserPersonalAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(fetchUserPersonalAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;
				state.data = action.payload;
			})

			.addCase(updateUserPersonalAsync.pending, (state) => {
				state.status = FetchStatus.LOADING;
			})
			.addCase(updateUserPersonalAsync.fulfilled, (state, action) => {
				state.status = FetchStatus.IDLE;

				if (state.data) {
					state.data = { ...state.data, ...action.payload };
				}
			});
	},
});

const getUserPersonal = (state: RootState) => state.userPersonal;

export const getUserPersonalData = createSelector(getUserPersonal, userPersonal => userPersonal.data);

export default userPersonalSlice.reducer;
