import { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import GroupService from 'services/group-service';
import { IdType } from 'types/common';
import ROUTES from 'utils/constants/routes';
import NotFound from 'views/not-found';

const GroupJoin = (): JSX.Element | null => {
	const [searchParams] = useSearchParams();

	const [groupId, setGroupId] = useState<IdType | null>(null);

	const token: string | null = searchParams.get('token');

	useEffect(() => {
		if (token && !groupId) {
			GroupService.getGroupIdByToken(token).then(res => setGroupId(res.id));
		}
	}, [groupId, token]);

	if (!token) return <NotFound />;

	if (groupId) return <Navigate to={`/${ROUTES.GROUP.BASE}/${groupId}?token=${token}`} />;

	return null;
};

export default GroupJoin;
