import { MessageDescriptor } from 'react-intl';
import { UserDocumentsStatus } from 'types/user-documents';
import { Dictionary } from 'utils/constants/dictionary';

const getUserDocumentsMessage = (status: UserDocumentsStatus): MessageDescriptor => {
	switch (status) {
		case UserDocumentsStatus.NOT_LOADED: {
			return {
				defaultMessage: 'Upload your MEI here to be able to receive your commission',
				id: Dictionary.personalDataPage.personalDocuments.statusNotLoadedMei,
			};
		}
		case UserDocumentsStatus.NOT_VERIFIED: {
			return {
				defaultMessage: 'Your document failed verification. Please, check comment below:',
				id: Dictionary.personalDataPage.personalDocuments.statusNotVerified,
			};
		}
		case UserDocumentsStatus.PROCESSING: {
			return {
				defaultMessage: 'Your document on verifying.{br}An administrator will review it soon',
				id: Dictionary.personalDataPage.personalDocuments.statusProcessing,
			};
		}
		case UserDocumentsStatus.VERIFIED: {
			return {
				defaultMessage: 'Your document successfully verfied. Enjoy!',
				id: Dictionary.personalDataPage.personalDocuments.statusVerified,
			};
		}
		default: {
			return { defaultMessage: 'Something went wrong', id: 'default-id' };
		}
	}
};

export default getUserDocumentsMessage;
