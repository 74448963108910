import { SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import './InputImageFile.scss';

type Props = {
	className?: string;
	defaultMessage?: string;
	id: string;
	labelId?: string;
	onChange: (e: SyntheticEvent) => void;
};

const InputImageFile = ({ className, defaultMessage, id, labelId, onChange }: Props): JSX.Element => (
	<label className={`input-image-file ${className || ''}`} htmlFor={id}>
		{defaultMessage && labelId && (
			<FormattedMessage defaultMessage={defaultMessage} id={labelId} />
		)}

		<input
			accept=".jpeg,.jpg,.png"
			id={id}
			onChange={onChange}
			type="file"
		/>
	</label>
);

export default InputImageFile;
