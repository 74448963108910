import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InputText } from 'components/inputs';
import PageTitle from 'components/page-title';
import { fetchGlossaryAsync } from 'store/glossary';
import {
	fetchGroupMembersAsync, getGroupMembersData, getGroupMembersFilter, setGroupMembersFilter,
} from 'store/group-members';
import { GlossaryEnum } from 'types/glossary';
import { GroupMembersFilter, GroupMembersListItem } from 'types/group-members';
import { GROUP_MEMBERS_FILTER_DEFAULT } from 'utils/constants/default-filters';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { searchIcoWhite } from 'assets/images';
import GroupMembersStatusSorting from './group-members-status-sorting';
import GroupMembersTable from './group-members-table';
import './GroupMembersPage.scss';

const GroupMembersPage = (): JSX.Element | null => {
	const dispatch = useAppDispatch();
	const { id } = useParams<{ id?: string }>();

	const [search, setSearch] = useState<string>('');

	const filter: GroupMembersFilter = useAppSelector(getGroupMembersFilter);
	const groupMembers: GroupMembersListItem[] | null = useAppSelector(getGroupMembersData);

	const submitSearchFilter = (search: string)=> {
		dispatch(setGroupMembersFilter({ ...filter, search }));
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
		if (e.target.value === '') {
			dispatch(setGroupMembersFilter({ ...filter, search: '' }));
		}
		setSearch(e.target.value);
	};

	const handlePressEnter = (e: KeyboardEvent<HTMLInputElement>): void => {
		dispatch(setGroupMembersFilter({ ...filter, search: e.currentTarget.value }));
	};

	useEffect(() => {
		dispatch(fetchGlossaryAsync(GlossaryEnum.COUNTRIES));
	}, [dispatch]);

	useEffect(() => {
		dispatch(fetchGroupMembersAsync(filter));
	}, [dispatch, filter]);

	useEffect(() => () => {
		dispatch(setGroupMembersFilter(GROUP_MEMBERS_FILTER_DEFAULT));
	}, [dispatch]);

	if (!id) return null;

	return (
		<div className="group-members">
			<div className="group-members__top">
				<PageTitle
					backLink={`/group/${id}`}
					defaultText="Your group members"
					textId={Dictionary.groupMembersPage.title}
				/>

				<div className="group-members__top-right">
					<div className="group-members__top-search">
						<InputText
							defaultPlaceholder="Search"
							onChange={handleChange}
							onPressEnter={handlePressEnter}
							placeholderId={Dictionary.common.search}
							suffix={<img alt="" onClick={() => submitSearchFilter(search)} src={searchIcoWhite} />}
						/>
					</div>

					<div className="group-members__top-sort">
						<GroupMembersStatusSorting />
					</div>
				</div>
			</div>

			<GroupMembersTable dataSource={groupMembers || []} />
		</div>
	);
};

export default GroupMembersPage;
