import { appLogoWhite } from 'assets/images';
import './TopBar.scss';

type Props = {
	onClickMenu: () => void;
};

const TopBar = ({ onClickMenu }: Props): JSX.Element => (
	<div className="top-bar">
		<img alt="logo" className="top-bar_logo" src={appLogoWhite} />

		<div className="top-bar__toggle-menu" onClick={onClickMenu}>
			<div className="inner"></div>
		</div>
	</div>
);

export default TopBar;
