import { FC, useEffect } from 'react';
import Carousel from 'components/carousel';
import EmptyData from 'components/empty-data';
import Loader from 'components/loader/Loader';
import PageTitle from 'components/page-title';
import {
	fetchTrainingCategoriesAsync, getTrainingCategoriesData, isTrainingCategoriesLoading,
} from 'store/training-categories';
import { TrainingCategoriesList, TrainingCategoriesListItem, TRAINING_COLOR } from 'types/trainings';
import { TRAININGS } from 'utils/constants/end-points';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './Trainings.scss';

const carouselRender = (category: TrainingCategoriesListItem)=> {
	if (!category.trainings.length) return null;

	return (
		<Carousel
			color={TRAINING_COLOR}
			items={category.trainings}
			key={category.id}
			linkTo={TRAININGS}
			title={category.title}
		/>
	);
};

const Trainings: FC = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const categories: TrainingCategoriesList | null = useAppSelector(getTrainingCategoriesData);
	const isLoading: boolean = useAppSelector(isTrainingCategoriesLoading);

	useEffect(() => {
		dispatch(fetchTrainingCategoriesAsync());
	}, [dispatch]);

	if (isLoading) return <Loader />;

	return (
		<div className="trainings">
			<PageTitle defaultText="Trainings" textId={Dictionary.trainingsPage.title} />
			{categories?.length ? categories.map(carouselRender) : <EmptyData />}
		</div>
	);
};

export default Trainings;

